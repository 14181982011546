import { useEffect, useState } from "react";

import "./annotation.scss";
import "../../../../components/Skeleton/index.scss";

function Annotations(props: any) {
	const { data, totalHeight } = props;

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoaded(true);
		}, 2000);
	});

	return (
		<div>
			<div style={{ width: "100%", maxHeight: totalHeight }}>
				{data && loaded ? (
					<img
						// id="tb-screenshot"
						// crossOrigin="anonymous"
						src={data}
						style={{
							maxWidth: "100%",
							maxHeight: totalHeight, // This ensures the image doesn't exceed the maximum height
							width: "100%",
							height: "auto", // This keeps the aspect ratio while respecting the maxHeight
							borderRadius: 4,
						}}
						alt=""
					/>
				) : (
					<div
						style={{
							width: "100%",
							height: "40vh",
						}}
						className="skeleton video-loading"
						role="status"
					/>
				)}
				{/* <canvas style={{ position: "absolute" }} id="testbuddy-canvas" /> */}
			</div>
		</div>
	);
}

export default Annotations;
