import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSlackIntegrationMutation } from "../../shared/services/integration";

function Slack() {
	const params = new URLSearchParams(useLocation().search);
	const [slackIntegration] = useSlackIntegrationMutation();

	const api = async () => {
		const code: string = params.get("code") ?? "";
		const data: any = {
			code,
			workspaceId: params.get("state") ?? "",
		};
		await slackIntegration(data);
		setTimeout(() => {
			window.close();
		}, 5000);
	};

	useEffect(() => {
		api();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex flex-column justify-content-center align-items-center primary-background">
			<div>Authorisation Success</div>
			<div>You may close this tab now</div>
		</div>
	);
}

export default Slack;
