import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAcceptInviteMutation } from "../../shared/services/invite";

function TestInvite() {
	const { id } = useParams();
	const [acceptInvite] = useAcceptInviteMutation();
	const [text, setText] = useState<any>("Loading..");
	const navigate = useNavigate();
	async function callInviteAccept() {
		const response: any = await acceptInvite({ id });
		setText(response?.data?.message);
		if (response?.data?.success) {
			toast.success(response?.data?.message);
		} else {
			toast.error(response?.data?.message);
		}
		setTimeout(() => {
			navigate("/login");
		}, 3000);
	}

	useEffect(() => {
		document.title = "TestBuddy | Invite";

		if (id) {
			callInviteAccept();
		}
	}, [id]);

	return (
		<div className="d-flex justify-content-center align-items-center">
			<h1 className="primary-font-color">{text}</h1>
		</div>
	);
}
export default TestInvite;
