import { useState, useEffect } from "react";

import { connectWithPmt } from "../../../utils/helpers";
import classNames from "./styles.module.scss";
import slackLogo from "../../../assets/icon/slack.svg";
import jiraLogo from "../../../assets/icon/jira.svg";
// import trelloLogo from "../../../assets/icon/trello.svg";
import githubLogo from "../../../assets/icon/github.svg";
import gitlabLogo from "../../../assets/icon/gitlab.svg";
import azureLogo from "../../../assets/icon/azure.svg";
import clickupLogo from "../../../assets/icon/clickup.svg";
import youtrackLogo from "../../../assets/icon/youtrack.png";
import {
	useDeleteIntegrationMutation,
	useGetAllIntegrationQuery,
} from "../../../shared/services/integration";
import JiraIntegration from "./jiraIntegration";
import SlackIntegration from "./slackIntegration";
import GithubIntegration from "./githubIntegration";
import GitlabIntegration from "./gitlabIntegration";
import ClickupIntegration from "./clickupIntegration";
import AzureIntegration from "./azureIntegration";
import CreateYoutrackIntegrationModal from "../../../components/Modal/createYoutrackIntegrationModal";
import YoutrackIntegration from "./youtrackIntegration";

interface IIntegrations {
	name: string;
	desc: string;
	icon: string;
}

const INTEGRATIONS: IIntegrations[] = [
	{
		name: "Slack",
		desc: "Notify to a Slack channel automatically when the bug will be uploaded to this workspace. ",
		icon: slackLogo,
	},
	{
		name: "Jira",
		desc: "Create a bug automatically when you upload the issues to this workspace.",
		icon: jiraLogo,
	},

	{
		name: "Github",
		desc: "Create a GitHub issue automatically when you upload or submit an issue to this workspace.",
		icon: githubLogo,
	},
	{
		name: "GitLab",
		desc: "Create a GitLab issue automatically when you upload or submit an issue to this workspace.",
		icon: gitlabLogo,
	},
	{
		name: "Azure DevOps",
		desc: "Create a Azure DevOps issue automatically when you upload or submit an issue to this workspace.",
		icon: azureLogo,
	},
	{
		name: "Clickup",
		desc: "Create a Clickup issue automatically when you upload or submit an issue to this workspace.",
		icon: clickupLogo,
	},
	{
		name: "Youtrack",
		desc: "Create a Youtrack issue automatically when you upload or submit an issue to this workspace.",
		icon: youtrackLogo,
	},
];
const intialState = {
	Slack: false,
	Jira: false,
	// Trello: false,
	Github: false,
	GitLab: false,
	"Azure DevOps": false,
	Clickup: false,
	Youtrack: false,
};

function Integrations(props: any) {
	const { workspaceId } = props;
	const [checked, setChecked] = useState<{ [key: string]: boolean }>(
		intialState,
	);
	const [jiraIntegration, setJiraIntegration] = useState<any>({});
	const [slackIntegration, setSlackIntegration] = useState<any>({});
	const [githubIntegration, setGithubIntegration] = useState<any>({});
	const [gitlabIntegration, setGitlabIntegration] = useState<any>({});
	const [clickupIntegration, setClickupIntegration] = useState<any>({});
	const [youtrackIntegration, setYoutrackIntegration] = useState<any>({});
	const [azureIntegration, setAzureIntegration] = useState<any>({});
	const [loadingState, setLoadingState] = useState<any>({});
	const [showYoutrackModal, setShowYoutrackModal] = useState<any>(false);

	const toggleLoadingState = (integrationName: any, isLoading: any) => {
		setLoadingState((prevState: any) => ({
			...prevState,
			[integrationName]: isLoading,
		}));
	};
	const [deleteIntegration] = useDeleteIntegrationMutation();
	const { data, refetch: refetchAllIntegrations } = useGetAllIntegrationQuery({
		id: workspaceId,
	});
	const [openModal, setOpenModal] = useState(null);

	useEffect(() => {
		if (!showYoutrackModal && loadingState && loadingState.Youtrack) {
			setLoadingState((prevState: any) => ({
				...prevState,
				Youtrack: false,
			}));
		}
	}, [showYoutrackModal, loadingState]);

	useEffect(() => {
		if (data && data?.length > 0) {
			let currChecked = { ...intialState };
			data.map((integration: any) => {
				if (integration.acType === "Jira") {
					setJiraIntegration(integration);
					currChecked = { ...currChecked, Jira: true };
				}
				if (integration.acType === "Slack") {
					setSlackIntegration(integration);
					currChecked = { ...currChecked, Slack: true };
				}
				if (integration.acType === "github") {
					setGithubIntegration(integration);
					currChecked = { ...currChecked, Github: true };
				}
				if (integration.acType === "gitlab") {
					setGitlabIntegration(integration);
					currChecked = { ...currChecked, GitLab: true };
				}
				if (integration.acType === "Clickup") {
					setClickupIntegration(integration);
					currChecked = { ...currChecked, Clickup: true };
				}
				if (integration.acType === "Azure DevOps") {
					setAzureIntegration(integration);
					currChecked = { ...currChecked, "Azure DevOps": true };
				}
				if (integration.acType === "Youtrack") {
					setYoutrackIntegration(integration);
					currChecked = { ...currChecked, Youtrack: true };
				}

				return integration;
			});
			setChecked(currChecked);
		}
	}, [data]); // eslint-disable-line react-hooks/exhaustive-deps

	const randomTabs = (pmt: string) => {
		if (pmt === "Jira") {
			return (
				<JiraIntegration
					jiraIntegration={jiraIntegration}
					workspaceId={workspaceId}
					openModal={openModal}
					setOpenModal={setOpenModal}
					refetchAllIntegrations={refetchAllIntegrations}
				/>
			);
		}
		if (pmt === "Slack") {
			return (
				<SlackIntegration
					slackIntegration={slackIntegration}
					workspaceId={workspaceId}
					openModal={openModal}
					setOpenModal={setOpenModal}
					refetchAllIntegrations={refetchAllIntegrations}
				/>
			);
		}
		if (pmt === "Github") {
			return (
				<GithubIntegration
					githubIntegration={githubIntegration}
					workspaceId={workspaceId}
					openModal={openModal}
					setOpenModal={setOpenModal}
					refetchAllIntegrations={refetchAllIntegrations}
				/>
			);
		}
		if (pmt === "GitLab") {
			return (
				<GitlabIntegration
					gitlabIntegration={gitlabIntegration}
					workspaceId={workspaceId}
					openModal={openModal}
					setOpenModal={setOpenModal}
					refetchAllIntegrations={refetchAllIntegrations}
				/>
			);
		}
		if (pmt === "Clickup") {
			return (
				<ClickupIntegration
					clickupIntegration={clickupIntegration}
					workspaceId={workspaceId}
					openModal={openModal}
					setOpenModal={setOpenModal}
					refetchAllIntegrations={refetchAllIntegrations}
				/>
			);
		}
		if (pmt === "Azure DevOps") {
			return (
				<AzureIntegration
					azureIntegration={azureIntegration}
					workspaceId={workspaceId}
					openModal={openModal}
					setOpenModal={setOpenModal}
					refetchAllIntegrations={refetchAllIntegrations}
				/>
			);
		}
		if (pmt === "Youtrack") {
			return (
				<YoutrackIntegration
					youtrackIntegration={youtrackIntegration}
					workspaceId={workspaceId}
					openModal={openModal}
					setOpenModal={setOpenModal}
					refetchAllIntegrations={refetchAllIntegrations}
				/>
			);
		}
		return null;
	};

	const handleRemoveIntegration = async (pmt: any) => {
		if (pmt === "Jira") {
			deleteIntegration({ id: jiraIntegration._id });
			setChecked({ ...checked, Jira: false });
		}
		if (pmt === "Slack") {
			deleteIntegration({ id: slackIntegration._id });
			setChecked({ ...checked, Slack: false });
		}
		if (pmt === "Github") {
			deleteIntegration({ id: githubIntegration._id });
			setChecked({ ...checked, github: false });
		}
		if (pmt === "GitLab") {
			deleteIntegration({ id: gitlabIntegration._id });
			setChecked({ ...checked, gitlab: false });
		}
		if (pmt === "Clickup") {
			deleteIntegration({ id: clickupIntegration._id });
			setChecked({ ...checked, Clickup: false });
		}
		if (pmt === "Azure DevOps") {
			deleteIntegration({ id: azureIntegration._id });
			setChecked({ ...checked, "Azure DevOps": false });
		}
		if (pmt === "Youtrack") {
			deleteIntegration({ id: youtrackIntegration._id });
			setChecked({ ...checked, Youtrack: false });
		}
		// refetchAllIntegrations();
	};
	const handleWindowClose = (name: any) => {
		toggleLoadingState(name, false);
		refetchAllIntegrations();
	};

	const handleAddIntegration = (name: any) => {
		toggleLoadingState(name, true);
		if (name === "Youtrack") {
			setShowYoutrackModal(true);
		} else {
			const newWindow: any = connectWithPmt(name, workspaceId);
			const pollNewWindow = setInterval(() => {
				if (newWindow.closed) {
					clearInterval(pollNewWindow);
					handleWindowClose(name);
				}
			}, 1000);
		}
	};

	return (
		<div className={`d-flex flex-column gap-4 ${classNames.container}`}>
			<div className={`${classNames["integration-section"]}`}>
				<h1 className="primary-font-color">Integration Tools</h1>
				<p className="primary-font-color">
					Allow your team to post recording/issues automatically to chat or bug
					tracking system once its connected to workspace.
					<br />
					For eg. notify to a slack channel or create bug on JIRA or other
					tools.{" "}
				</p>
			</div>
			{INTEGRATIONS.map((integration) => (
				<div className={`${classNames["integration-section"]}`}>
					<div className="d-flex align-items-center justify-content-between">
						<div className={`d-flex gap-3 ${classNames["pmt-logo"]}`}>
							<img
								className={
									checked[`${integration.name}`]
										? classNames.enabled
										: classNames.disabled
								}
								width="15"
								alt={integration.name}
								src={integration.icon}
								style={{
									filter:
										integration.name === "Github"
											? "contrast(0.5)"
											: "grayscale(3)",
								}}
							/>
							<div>
								<h1 className="primary-font-color m-auto">
									{integration.name}
								</h1>
								<p className="primary-font-color">{integration.desc}</p>
							</div>
						</div>
						<div
							style={{
								alignSelf: "flex-start",
							}}
							className="form-check form-switch align-self-flex-start"
						>
							{loadingState[integration.name] ? (
								<div className="spinner-border text-primary" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							) : (
								<input
									className="form-check-input"
									type="checkbox"
									role="switch"
									name={integration.name}
									checked={checked[integration.name]}
									id="flexSwitchCheckDefault"
									onClick={() => {
										if (checked[integration.name]) {
											handleRemoveIntegration(integration.name);
										} else {
											handleAddIntegration(integration.name);
										}
										// onToggleHandler(e)
									}}
								/>
							)}
						</div>
					</div>
					{checked[integration.name] && <>{randomTabs(integration?.name)}</>}
				</div>
			))}
			<CreateYoutrackIntegrationModal
				show={showYoutrackModal}
				onHide={() => setShowYoutrackModal(false)}
				workspaceId={workspaceId}
				handleClose={() => handleWindowClose("Youtrack")}
			/>
		</div>
	);
}

export default Integrations;
