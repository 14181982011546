import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuthState {
	name?: string;
	token?: string;
	id?: string;
	firstName?: string;
	lastName?: string;
	avatar?: string;
}

interface IState {
	user: IAuthState;
}

const initialState: IAuthState = {
	name: undefined,
	firstName: undefined,
	lastName: undefined,
	token: undefined,
	id: undefined,
	avatar: undefined,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		resetUser: () => {
			return initialState;
		},
		setUser: (
			state,
			action: PayloadAction<{
				name?: string;
				firstName?: string;
				lastName?: string;
				token?: string;
				id?: string;
				avatar?: string;
			}>,
		) => {
			return {
				...state,
				name: action.payload?.name,
				firstName: action.payload?.firstName,
				lastName: action.payload?.lastName,
				id: action.payload?.id,
				avatar: action.payload?.avatar,
			};
		},
		setCredentials: (state, action: PayloadAction<{ token: string }>) => {
			return {
				...state,
				token: action.payload.token,
			};
		},
	},
});

export const selectUser = (state: IState) => state.user;
export const { setUser, setCredentials, resetUser } = userSlice.actions;
export default userSlice.reducer;
