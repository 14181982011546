import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "bootstrap";
import { Checkbox } from "antd";
import { toast } from "react-toastify";

// import NetworkItem from "./NetworkItem";
import "./style.css";
import "./networktab.scss";
import fontimg from "../../../assets/font_network.svg";
import cssimg from "../../../assets/css_network.svg";
import mediaimg from "../../../assets/media_network.svg";
import scriptimg from "../../../assets/script_network.svg";
import fetchimg from "../../../assets/fetch_network.svg";
import Highlight from "react-highlight";

function NetworkTab(props: any) {
	// const theme: any = "light";
	const { networkEvents } = props;
	const [data, setData] = useState(networkEvents);
	function formatPayload(payload: any) {
		try {
			// Check if payload is a string and looks like a JSON string
			if (typeof payload === "string") {
				payload = JSON.parse(payload); // Parse the string into an object
			}

			// Stringify the object with indentation
			return JSON.stringify(payload, null, 2);
		} catch (error) {
			// If parsing fails, return the original payload
			return payload;
		}
	}
	const getQueryParams = (url: any) => {
		const queryParams: any = {};

		// Check if the URL is relative, and if so, prepend the current origin
		const fullUrl = url.startsWith("http") ? url : `https://google.com${url}`;

		try {
			const urlObj = new URL(fullUrl); // Create URL object to handle parsing
			urlObj.searchParams.forEach((value, key: any) => {
				queryParams[key] = value;
			});
		} catch (error) {
			console.error("Invalid URL", error);
		}

		return queryParams;
	};

	const [input, setInput] = useState("");
	const [all, setAll] = useState();
	const [fetch, setFetch] = useState();
	const [ws, setWs] = useState();
	const [js, setJs] = useState();
	const [css, setCss] = useState();
	const [img, setImg] = useState();
	const [font, setFont] = useState();
	const [other, setOther] = useState();
	const [manifest, setManifest] = useState();
	const [doc, setDoc] = useState();
	const [maxHeight, setMaxHeight] = useState("100%");
	const [selectedStatus, setSelectedStatus] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [selectedRequest, setSelectedRequest] = useState<any>(null);
	const [panelPosition, setPanelPosition] = useState(50);
	const [isDragging, setIsDragging] = useState(false);
	const panelRef = useRef(null);
	const [isexpandedGeneral, setisExpandedGeneral] = useState(false);
	const [isExpandedRequestHeaders, setIsExpandedRequestHeaders] =
		useState(false);
	const [selectedCopyNetworkType, setSelectedCopyNetworkType] =
		useState("cURL");
	const [isOpenCopy, setIsOpenCopy] = useState(false);
	const [selectedTab, setSelectedTab] = useState("Headers");
	const [showErrorsOnly, setShowErrorsOnly] = useState(false);
	const [availableStatusCodes, setAvailableStatusCodes] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedMethod, setSelectedMethod] = useState<any>(null);
	const [availableMethods, setAvailableMethods] = useState([]);
	const [isOpenMethod, setIsOpenMethod] = useState(false);
	const [invertFilter, setInvertFilter] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const handleCopyClick = () => {
		navigator.clipboard.writeText(selectedRequest.request.uri);
		toast.success("URL copied to clipboard!");
	};

	const handleCheckboxChange = () => {
		setShowErrorsOnly(!showErrorsOnly);
	};

	const handleTabClick = (tabName: any) => {
		setSelectedTab(tabName);
	};
	const handleRowClick = (request: any) => {
		setSelectedRequest(request);
		setPanelPosition(50); // Set the panel to be open at 50%
	};

	const handleMouseDown = () => {
		setIsDragging(true);
	};

	const handleMouseMove = (e: any) => {
		if (isDragging) {
			const newPanelPosition =
				((window.innerWidth - e.clientX) / window.innerWidth) * 100;
			setPanelPosition(newPanelPosition < 10 ? 10 : newPanelPosition); // Prevent panel from being too small
		}
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	useEffect(() => {
		window.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("mouseup", handleMouseUp);
		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, [isDragging]);

	const handleSelect = (status: any) => {
		setSelectedStatus(status);
		setSelectedRequest(null);
		setIsOpen(false);
	};

	const handleClear = () => {
		setSelectedStatus("");
	};

	useEffect(() => {
		const handleResize = () => {
			setMaxHeight(`${window.innerHeight / 2 - 50}px`);
		};

		window.addEventListener("resize", handleResize);
		handleResize(); // Call initially to set the value

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	useEffect(() => {
		setData(networkEvents);

		console.log({ networkEvents });
	}, [networkEvents]);
	useEffect(() => {
		// Extract unique status codes from the data
		const statusCodes: any = [
			...new Set(data.map((row: any) => row.response.status)),
		];
		const methods: any = [...new Set(data.map((row: any) => row.request.verb))];
		setAvailableMethods(methods);
		setAvailableStatusCodes(statusCodes);
	}, [data]);
	const handleSelectMethod = (method: any) => {
		setSelectedMethod(method);
		setSelectedRequest(null);
		setIsOpenMethod(false);
	};
	const handleClearMethod = () => {
		setSelectedMethod(null);
	};

	useEffect(() => {
		// Filter the data based on the selected status code, method, and error checkbox
		let filtered = data;

		if (selectedStatus) {
			filtered = filtered.filter(
				(row: any) => row.response.status === parseInt(selectedStatus, 10),
			);
		}

		if (selectedMethod) {
			filtered = filtered.filter(
				(row: any) => row.request.verb === selectedMethod,
			);
		}

		if (showErrorsOnly) {
			filtered = filtered.filter((row: any) => row.response.status >= 400);
		}

		if (invertFilter) {
			// Invert the filtered data
			filtered = data.filter((row: any) => !filtered.includes(row));
		}

		setFilteredData(filtered);
	}, [selectedStatus, selectedMethod, showErrorsOnly, invertFilter, data]);
	const handleInvertChange = () => {
		setInvertFilter(!invertFilter);
	};

	useEffect(() => {
		setAll(networkEvents?.length);
		const filteredDataa = networkEvents;
		if (filteredDataa) {
			const arr1 = filteredDataa?.filter((ele: any) => {
				return ele.type === "xhr" || ele.type === "fetch";
			});
			setFetch(arr1?.length);

			const arr2 = filteredDataa?.filter((ele: any) => {
				return ele.type === "script";
			});
			setJs(arr2?.length);

			const arr3 = filteredDataa?.filter((ele: any) => {
				return ele.type === "stylesheet";
			});

			setCss(arr3?.length);

			const arr4 = filteredDataa.filter((ele: any) => {
				return ele.type === "image";
			});

			setImg(arr4?.length);

			const arr5 = filteredDataa.filter((ele: any) => {
				return ele.type === "websocket";
			});
			setWs(arr5?.length);

			const arr6 = filteredDataa.filter((ele: any) => {
				return ele.type === "font";
			});
			setFont(arr6?.length);

			const arr7 = filteredDataa.filter((ele: any) => {
				return (
					ele.type !== "manifest" &&
					ele.type !== "document" &&
					ele.type !== "font" &&
					ele.type !== "websocket" &&
					ele.type !== "image" &&
					ele.type !== "script" &&
					ele.type !== "xhr" &&
					ele.type !== "fetch"
				);
			});
			setOther(arr7?.length);
			const arr9: any = filteredData.filter((ele: any) => {
				return ele.type === "document";
			});
			setDoc(arr9?.length);
			const arr8: any = filteredData.filter((ele: any) => {
				return ele.type === "manifest";
			});
			setManifest(arr8?.length);
		}
	}, [data]);

	useEffect(() => {
		// Initialize tooltips when the component mounts
		const tooltipTriggerList = [].slice.call(
			// eslint-disable-next-line quotes
			document.querySelectorAll('[data-toggle="tooltip"]'),
		);
		tooltipTriggerList.map((tooltipTriggerEl) => {
			return new Tooltip(tooltipTriggerEl);
		});
	}, []);

	const [selectedItem, setSelectedItem] = useState("All");
	const handleChange = (e: any) => {
		const inputValue = e.target.value.toLowerCase();
		setInput(inputValue);
		setSelectedRequest(null);
		// Filter the data array based on the input value
		const filteredDataa = networkEvents.filter((item: any) => {
			// Convert the request.uri to lowercase for case-insensitive search
			const uri = item.request.uri.toLowerCase();

			// Check if the uri includes the input value
			return uri.indexOf(inputValue) !== -1;
		});
		const item = selectedItem;
		if (selectedItem === "All") {
			setData(filteredDataa);
		} else if (selectedItem === "xhr") {
			const arr = filteredDataa.filter((ele: any) => {
				return ele.type === "xhr" || ele.type === "fetch";
			});
			setData(arr);
		} else if (item === "js") {
			const arr = filteredDataa.filter((ele: any) => {
				return ele.type === "script";
			});
			setData(arr);
		} else if (item === "css") {
			const arr = filteredDataa.filter((ele: any) => {
				return ele.type === "stylesheet";
			});
			setData(arr);
		} else if (item === "img") {
			const arr = filteredDataa.filter((ele: any) => {
				return ele.type === "image";
			});
			setData(arr);
		} else if (item === "ws") {
			const arr = filteredDataa.filter((ele: any) => {
				return ele.type === "websocket";
			});
			setData(arr);
		} else if (item === "font") {
			const arr = filteredDataa.filter((ele: any) => {
				return ele.type === "font";
			});
			setData(arr);
		} else if (item === "other") {
			const arr = filteredDataa.filter((ele: any) => {
				return (
					ele.type !== "manifest" &&
					ele.type !== "document" &&
					ele.type !== "font" &&
					ele.type !== "websocket" &&
					ele.type !== "image" &&
					ele.type !== "script" &&
					ele.type !== "xhr" &&
					ele.type !== "fetch"
				);
			});
			setData(arr);
		}
	};

	const handleClick = (item: any) => {
		setSelectedItem(item);
		setSelectedRequest(null);
		if (item === "All") {
			if (input !== "") {
				const filteredDataa = networkEvents?.filter((i: any) => {
					// Convert the request.uri to lowercase for case-insensitive search
					const uri = i.request.uri.toLowerCase();

					// Check if the uri includes the input value
					return uri.indexOf(input) !== -1;
				});
				setData(filteredDataa);
			} else setData(networkEvents);
		} else if (item === "xhr") {
			const arr = networkEvents?.filter((ele: any) => {
				return ele.type === "xhr" || ele.type === "fetch";
			});
			if (input !== "") {
				const filteredDataa = arr.filter((u: any) => {
					// Convert the request.uri to lowercase for case-insensitive search
					const uri = u.request.uri.toLowerCase();

					// Check if the uri includes the input value
					return uri.indexOf(input) !== -1;
				});
				setData(filteredDataa);
			} else setData(arr);
		} else if (item === "js") {
			const arr = networkEvents?.filter((ele: any) => {
				return ele.type === "script";
			});
			if (input !== "") {
				const filteredDataa = arr?.filter((j: any) => {
					// Convert the request.uri to lowercase for case-insensitive search
					const uri = j.request.uri.toLowerCase();

					// Check if the uri includes the input value
					return uri.indexOf(input) !== -1;
				});
				setData(filteredDataa);
			} else setData(arr);
		} else if (item === "css") {
			const arr = networkEvents?.filter((ele: any) => {
				return ele.type === "stylesheet";
			});
			if (input !== "") {
				const filteredDataa = arr?.filter((k: any) => {
					// Convert the request.uri to lowercase for case-insensitive search
					const uri = k.request.uri.toLowerCase();

					// Check if the uri includes the input value
					return uri.indexOf(input) !== -1;
				});
				setData(filteredDataa);
			} else setData(arr);
		} else if (item === "img") {
			const arr = networkEvents?.filter((ele: any) => {
				return ele.type === "image";
			});
			if (input !== "") {
				const filteredDataa = arr?.filter((l: any) => {
					// Convert the request.uri to lowercase for case-insensitive search
					const uri = l.request.uri.toLowerCase();

					// Check if the uri includes the input value
					return uri.indexOf(input) !== -1;
				});
				setData(filteredDataa);
			} else setData(arr);
		} else if (item === "ws") {
			const arr = networkEvents?.filter((ele: any) => {
				return ele.type === "websocket";
			});
			if (input !== "") {
				const filteredDataa = arr?.filter((m: any) => {
					// Convert the request.uri to lowercase for case-insensitive search
					const uri = m.request.uri.toLowerCase();

					// Check if the uri includes the input value
					return uri.indexOf(input) !== -1;
				});
				setData(filteredDataa);
			} else setData(arr);
		} else if (item === "font") {
			const arr = networkEvents?.filter((ele: any) => {
				return ele.type === "font";
			});
			if (input !== "") {
				const filteredDataa = arr?.filter((n: any) => {
					// Convert the request.uri to lowercase for case-insensitive search
					const uri = n.request.uri.toLowerCase();

					// Check if the uri includes the input value
					return uri.indexOf(input) !== -1;
				});
				setData(filteredDataa);
			} else setData(arr);
		} else if (item === "other") {
			const arr = networkEvents?.filter((ele: any) => {
				return (
					ele.type !== "manifest" &&
					ele.type !== "document" &&
					ele.type !== "font" &&
					ele.type !== "websocket" &&
					ele.type !== "image" &&
					ele.type !== "script" &&
					ele.type !== "xhr" &&
					ele.type !== "fetch"
				);
			});
			if (input !== "") {
				const filteredDataa = arr?.filter((o: any) => {
					// Convert the request.uri to lowercase for case-insensitive search
					const uri = o.request.uri.toLowerCase();

					// Check if the uri includes the input value
					return uri.indexOf(input) !== -1;
				});
				setData(filteredData);
			} else setData(arr);
		}
	};
	const generateCurlCommand = (request: any) => {
		// Generate cURL command from the network request object
		const headers = Object.entries(request.headers || {})
			.map(([key, value]) => {
				// Convert the value to a string if it's an object
				const headerValue =
					typeof value === "object" ? JSON.stringify(value) : value;
				return `-H "${key}: ${headerValue}"`;
			})
			.join(" ");

		const method = request.verb || "GET";
		const url = request.uri || "";

		return `curl -X ${method} ${headers} "${url}"`;
	};

	const generateFetchCommand = (request: any) => {
		// Generate Fetch API command from the network request object
		const headers = JSON.stringify(request.headers || {}, null, 2);
		const method = request.verb || "GET";
		const url = request.uri || "";

		return `fetch("${url}", {
	  method: "${method}",
	  headers: ${headers},
	  
	})`;
	};
	const handleSelectCopy = (method: any) => {
		let textToCopy = "";

		switch (method) {
			case "cURL":
				textToCopy = generateCurlCommand(selectedRequest?.request);
				break;
			case "Fetch":
				textToCopy = generateFetchCommand(selectedRequest?.request);
				break;
			case "HAR":
				textToCopy = JSON.stringify(selectedRequest?.request, null, 2); // Assuming networkRequest is already in HAR format
				break;
			default:
				textToCopy = "";
		}

		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				toast.success("Copied successfully!");
				setIsOpenCopy(false); // Close the options after copying
			})
			.catch((err) => {
				console.error("Could not copy text: ", err);
			});
		setSelectedCopyNetworkType(method);
	};

	const truncateText = (text: string, maxLength: number) => {
		if (text.length > maxLength) {
			return `${text.substring(0, maxLength)}...`;
		}
		return text;
	};
	const getImgSrc = (type: any) => {
		if (type === "font") {
			return fontimg;
		}
		if (type === "media" || type === "image") {
			return mediaimg;
		}
		if (type === "script") {
			return scriptimg;
		}
		if (type === "stylesheet") {
			return cssimg;
		}
		if (type === "xhr" || type === "fetch") {
			return fetchimg;
		}
		return "";
	};

	return (
		<div
			className="tab-pane active show network-tab"
			id="tabs-network"
			style={{
				maxHeight,
				overflowY: "scroll",
				padding: 0,
				background: "#1f1f1f",
				minHeight: maxHeight,
			}}
		>
			<div>
				{(networkEvents?.length === 0 || !networkEvents) && (
					<div className="p-4">
						<div
							className="project-setting-title"
							style={{ fontSize: "14px", lineHeight: "16px", color: "#fff" }}
						>
							*No Network logs in this site.
						</div>
					</div>
				)}

				{networkEvents?.length > 0 && (
					<div className="network-filters-parent">
						<div style={{ position: "relative" }} id="filter-input">
							<input
								type="text"
								placeholder="Filter"
								className="filter-container"
								onChange={(e) => handleChange(e)}
							/>
							<div className="filter-svg">
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M11.9907 3.61606C9.33849 3.31964 6.66154 3.31964 4.00935 3.61606C3.96869 3.62072 3.93002 3.63621 3.89739 3.66091C3.86476 3.68561 3.83935 3.71861 3.82382 3.75648C3.80829 3.79435 3.80321 3.83569 3.80911 3.87619C3.81501 3.91669 3.83167 3.95486 3.85735 3.98673L6.20201 6.88539C7.04186 7.92366 7.50003 9.21865 7.50001 10.5541V12.4801L8.50001 13.2134V10.5534C8.50015 9.21821 8.95832 7.92348 9.79801 6.88539L12.1427 3.98673C12.1684 3.95486 12.185 3.91669 12.1909 3.87619C12.1968 3.83569 12.1917 3.79435 12.1762 3.75648C12.1607 3.71861 12.1353 3.68561 12.1026 3.66091C12.07 3.63621 12.0313 3.62072 11.9907 3.61606ZM3.89868 2.62206C6.62464 2.31739 9.37606 2.31739 12.102 2.62206C13.066 2.73006 13.5307 3.86073 12.9207 4.61539L10.576 7.51406C9.87991 8.37433 9.50009 9.44743 9.50001 10.5541V14.2001C9.50008 14.2925 9.47451 14.3832 9.42614 14.462C9.37776 14.5408 9.30849 14.6046 9.22601 14.6464C9.14353 14.6882 9.05107 14.7063 8.95892 14.6987C8.86677 14.6911 8.77853 14.6581 8.70401 14.6034L6.70401 13.1367C6.64073 13.0902 6.58928 13.0295 6.55384 12.9594C6.5184 12.8894 6.49996 12.8119 6.50001 12.7334V10.5534C6.50001 9.44673 6.12001 8.37406 5.42468 7.51339L3.08001 4.61606C2.46935 3.86139 2.93335 2.73006 3.89868 2.62206Z"
										fill="#E3E3E3"
									/>
								</svg>
							</div>
						</div>
						{/* filter input ended */}
						<div id="invert" className="d-flex">
							<div>
								<Checkbox
									className="custom-checkbox"
									checked={invertFilter}
									onChange={handleInvertChange}
								>
									{" "}
									<span className="checkbox-label">Invert</span>{" "}
								</Checkbox>
							</div>
						</div>
						{/* invert ended */}

						{/* partition */}
						<div>
							<svg
								width="1"
								height="28"
								viewBox="0 0 1 28"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<line
									x1="0.5"
									y1="-2.18557e-08"
									x2="0.500001"
									y2="28"
									stroke="#5E5E5E"
								/>
							</svg>
						</div>

						{/* method filters start */}
						<div
							className={`filter-box ${selectedItem === "All" ? "active" : ""}`}
							onClick={() => handleClick("All")}
						>
							All ({all})
						</div>
						<div
							className={`filter-box ${selectedItem === "xhr" ? "active" : ""}`}
							onClick={() => handleClick("xhr")}
						>
							Fetch/XHR ({fetch})
						</div>
						<div
							className={`filter-box ${selectedItem === "doc" ? "active" : ""}`}
							onClick={() => handleClick("doc")}
						>
							Doc ({doc})
						</div>
						<div
							className={`filter-box ${selectedItem === "css" ? "active" : ""}`}
							onClick={() => handleClick("css")}
						>
							CSS ({css})
						</div>
						<div
							className={`filter-box ${selectedItem === "js" ? "active" : ""}`}
							onClick={() => handleClick("js")}
						>
							JS ({js})
						</div>
						<div
							className={`filter-box ${selectedItem === "font" ? "active" : ""}`}
							onClick={() => handleClick("font")}
						>
							Font ({font})
						</div>
						<div
							className={`filter-box ${selectedItem === "img" ? "active" : ""}`}
							onClick={() => handleClick("img")}
						>
							Media ({img})
						</div>
						<div
							className={`filter-box ${selectedItem === "manifest" ? "active" : ""}`}
							onClick={() => handleClick("manifest")}
						>
							Manifest ({manifest})
						</div>
						<div
							className={`filter-box ${selectedItem === "ws" ? "active" : ""}`}
							onClick={() => handleClick("ws")}
						>
							WS ({ws})
						</div>
						<div
							className={`filter-box ${selectedItem === "other" ? "active" : ""}`}
							onClick={() => handleClick("other")}
						>
							Other ({other})
						</div>

						{/* methods dropdown */}
						<div className="method-select-container">
							<div
								className="status-select"
								onClick={() => setIsOpenMethod(!isOpenMethod)}
							>
								<div className="status-selected">
									{selectedMethod || "Methods"}
									{selectedMethod && (
										<span
											className="clear-icon"
											onClick={(e) => {
												e.stopPropagation();
												handleClearMethod();
											}}
										>
											&times;
										</span>
									)}
									<svg
										width="8"
										height="8"
										viewBox="0 0 8 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g clipPath="url(#clip0_43_129)">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M4.43328 6.95091C4.38941 7.02699 4.32628 7.09017 4.25024 7.13411C4.1742 7.17804 4.08793 7.20117 4.00011 7.20117C3.91229 7.20117 3.82602 7.17804 3.74998 7.13411C3.67393 7.09017 3.61081 7.02699 3.56694 6.95091L0.688941 1.96624C0.645058 1.89023 0.621954 1.80401 0.621954 1.71624C0.621953 1.62847 0.645055 1.54225 0.688938 1.46624C0.732821 1.39024 0.795938 1.32712 0.871946 1.28323C0.947954 1.23935 1.03417 1.21624 1.12194 1.21624L6.87794 1.21624C6.96571 1.21624 7.05193 1.23935 7.12794 1.28323C7.20395 1.32712 7.26706 1.39023 7.31095 1.46624C7.35483 1.54225 7.37793 1.62847 7.37793 1.71624C7.37793 1.80401 7.35483 1.89023 7.31094 1.96624L4.43294 6.95091L4.43328 6.95091Z"
												fill="#E3E3E3"
											/>
										</g>
										<defs>
											<clipPath id="clip0_43_129">
												<rect
													width="8"
													height="8"
													fill="white"
													transform="translate(8 8) rotate(180)"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
								{isOpenMethod && (
									<div className="status-options">
										{availableMethods.map((statusCode) => (
											<div
												key={statusCode}
												className="status-option"
												onClick={() => handleSelectMethod(statusCode)}
											>
												{statusCode}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
						{/* status base */}
						<div className="status-select-container">
							<div className="status-select" onClick={() => setIsOpen(!isOpen)}>
								<div className="status-selected">
									{selectedStatus || "Status"}
									{selectedStatus && (
										<span
											className="clear-icon"
											onClick={(e) => {
												e.stopPropagation();
												handleClear();
											}}
										>
											&times;
										</span>
									)}
									<svg
										width="8"
										height="8"
										viewBox="0 0 8 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g clipPath="url(#clip0_43_129)">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M4.43328 6.95091C4.38941 7.02699 4.32628 7.09017 4.25024 7.13411C4.1742 7.17804 4.08793 7.20117 4.00011 7.20117C3.91229 7.20117 3.82602 7.17804 3.74998 7.13411C3.67393 7.09017 3.61081 7.02699 3.56694 6.95091L0.688941 1.96624C0.645058 1.89023 0.621954 1.80401 0.621954 1.71624C0.621953 1.62847 0.645055 1.54225 0.688938 1.46624C0.732821 1.39024 0.795938 1.32712 0.871946 1.28323C0.947954 1.23935 1.03417 1.21624 1.12194 1.21624L6.87794 1.21624C6.96571 1.21624 7.05193 1.23935 7.12794 1.28323C7.20395 1.32712 7.26706 1.39023 7.31095 1.46624C7.35483 1.54225 7.37793 1.62847 7.37793 1.71624C7.37793 1.80401 7.35483 1.89023 7.31094 1.96624L4.43294 6.95091L4.43328 6.95091Z"
												fill="#E3E3E3"
											/>
										</g>
										<defs>
											<clipPath id="clip0_43_129">
												<rect
													width="8"
													height="8"
													fill="white"
													transform="translate(8 8) rotate(180)"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
								{isOpen && (
									<div className="status-options">
										{availableStatusCodes.map((statusCode) => (
											<div
												key={statusCode}
												className="status-option"
												onClick={() => handleSelect(statusCode)}
											>
												{statusCode}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
						<div id="invert" className="d-flex ml-16">
							<div>
								<Checkbox
									className="custom-checkbox"
									checked={showErrorsOnly}
									onChange={handleCheckboxChange}
								>
									{" "}
									<span className="checkbox-label">Errors</span>{" "}
								</Checkbox>
							</div>
						</div>
						{/* ended filter */}
					</div>
				)}
				{networkEvents?.length > 0 && (
					<div className="network-container">
						<div className="custom-table-container">
							<table className="custom-table">
								<thead>
									<tr>
										<th>Network</th>
										<th>Status</th>
										<th>Method</th>
										<th>Type</th>
										<th>Initiator</th>
										<th>Time</th>
									</tr>
								</thead>
								<tbody>
									{filteredData.map((row: any, index: any) => (
										<tr
											key={row.key}
											className={
												index % 2 === 0 ? "custom-row-even" : "custom-row-odd"
											}
										>
											<td
												style={{ cursor: "pointer" }}
												onClick={() => handleRowClick(row)}
											>
												{getImgSrc(row.type) !== null && (
													<img src={getImgSrc(row.type)} alt="icon" />
												)}
												{truncateText(row.request.uri, 60)}
											</td>
											<td>{row.response.status}</td>
											<td>{row.request.verb}</td>
											<td style={{ textTransform: "uppercase" }}>{row.type}</td>
											<td>{row.initiator}</td>
											<td>{Math.floor(row.duration)} ms</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						{selectedRequest && (
							<div
								className="details-panel"
								ref={panelRef}
								style={{ width: `${panelPosition}%`, height: "auto" }}
							>
								<div
									className="draggable-handle"
									onMouseDown={handleMouseDown}
								/>
								<div className="panel-header">
									<div
										style={{ cursor: "pointer" }}
										onClick={() => setSelectedRequest(null)}
									>
										<svg
											width="21"
											height="21"
											viewBox="0 0 21 21"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M15.5 15.5L5.5 5.5M15.5 5.5L5.5 15.5"
												stroke="#E3E3E3"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
									<div
										className={`panel-tab ${selectedTab === "Headers" ? "active" : ""}`}
										onClick={() => handleTabClick("Headers")}
									>
										Headers
									</div>
									<div
										className={`panel-tab ${selectedTab === "Payload" ? "active" : ""}`}
										onClick={() => handleTabClick("Payload")}
									>
										Payload
									</div>
									<div
										className={`panel-tab ${selectedTab === "Response" ? "active" : ""}`}
										onClick={() => handleTabClick("Response")}
									>
										Response
									</div>
								</div>
								<div className="copy-network">
									<div>Copy request as</div>
									<div>
										<div className="status-select-container">
											<div
												className="status-select"
												onClick={() => setIsOpenCopy(!isOpenCopy)}
											>
												<div className="status-selected">
													{selectedCopyNetworkType}

													<svg
														width="8"
														height="8"
														viewBox="0 0 8 8"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<g clipPath="url(#clip0_43_129)">
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M4.43328 6.95091C4.38941 7.02699 4.32628 7.09017 4.25024 7.13411C4.1742 7.17804 4.08793 7.20117 4.00011 7.20117C3.91229 7.20117 3.82602 7.17804 3.74998 7.13411C3.67393 7.09017 3.61081 7.02699 3.56694 6.95091L0.688941 1.96624C0.645058 1.89023 0.621954 1.80401 0.621954 1.71624C0.621953 1.62847 0.645055 1.54225 0.688938 1.46624C0.732821 1.39024 0.795938 1.32712 0.871946 1.28323C0.947954 1.23935 1.03417 1.21624 1.12194 1.21624L6.87794 1.21624C6.96571 1.21624 7.05193 1.23935 7.12794 1.28323C7.20395 1.32712 7.26706 1.39023 7.31095 1.46624C7.35483 1.54225 7.37793 1.62847 7.37793 1.71624C7.37793 1.80401 7.35483 1.89023 7.31094 1.96624L4.43294 6.95091L4.43328 6.95091Z"
																fill="#E3E3E3"
															/>
														</g>
														<defs>
															<clipPath id="clip0_43_129">
																<rect
																	width="8"
																	height="8"
																	fill="white"
																	transform="translate(8 8) rotate(180)"
																/>
															</clipPath>
														</defs>
													</svg>
												</div>
												{isOpenCopy && (
													<div className="status-options">
														<div
															className="status-option"
															onClick={() => handleSelectCopy("cURL")}
														>
															cURL
														</div>
														<div
															className="status-option"
															onClick={() => handleSelectCopy("Fetch")}
														>
															Fetch
														</div>
														<div
															className="status-option"
															onClick={() => handleSelectCopy("HAR")}
														>
															HAR
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>

								<div className="panel-content">
									{selectedTab === "Headers" && (
										<div className="general-info">
											<div className="d-flex">
												<div
													onClick={() => setisExpandedGeneral((prev) => !prev)}
													style={{ marginRight: "8px" }}
												>
													{!isexpandedGeneral && (
														<svg
															width="15"
															height="16"
															viewBox="0 0 15 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M5 14.5V2.5L10.394 7.894C10.5547 8.05474 10.645 8.27272 10.645 8.5C10.645 8.72728 10.5547 8.94526 10.394 9.106L5 14.5Z"
																fill="white"
																fillOpacity="0.6"
															/>
														</svg>
													)}
													{isexpandedGeneral && (
														<svg
															width="13"
															height="7"
															viewBox="0 0 13 7"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M0.210938 0.499999L12.2109 0.5L6.81694 5.894C6.6562 6.05469 6.43822 6.14496 6.21094 6.14496C5.98365 6.14496 5.76567 6.05469 5.60494 5.894L0.210938 0.499999Z"
																fill="white"
																fillOpacity="0.6"
															/>
														</svg>
													)}
												</div>
												<div className="headers-title">General</div>
											</div>
											{isexpandedGeneral && (
												<div
													style={{ paddingLeft: "32px", paddingTop: "12px" }}
												>
													<div
														className="info-row"
														onMouseEnter={handleMouseEnter}
														onMouseLeave={handleMouseLeave}
													>
														<div
															style={{
																marginRight: "6px",
																display: isHovered ? "block" : "none",
																cursor: "pointer",
															}}
															className="copy-icon"
															onClick={handleCopyClick}
															title="Copy URL"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
																className="cursor-pointer"
															>
																<path
																	d="M15.7188 2.21875C15.9062 2.40625 16 2.63542 16 2.90625V10C15.9792 10.5625 15.7812 11.0312 15.4062 11.4062C15.0312 11.7812 14.5625 11.9792 14 12H8C7.4375 11.9792 6.96875 11.7812 6.59375 11.4062C6.19792 11.0312 5.98958 10.5625 5.96875 10V2C5.98958 1.4375 6.1875 0.96875 6.5625 0.59375C6.9375 0.21875 7.40625 0.0208333 7.96875 0H13.0938C13.3646 0 13.5938 0.09375 13.7812 0.28125L15.7188 2.21875ZM14.5 10H14.4688V4H13C12.7083 4 12.4688 3.90625 12.2812 3.71875C12.0938 3.53125 12 3.29167 12 3L11.9688 1.5H7.96875C7.65625 1.54167 7.48958 1.70833 7.46875 2V10C7.48958 10.3125 7.65625 10.4792 7.96875 10.5H14C14.3125 10.4792 14.4792 10.3125 14.5 10ZM8.5 14L8.53125 13H10V14C9.97917 14.5625 9.78125 15.0312 9.40625 15.4062C9.03125 15.7812 8.5625 15.9792 8 16H2C1.4375 15.9792 0.96875 15.7812 0.59375 15.4062C0.21875 15.0312 0.0208333 14.5625 0 14V6C0.0208333 5.4375 0.21875 4.96875 0.59375 4.59375C0.96875 4.21875 1.4375 4.02083 2 4H5V5.5H2C1.6875 5.52083 1.52083 5.6875 1.5 6L1.46875 14C1.48958 14.3125 1.65625 14.4792 1.96875 14.5H8C8.3125 14.4792 8.47917 14.3125 8.5 14Z"
																	fill="white"
																	fill-opacity="0.6"
																></path>
															</svg>
														</div>
														<div className="info-label">Request URL :</div>
														<div className="info-value">
															{truncateText(selectedRequest.request.uri, 60)}
														</div>
													</div>
													<div className="info-row">
														<div className="info-label">Request Method :</div>
														<div className="info-value">
															{selectedRequest.request.verb}
														</div>
													</div>
													<div className="info-row">
														<div className="info-label">Status Code :</div>
														<div className="info-value">
															{selectedRequest.response.status}
														</div>
													</div>
												</div>
											)}
										</div>
									)}

									{selectedRequest?.request?.headers?.length > 0 &&
										selectedTab === "Headers" && (
											<div className="general-info">
												<div className="headers-title">
													<div
														onClick={() =>
															setIsExpandedRequestHeaders((prev) => !prev)
														}
														style={{ marginRight: "8px" }}
													>
														{!isExpandedRequestHeaders && (
															<svg
																width="15"
																height="16"
																viewBox="0 0 15 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M5 14.5V2.5L10.394 7.894C10.5547 8.05474 10.645 8.27272 10.645 8.5C10.645 8.72728 10.5547 8.94526 10.394 9.106L5 14.5Z"
																	fill="white"
																	fillOpacity="0.6"
																/>
															</svg>
														)}
														{isExpandedRequestHeaders && (
															<svg
																width="13"
																height="7"
																viewBox="0 0 13 7"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M0.210938 0.499999L12.2109 0.5L6.81694 5.894C6.6562 6.05469 6.43822 6.14496 6.21094 6.14496C5.98365 6.14496 5.76567 6.05469 5.60494 5.894L0.210938 0.499999Z"
																	fill="white"
																	fillOpacity="0.6"
																/>
															</svg>
														)}
													</div>
													<div>
														{" "}
														Request Headers (
														{selectedRequest?.request?.headers.length})
													</div>
												</div>
												{isExpandedRequestHeaders && (
													<div className="headers-content">
														{selectedRequest?.request?.headers.map(
															(header: any) => (
																<div key={header?.name} className="header-row">
																	<div className="header-name">
																		{header.name}
																	</div>
																	<div className="header-value">
																		{header.value}
																	</div>
																</div>
															),
														)}
													</div>
												)}
											</div>
										)}
									{selectedTab === "Payload" && (
										<div style={{ marginLeft: "16px" }}>
											{selectedRequest?.request?.body ||
											selectedRequest?.request?.uri.includes("?") ? (
												<>
													{/* Display Request Body if available */}
													{selectedRequest?.request?.body ? (
														<>
															<Highlight className="json">
																{formatPayload(selectedRequest.request.body)}
															</Highlight>
														</>
													) : null}

													{/* Display Query Parameters if available */}
													{selectedRequest?.request?.uri.includes("?") && (
														<>
															<Highlight className="json">
																{JSON.stringify(
																	getQueryParams(selectedRequest.request.uri),
																	null,
																	2,
																)}
															</Highlight>
														</>
													)}
												</>
											) : (
												<div>No payload available</div> // Show only if neither body nor query parameters exist
											)}
										</div>
									)}
									{selectedTab === "Response" && (
										<div style={{ marginLeft: "16px" }}>
											{selectedRequest?.response?.body ? (
												<Highlight className="json">
													{JSON.stringify(
														selectedRequest.response.body,
														null,
														2,
													)}
												</Highlight>
											) : (
												<div>No response available</div>
											)}
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				)}

				{/* tabls started */}
			</div>
		</div>
	);
}

export default NetworkTab;
