import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

export const notificationPreferenceApi = createApi({
	reducerPath: "notificationPreferenceApi",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getNotificationPreference: builder.query<any, any>({
			query: (id: any) => ({
				url: `/notification/preference/${id}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),
		updateNotificationPreference: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/notification/preference/${data?.workspaceId}`,
				method: "PATCH",
				body: data,
			}),
		}),
	}),
});

export const {
	useGetNotificationPreferenceQuery,
	useUpdateNotificationPreferenceMutation,
} = notificationPreferenceApi;
