import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { PublicClientApplication } from "@azure/msal-browser";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import Carousel from "../../components/Carousel/index";
import { useLoginUserMutation } from "../../shared/services/auth";
import {
	IAuthState,
	setCredentials,
	setUser,
} from "../../shared/features/auth";
import { RootState } from "../../redux/store";
import useEffectOnce from "../../hooks/useEffectOnce";
import classNames from "./styles.module.scss";
import Google from "../../assets/icon/google";
import Microsoft from "../../assets/icon/microsoft";
import Github from "../../assets/icon/githubOAuth";
import { useLoginWithMicrosoftMutation } from "../../shared/services/oauth";
import { getGoogleOAuthURL } from "../../utils/helpers";
import {
	REACT_APP_GITHUB_SSO_CLIENT_ID,
	REACT_APP_GITHUB_SSO_REDIRECT_URL,
} from "../../config/environment";
import PublicTopNav from "../../components/TopNav/PublicTopNav";
import IError from "../../utils/types";
import Loader from "../../components/Loader";

function Login() {
	const [loginUser, { isLoading }] = useLoginUserMutation();
	const [loginWithMicrosoft] = useLoginWithMicrosoftMutation();
	const authState = useSelector<RootState>((state) => state.auth) as IAuthState;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const params = new URLSearchParams(useLocation().search);
	const [viewPassword, setViewPassword] = useState<boolean>(false);
	const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const {
		register,
		handleSubmit,
		formState: { errors },
	}: any = useForm();

	// Get individual query parameters
	const name: string = queryParams.get("firstName") || "";
	const token: string = queryParams.get("token") || "";
	const extensionId = params.get("extensionId");

	useEffectOnce(() => {
		document.title = "TestBuddy | Login";
		if (authState.token) {
			if (name || token) {
				Cookies.set("extensionToken", token, {
					expires: 30,
					path: "",
				});
				Cookies.set("firstName", name, {
					expires: 2,
					path: "",
				});
				if (extensionId) {
					chrome.runtime.sendMessage(
						extensionId,
						{
							msg: "REFRESH-CREATEBUG-PAGE",
							data: { extensionId },
						},
						() => {
							setTimeout(() => {
								window.close();
							}, 1500);
						},
					);
				}
			}
			navigate(state?.from || "/workspace");
		}
	}, [authState.token, navigate, state?.from]);

	const onSubmitHandler = (data: any) => {
		loginUser(data)
			.unwrap()
			.then((response) => {
				dispatch(setCredentials({ token: response.data.accessToken }));
				dispatch(setUser({ firstName: response.data?.firstName }));
				Cookies.set("extensionToken", response?.data?.extensionToken, {
					expires: 30,
					path: "/",
				});
				Cookies.set("firstName", response?.data?.firstName, {
					expires: 2,
					path: "/",
				});
				if (extensionId) {
					setIsLoggingIn(false);
					chrome.runtime.sendMessage(
						extensionId,
						{
							msg: "REFRESH-CREATEBUG-PAGE",
							data: { extensionId },
						},
						() => {
							setTimeout(() => {
								window.close();
							}, 1500);
						},
					);
				}
				setIsLoggingIn(false);
				navigate(state?.from || "/workspace"); // comment
			})
			.catch((err: IError) => {
				setIsLoggingIn(false);
				toast.error(err?.data?.message);
			});
	};

	const handleGoogleLogin = (e: any) => {
		e.preventDefault();
		window.open(getGoogleOAuthURL(extensionId), "_blank");
		window.close();
	};

	const handleGithubLogin = (e: any) => {
		e.preventDefault();
		window.open(
			`https://github.com/login/oauth/authorize?client_id=${REACT_APP_GITHUB_SSO_CLIENT_ID}&redirect_uri=${REACT_APP_GITHUB_SSO_REDIRECT_URL}${extensionId ? `&state=${extensionId}` : ""}`,
			"_blank",
		);
		window.close();
	};

	const msalConfig: any = {
		scopes: ["user.read"],
	};

	const handleMicrosoftLogin = async (e: any) => {
		e.preventDefault();

		// await publicClientApplication.handleRedirectPromise();
		const publicClientApplication: any = new PublicClientApplication({
			auth: {
				clientId:
					process.env.REACT_APP_MSAL_APPID ||
					"a099b791-8e7c-4173-b556-31f9c76a6879",
				// authority:
				// 	"https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a",
				redirectUri:
					process.env.REACT_APP_MSAL_REDIRECT_URL ||
					"https://app-dev.testbuddy.io/azure", // Adjust as needed
			},
		});
		await publicClientApplication.initialize();
		await publicClientApplication.handleRedirectPromise();

		const popup: any = await publicClientApplication.loginPopup({
			scopes: msalConfig.scopes,
			prompt: "select_account",
		});

		try {
			const firstName = popup.account.name.split(" ")?.[0];
			const lastName = popup.account.name.split(" ")?.[1];
			const email = popup.account.username;

			const data = {
				firstName,
				lastName,
				email,
				rememberMe: false,
				accessToken: popup.accessToken,
				...(extensionId && { state: extensionId }),
			};
			await loginWithMicrosoft(data)
				.then(async (res: any) => {
					setTimeout(() => {
						window.open(res?.data?.redirectURL, "_self");
					}, 1000);
				})
				.catch(() => {
					// toast.error(err?.response?.data?.message);
				});
		} catch (err) {
			toast.error("Something went wrong.");
		}
	};

	const carouselRef: any = useRef(null);
	const loginRef: any = useRef(null);

	useEffect(() => {
		if (loginRef && carouselRef) {
			// Check if loginRef.current is truthy before accessing its properties
			const loginElement: any = loginRef.current;
			if (!loginElement) return;

			const divTop = loginElement.getBoundingClientRect().top;

			// Check if carouselRef.current is truthy before updating its style
			const carouselElement = carouselRef.current;
			if (carouselElement) {
				carouselElement.style.paddingTop = `${divTop}px`;
			}
		}
	}, [carouselRef, loginRef]);

	const handleShowPassword = () => {
		setViewPassword(!viewPassword);
	};

	return (
		<>
			<PublicTopNav isBugDetailsPage={false} />
			<div
				// style={{ height: "100vh" }}
				className="overflow-hidden w-100 d-flex flex-row"
			>
				<div
					ref={carouselRef}
					id="carousel"
					className="w-50 d-flex justify-content-center bg-primary"
					style={{
						height: "100vh",
					}}
				>
					<Carousel />
				</div>
				<div
					className={`w-50 h-100 bg-white d-flex align-items-center justify-content-center flex-column ${classNames["login-container"]}`}
				>
					<div
						className={`${classNames.loginContent} d-flex flex-column align-items-start
				`}
					>
						<form
							className={`${classNames.loginForm} d-flex flex-column align-items-start w-100`}
							onSubmit={handleSubmit(onSubmitHandler)}
							action="submit"
						>
							<h1 ref={loginRef} id="login" className={classNames.loginHeading}>
								Login
							</h1>
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label htmlFor="email">Email</label>
							<input
								id="email"
								{...register("email", {
									required: "Email is required.",
									pattern: {
										value:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: "Please enter a valid email",
									},
								})}
								style={{
									border: "1px solid #d1d5e1",
								}}
								className={`w-100 bg-white ${classNames.input}`}
								// onChange={handleUserName}
								type="text"
							/>
							{errors?.email && (
								<span className="text-danger">{errors?.email?.message}</span>
							)}

							<div
								className="d-flex justify-content-between w-100 align-items-center"
								style={{
									marginTop: 16,
								}}
							>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label htmlFor="password">Password</label>
								<Link
									to={
										extensionId
											? `/user/forgot-password?extensionId=${extensionId}`
											: "/user/forgot-password"
									}
									style={{ width: "169px", textDecoration: "none" }}
								>
									Forgot Password?
								</Link>
							</div>
							<div
								className={`${classNames["inner-addon-rp"]} ${classNames["right-addon-rp"]}`}
								style={{
									width: "100%",
									textAlign: "left",
								}}
							>
								<input
									id="password"
									{...register("password", {
										required: "Password is required.",
									})}
									className={`w-100 ${classNames.input}`}
									// onChange={(e) => setPassword(e.target.value)}
									type={viewPassword ? "text" : "password"}
								/>
								{errors?.password && (
									<span className="text-danger text-left">
										{errors?.password?.message}
									</span>
								)}
								<i
									className={`${classNames.icon} bi ${
										viewPassword ? "bi-eye-slash" : "bi-eye"
									} cursor-pointer`}
									style={{
										top: "17%",
										pointerEvents: "all",
										right: 16,
									}}
									aria-label="show pass"
									onClick={handleShowPassword}
									onKeyDown={handleShowPassword}
									role="button"
									tabIndex={0}
									id="togglePassword"
								/>
							</div>
							<button
								type="submit"
								className={`w-100 text-uppercase ${classNames.loginButton} d-flex align-items-center justify-content-center`}
								style={{
									marginTop: 16,
								}}
								disabled={isLoading}
								onClick={() => {
									setIsLoggingIn(true);
								}}
							>
								<div
									style={{
										marginRight: isLoggingIn ? 5 : 0,
									}}
								>
									Login
								</div>
								{isLoggingIn && <Loader theme="light" />}
							</button>
						</form>
						<div
							className={`d-flex align-items-center ${classNames.signupLink}`}
						>
							<h2
								style={{
									marginRight: 5,
								}}
							>
								If you don't have an account?
							</h2>
							<a
								style={{
									fontSize: 16,
								}}
								href={
									extensionId ? `/signup?extensionId=${extensionId}` : "/signup"
								}
							>
								Signup here
							</a>
						</div>
						<div className={`d-flex align-items-center ${classNames.or}`}>
							<hr />
							<span>OR</span> <hr />
						</div>
						<div
							className={`d-flex flex-column w-100 ${classNames.oauthButtons}`}
						>
							<button
								type="button"
								onClick={handleGoogleLogin}
								disabled={isLoading}
								aria-label="Continue with Google"
								className={`${classNames.loginButton} bg-white text-dark ${classNames.oauthButton}`}
							>
								<Google />
								<p className="d-inline"> Continue with Google </p>
							</button>
							<button
								onClick={handleGithubLogin}
								aria-label="Continue with Github"
								type="button"
								disabled={isLoading}
								className={`${classNames.loginButton} bg-white text-dark ${classNames.oauthButton}`}
							>
								<Github />
								<p className="d-inline">Continue with Github</p>
							</button>
							<button
								onClick={handleMicrosoftLogin}
								aria-label="Continue with Microsoft"
								type="button"
								disabled={isLoading}
								className={`${classNames.loginButton} bg-white text-dark ${classNames.oauthButton}`}
							>
								<Microsoft />
								<p className="d-inline">Continue with Microsoft</p>
							</button>
						</div>
					</div>
				</div>
				{/* <form
				className="d-flex flex-column h-100 w-50 m-auto bg-primary"
				onSubmit={onSubmitHandler}
				action="submit"
			> */}
				{/* </form> */}
			</div>
		</>
	);
}

export default Login;
