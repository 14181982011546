import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

interface IMicrosoftLogin {
	firstName: string;
	lastName: string;
	email: string;
	state?: string;
}

export const oauthApi = createApi({
	reducerPath: "oauthAPI",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		loginWithMicrosoft: builder.mutation<any, IMicrosoftLogin>({
			query: (data: IMicrosoftLogin) => ({
				url: "/oauth/microsoft",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
	}),
});

export const { useLoginWithMicrosoftMutation } = oauthApi;
