import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import classNames from "./gridbug.module.scss";
import linkIcon from "../../assets/link-icon.svg";
// import slackLogo from "../../assets/slack-logo.svg";
import { IIssues } from "../../shared/services/workspace";
import fakeImg from "../../assets/fakeimg.svg";
import { timeAgo } from "../../utils/helpers";
import slackLogo from "../../assets/icon/slack.svg";
import jiraLogo from "../../assets/icon/jira.svg";
import githubLogo from "../../assets/icon/github.svg";
import gitlabLogo from "../../assets/icon/gitlab.svg";
import azureLogo from "../../assets/icon/azure.svg";
import clickupLogo from "../../assets/icon/clickup.svg";
import youtrackLogo from "../../assets/icon/youtrack.png";
import { resetWorkspace } from "../../redux/store";

function GridBug(props: { details: IIssues }) {
	const {
		details: {
			title,
			_id: issueId,
			screenshot,
			author,
			createdAt,
			pmtIssues,
			thumbnail,
		},
	} = props;

	const navigate = useNavigate();

	const handleNavigateToBugDetails = () => {
		resetWorkspace();
		navigate(`/bugdetails/${issueId}`);
	};

	const handleCopyLink = (e: any) => {
		e.stopPropagation();
		navigator.clipboard.writeText(
			`${process.env.REACT_APP_URL}/bugdetails/${issueId}`,
		);
		toast.success("Link copied to clipboard");
	};

	const handleRedirectToPmt = (e: any, link: string) => {
		e.stopPropagation();
		window.open(link, "_blank");
	};

	const renderIcon = (type: string) => {
		switch (type) {
			case "github":
				return githubLogo;
			case "gitlab":
				return gitlabLogo;
			case "azureDevops":
				return azureLogo;
			case "slack":
				return slackLogo;
			case "jira":
				return jiraLogo;
			case "clickup":
				return clickupLogo;
			case "youtrack":
				return youtrackLogo;
			default:
				return ""; // or any other default value
		}
	};

	return (
		<div
			onClick={handleNavigateToBugDetails}
			className={` ${classNames["bug-container"]}`}
			style={{
				cursor: "pointer",
				minWidth: "31.5%",
				maxWidth: "33.33%",
				height: "auto",
				flex: 1,
				marginBottom: 16,
			}}
			tabIndex={0}
			role="button"
			aria-label="bug"
			onKeyDown={(e) => {
				if (e.key === "Enter") {
					handleNavigateToBugDetails();
				}
			}}
		>
			{/* <div className={classNames["bug-image-container"]}> */}
			<div
				title={author?.firstName}
				className={`d-flex justify-content-center align-items-center ${classNames["bug-reporter"]}`}
			>
				{author?.firstName?.charAt(0)}
			</div>
			<div
				title="Copy Link"
				onClick={handleCopyLink}
				style={{ cursor: "pointer" }}
				tabIndex={0}
				role="button"
				aria-label="bug"
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						handleCopyLink(e);
					}
				}}
				className={`d-flex justify-content-center align-items-center ${classNames["bug-link"]}`}
			>
				<img src={linkIcon} alt="linkIcon" style={{ cursor: "pointer" }} />
			</div>
			{thumbnail && (
				<div className={`${classNames["play-icon"]}`}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="25"
						height="25"
						fill="white"
						className="bi bi-play-fill"
						viewBox="0 0 16 16"
					>
						<path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
					</svg>
				</div>
			)}
			<img
				src={screenshot || thumbnail || fakeImg}
				style={{ objectFit: "cover", maxWidth: "100%" }}
				alt="screenshot"
				className={classNames.image}
			/>
			{/* </div> */}
			<div
				className="d-flex justify-content-between align-items-center"
				style={{
					height: "4rem",
					padding: "0px 20px 0px 20px",
				}}
			>
				<div className={`gap-1 d-flex  ${classNames.description}`}>
					{/* <img
						className={classNames.slackLogo}
						src={slackLogo}
						alt="slacklogo"
					/> */}
					{pmtIssues &&
						Object?.entries(pmtIssues)?.map(([type, { posted, issueLink }]) => (
							<div
								key={type}
								onClick={(e: any) => handleRedirectToPmt(e, issueLink)}
								style={{
									cursor: "pointer",
									position: "relative",
									maxWidth: "65%",
								}}
								tabIndex={0}
								role="button"
								aria-label="bug"
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleRedirectToPmt(e, issueLink);
									}
								}}
							>
								<img
									src={renderIcon(type)}
									alt=""
									style={{
										width: 20,
										height: 20,
										// position: "absolute",
										// borderRadius: 15,
										// marginLeft: index + index === 0 ? 0 : 12,
										// border: "0.5px solid lightgray",
										filter:
											posted === "success" ? "contrast(0.5)" : "grayscale(3)",
									}}
								/>
							</div>
						))}
					<p className={` ${classNames.description}`} title={title}>
						{/* {issueId}  */}
						{title}
					</p>
				</div>
				<div className={` ${classNames.description}`}>
					{timeAgo(new Date(createdAt))}
				</div>
			</div>
		</div>
	);
}

export default GridBug;
