import { useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

import { useResetPasswordMutation } from "../../shared/services/user";
import "./resetPassword.scss";
import PublicTopNav from "../../components/TopNav/PublicTopNav";

interface IFormInput {
	password: string;
	passwordConfirm: string;
}

function ResetPassword() {
	const navigate = useNavigate();

	document.title = "Testbuddy | Reset Password";

	const url = window.location.href;
	const tokenStartIndex =
		url.indexOf("/reset-password/") + "/reset-password/".length;
	const tokenEndIndex = url.indexOf("?") !== -1 ? url.indexOf("?") : url.length;
	const token = url.substring(tokenStartIndex, tokenEndIndex);
	const params = new URLSearchParams(useLocation().search);
	const extensionId = params.get("extensionId");
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<IFormInput>({ mode: "onChange" });
	const [viewPassword, setViewPassword] = useState<boolean>(false);
	const [viewConfirmPassword, setViewConfirmPassword] =
		useState<boolean>(false);

	const [resetPassword] = useResetPasswordMutation();

	const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
		try {
			data.token = token; // eslint-disable-line no-param-reassign
			await resetPassword(data).then(() => {
				toast.success("Password Reset Successfully");
				if (extensionId) {
					navigate(`/login?extensionId=${extensionId}`);
				} else {
					navigate("/login");
				}
			});
		} catch (error) {
			toast.error("Something went wrong");
		}
	};

	const password = useRef({});
	password.current = watch("password", "");

	const handleShowPassword = () => {
		setViewPassword(!viewPassword);
	};

	const handleShowConfirmPassword = () => {
		setViewConfirmPassword(!viewConfirmPassword);
	};

	return (
		<main
			style={{
				paddingTop: "0px",
				background: " #f8f8fa",
				maxWidth: "100%",
				minHeight: "100vh",
			}}
		>
			<PublicTopNav isBugDetailsPage={false} />
			<div
				className="container-xl "
				style={{ display: "flex", justifyContent: "center" }}
			>
				<div
					className="row main-wraper"
					style={{
						// marginTop: 56,
						width: "100%",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<h1 style={{ color: "black" }}>Reset Password</h1>
					</div>
					<form
						style={{
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
						}}
						onSubmit={handleSubmit(onSubmit)}
					>
						<div
							//  className="col-md-4 col-sm-12 py-3"
							className="mt-3"
							style={{ width: 500, textAlign: "left" }}
						>
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label className="form-label setting-title-sub-rp">
								New Password
							</label>
							<div className="inner-addon-rp right-addon-rp">
								<input
									style={{
										width: 500,
										boxShadow: "none",
										backgroundColor: "white",
									}}
									{...register("password", {
										required: "Password is required.",
										pattern: {
											value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
											message:
												"Password must be minimum 8 characters, and have at least 1uppercase letter, 1 lowercase letter and 1 number",
										},
									})}
									placeholder="Password"
									type={viewPassword ? "text" : "password"}
									className="form-control settings-field-height-rp input-text-reset-password-rp border-0 bg-color-ebf1fa"
									name="password"
								/>
								<i
									className={`icon bi ${
										viewPassword ? "bi-eye-slash" : "bi-eye"
									} cursor-pointer`}
									style={{
										top: errors.password ? "13%" : "23%",
										pointerEvents: "all",
									}}
									aria-label="show pass"
									onClick={handleShowPassword}
									onKeyDown={handleShowPassword}
									role="button"
									tabIndex={0}
									id="togglePassword"
								/>
								{errors.password && (
									<span style={{ color: "red", width: 500 }} className="error">
										{errors.password.message}
									</span>
								)}
							</div>
						</div>
						<div
							//  className="col-md-4 col-sm-12 py-3"
							className="mt-3"
							style={{ width: 500, textAlign: "left" }}
						>
							{/* eslint-disable-next-line   jsx-a11y/label-has-associated-control */}
							<label className="form-label setting-title-sub-rp">
								Confirm New Password
							</label>
							<div className="inner-addon-rp right-addon-rp">
								<input
									style={{
										width: 500,
										boxShadow: "none",
										backgroundColor: "white",
									}}
									{...register("passwordConfirm", {
										required: "Confirm Password.",
										validate: (value: string) =>
											value === password.current ||
											"The passwords do not match",
									})}
									placeholder="Confirm Password"
									type={viewConfirmPassword ? "text" : "password"}
									className="form-control settings-field-height-rp input-text-reset-password-rp border-0 bg-color-ebf1fa"
									name="passwordConfirm"
								/>
								<i
									className={`icon bi ${
										viewConfirmPassword ? "bi-eye-slash" : "bi-eye"
									} cursor-pointer`}
									style={{
										top: errors.password ? "13%" : "23%",
										pointerEvents: "all",
									}}
									aria-label="show confirm pass"
									onKeyDown={handleShowConfirmPassword}
									role="button"
									tabIndex={0}
									onClick={handleShowConfirmPassword}
									id="togglePassword"
								/>
								{errors.passwordConfirm && (
									<span style={{ color: "red" }} className="error">
										{errors.passwordConfirm.message}
									</span>
								)}
							</div>
						</div>

						{/* <div>
                <label className="form-check p-l-0 mt-3">
                  <input
                    type="checkbox"
                    // className="form-check-input m-t-3 m-l-0"
                    onChange={handleShowPassword}
                    checked={viewPassword}
                  />
                  <span className="form-check-label d-inline-block setting-title-sub m-l-1rem">
                    Show Passwords
                  </span>
                </label>
              </div> */}
						<div
						// className="col-12 my-3"
						>
							<button
								style={{ width: 500 }}
								type="submit"
								value="yes"
								disabled={!isValid}
								className="btn btn-primary-rp primary-font-color-invert submit-button-rp button-blue-rp mt-3"
							>
								Reset Password
							</button>
						</div>
					</form>
				</div>
			</div>
		</main>
	);
}

export default ResetPassword;
