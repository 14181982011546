import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { authApi } from "../shared/services/auth";
// import { usersApi } from '../Shared/services/user';
import { integrationApi } from "../shared/services/integration";
import auth from "../shared/features/auth";
import theme from "../shared/features/themeSlice";
import { inviteApi } from "../shared/services/invite";
import { workspaceApi } from "../shared/services/workspace";
import { notificationPreferenceApi } from "../shared/services/notificationPreference";
import { issueApi } from "../shared/services/bugDetails";
import { notificationApi } from "../shared/services/notification";
import { oauthApi } from "../shared/services/oauth";
import { commentApi } from "../shared/services/comment";
import { memberApi } from "../shared/services/members";
import { purchaseApi } from "../shared/services/purchase";

// import { apiSlice } from '../app/api/apiSlice';
// import { oauthApi } from '../Shared/services/oauth';

export const store = configureStore({
	devTools: true,
	reducer: {
		[integrationApi.reducerPath]: integrationApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
		[workspaceApi.reducerPath]: workspaceApi.reducer,
		auth,
		theme,
		[inviteApi.reducerPath]: inviteApi.reducer,
		[notificationPreferenceApi.reducerPath]: notificationPreferenceApi.reducer,
		[issueApi.reducerPath]: issueApi.reducer,
		[notificationApi.reducerPath]: notificationApi.reducer,
		[oauthApi.reducerPath]: oauthApi.reducer,
		// [usersApi.reducerPath]: usersApi.reducer,
		[commentApi.reducerPath]: commentApi.reducer,
		[memberApi.reducerPath]: memberApi.reducer,
		[purchaseApi.reducerPath]: purchaseApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			integrationApi.middleware,
			authApi.middleware,
			workspaceApi.middleware,
			inviteApi.middleware,
			notificationPreferenceApi.middleware,
			issueApi.middleware,
			notificationApi.middleware,
			oauthApi.middleware,
			commentApi.middleware,
			memberApi.middleware,
			purchaseApi.middleware,
		),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Reset the entire Redux store
export const resetCache = async () => {
	store.dispatch(integrationApi.util.resetApiState());
	store.dispatch(authApi.util.resetApiState());
	store.dispatch(workspaceApi.util.resetApiState());
	store.dispatch(inviteApi.util.resetApiState());
	store.dispatch(notificationPreferenceApi.util.resetApiState());
	store.dispatch(issueApi.util.resetApiState());
	store.dispatch(oauthApi.util.resetApiState());
	store.dispatch(commentApi.util.resetApiState());
	store.dispatch(memberApi.util.resetApiState());
	store.dispatch(purchaseApi.util.resetApiState());
	store.dispatch(notificationApi.util.resetApiState());
};

export const resetWorkspace = async () => {
	store.dispatch(workspaceApi.util.resetApiState());
};

setupListeners(store.dispatch);
