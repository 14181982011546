import { useNavigate, useParams } from "react-router-dom";

import gear from "../../../assets/img/icon/gear.svg";
// import refer from "../../../assets/img/icon/refer.svg";
import classNames from "./styles.module.scss";

function WorkspaceSetting() {
	const navigate = useNavigate();
	const params = useParams();

	return (
		<div
			className={`d-flex flex-column justify-content-start ${classNames["workspace-container"]}`}
		>
			<button
				type="button"
				onClick={() => navigate(`/workspace/${params.id}/settings`)}
				className={`hoverable-option d-flex border-solid  gap-2 align-items-center justify-content-start ${classNames["workspace-setting"]}`}
			>
				<img src={gear} alt="setting" />

				<h1 className="text-white m-0">Workspace Settings</h1>
			</button>
			{/* <div
				className={`hoverable-option d-flex border-1 gap-2  align-items-center justify-content-start ${classNames["workspace-setting"]}`}
			>
				<img src={refer} alt="setting" />

				<h1
					className={`text-white m-0 d-block align-middle ${classNames["workspace-setting-h1"]}`}
				>
					Refer a friend
				</h1>
			</div> */}
		</div>
	);
}

export default WorkspaceSetting;
