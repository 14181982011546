import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import NewUserCard from "../../components/newUserCard";
import CreateWorkspaceModal from "../../components/Modal/createWorkspaceModal";
import plusIcon from "../../assets/plus-icon-vector.svg";
import playIcon from "../../assets/play-icon-vector.svg";
import "react-toastify/dist/ReactToastify.css";
import classNames from "./workspace.module.scss";
import gridIcon from "../../assets/grid-icon.svg";
import commentIcon from "../../assets/icon/comment.svg";
import tableIcon from "../../assets/table-icon.svg";
import GridBug from "../../components/GridBug";
import Table from "../../components/Table";
import {
	useGetIssuesQuery,
	useGetIssueCountQuery,
	IIssues,
	useGetWorkspaceQuery,
} from "../../shared/services/workspace";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import TableFooter from "../../components/Table/TableFooter";
import { useGetMembersQuery } from "../../shared/services/members";
import { useAppSelector } from "../../redux/hooks";
import { RootState, resetWorkspace } from "../../redux/store";
import CustomDropdownIndicator from "../../components/CustomSelect/customDropdownIndicator";
import TutorialModal from "../../components/Modal/tutorial";
import linkExpired from "../../assets/icon/linkExpired.png";

import "react-loading-skeleton/dist/skeleton.css";

const Headers = [
	{
		name: "Last updated at",
		onClick() {},
		render: ({ updatedAt }: { updatedAt: Date }) => {
			return new Date(updatedAt).toDateString();
		},
		sorter: true,
		dataIndex: "updatedAt",
	},
	{
		name: "Bug ID",
		dataIndex: "issueId",
		render: ({ bugID }: { bugID: string }) => {
			return bugID || "-";
		},
	},
	{
		name: "Bug Title",
		dataIndex: "title",
	},
	{
		name: "Status",
		dataIndex: "status",
		render: ({
			status,
		}: {
			status: { id: string; name: string } | undefined;
		}) => {
			return status?.name || "-";
		},
	},
	{
		name: "Reporter",
		render: ({
			author,
		}: {
			author: { _id: string; firstName: string; lastName: string };
		}) => {
			return `${author?.firstName} ${author?.lastName}`;
		},
		dataIndex: "author",
	},
	{
		name: "",
		dataIndex: "number",
		render: ({ comments }: { comments: number }) => (
			<div className="d-flex  align-items-center">
				<img className="sort-icon" alt="sort" src={commentIcon} />
				{comments}
			</div>
		),
	},
];

function Workspace() {
	const params = useParams();
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;

	const navigate = useNavigate();

	const [pagination, setPagination] = useState({
		skip: 0,
		limit: 10,
	});
	const [totalBugs, setTotalBugs] = useState(0);
	const [bugs, setBugs] = useState<Array<IIssues>>([]);
	const [sortOrder, setSortOrder] = useState("desc");
	const [tableView, setTableView] = useState(false);
	const [memberOptions, setMemberOptions] = useState([]);
	const [tempWorkspaceId, setTempWorkspaceId] = useState("");
	const [title, setTitle] = useState("");
	const [reporter, setReporter] = useState("");

	const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] =
		useState(false);
	const [showTutorialModal, setShowTutorialModal] = useState(false);

	const workspaceId = params.id as string;

	useEffect(() => {
		if (workspaceId) {
			setPagination({
				skip: 0,
				limit: 10,
			});
		}
	}, [workspaceId]);

	Headers[0].onClick = () => {
		setSortOrder(sortOrder === "asc" ? "desc" : "asc");
	};

	const { data: issueCount, isError: countError } = useGetIssueCountQuery(
		{ workspaceId, title },
		{
			refetchOnMountOrArgChange: true,
			skip: !workspaceId,
		},
	);

	const {
		data: workspaceIssues,
		isFetching: isFetchingWorkspaceIssues,
		isError: issuesError,
	} = useGetIssuesQuery(
		{
			workspaceId,
			skip: pagination.skip,
			limit: pagination.limit,
			sortOrder,
			title,
			reporter,
		},
		{
			refetchOnMountOrArgChange: true,
			skip: !totalBugs,
		},
	);

	const { data: workspace } = useGetWorkspaceQuery(
		{ id: workspaceId },
		{
			refetchOnMountOrArgChange: true,
			skip: !workspaceId,
		},
	);

	const { data: members } = useGetMembersQuery(
		{
			workspaceId,
			skip: 0,
			limit: 0,
			count: false,
			// role: "reporter",
		},
		{
			// refetchOnMountOrArgChange: true,
			// skip: !workspaceId,
		},
	);

	useEffect(() => {
		if (countError || issuesError) {
			toast.error(
				"You are not authorised to access the contents of this workspace",
			);
		}
	}, [countError, issuesError]);

	useEffect(() => {
		document.title = "TestBuddy";
		if (members && members?.data?.length > 0) {
			const tempMemberOptions: any = [];
			members?.data?.forEach((member: any) => {
				tempMemberOptions.push({
					value: member?.user?._id,
					label: `${member?.user?.firstName} ${member?.user?.lastName}`,
				}); // Note the use of semicolon instead of comma here
			});
			setMemberOptions(tempMemberOptions);
		}
	}, [members]);

	const cb = () => {
		if (bugs.length < totalBugs && bugs.length > 0) {
			setPagination((prev) => ({ ...prev, skip: prev.skip + 1 }));
		}
	};
	const [observerTarget] = useInfiniteScroll(cb, [totalBugs, bugs.length]);

	useEffect(() => {
		setTotalBugs(issueCount?.data?.totalIssues || 0);
	}, [issueCount]);

	useEffect(() => {
		if (workspaceIssues && workspaceIssues.data && !tableView) {
			if (workspaceId !== tempWorkspaceId) {
				setTempWorkspaceId(workspaceId);
				setBugs([...workspaceIssues.data]);
			} else {
				const newBugs = [...bugs, ...workspaceIssues.data];
				setBugs(newBugs);
			}
		} else {
			setBugs(workspaceIssues?.data || []);
		}
		// eslint-disable-next-line
	}, [workspaceIssues]);

	const renderMyComponents = () => {
		const timesToRender = 3;

		return Array.from({ length: timesToRender }, () => (
			<div
				style={{
					width: "31%",
					maxWidth: "33%",
					height: "250px",
					background: "white",
					border: "1px solid lightgray",
				}}
			>
				<Skeleton count={1} width="100%" height={150} />
				<div style={{ marginTop: 20 }} />
				<div className="d-flex justify-content-around">
					<Skeleton count={1} width={150} />
					<Skeleton count={1} width={100} />
				</div>
			</div>
		));
	};

	const handleRowClick = (rowData: any) => {
		resetWorkspace();
		navigate(`/bugdetails/${rowData?._id}`);
	};

	const handleTableView = (e: any) => {
		e.preventDefault();
		setPagination({ skip: 0, limit: 10 });
		setTableView(!tableView);
	};

	return (
		<div>
			{!countError && (
				<div
					className={`row ${classNames["workspace-container"]} gap-sm-2 gap-md-0`}
				>
					<div className="col-6 col-md-6 col-sm-12 d-flex justify-content-sm-center justify-content-md-end">
						<NewUserCard
							heading="Collaborate with your team"
							description="Join or create workspace and start collaborating with your teammates"
							buttonText="CREATE WORKSPACE"
							buttonIcon={plusIcon}
							action="createWorkspace"
							setShowCreateWorkspaceModal={setShowCreateWorkspaceModal}
						/>
					</div>
					<div className="col-6 col-md-6 col-sm-12 d-flex justify-content-sm-center justify-content-md-start">
						<NewUserCard
							heading=" Add your first bug"
							description="Click on TestBuddy browser extension and create your first bug!"
							buttonText="WATCH THE TUTORIAL"
							buttonIcon={playIcon}
							action="watchTutorial"
							setShowTutorialModal={setShowTutorialModal}
						/>
					</div>
				</div>
			)}

			{(countError || issuesError) && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",

						background: "white",
						width: "100%",
					}}
				>
					<div>
						<img src={linkExpired} alt="linkexpired" width={200} />
					</div>
					<h1
						style={{
							display: "flex",

							justifyContent: "center",
							alignItems: "center",
						}}
					>
						You are not authorised to access this workspace content!
					</h1>
					<p>
						Please make sure the workspace ID is correct. or please contact
						testbuddy admin at admin@testunity.com if you believe something went
						wrong.
					</p>
				</div>
			)}
			{workspaceId && !countError && !issuesError && (
				<div className={`${classNames["workspace-main"]}`}>
					<div
						id="heading"
						className={`primary-font-color ${classNames["workspace-main-heading"]}`}
					>
						<p>{workspace?.data?.name} - BUG LIST</p>
					</div>
					<div
						className={`${classNames["workspace-bugs-container"]} w-100 table-background`}
					>
						<div
							className={`mx-0 d-flex flex-nowrap py-2 px-2 justify-content-between ${classNames["workspace-header"]}`}
						>
							<input
								type="text"
								placeholder="Search Bug Title, Bug ID"
								className={`border form-control shadow-none ${classNames["workspace-bug-search"]} table-search table-input`}
								onChange={(e) => {
									setPagination((prev) => ({ ...prev, skip: 0 }));
									setBugs([]);
									setTitle(e.target.value);
								}}
								value={title}
							/>

							<div className="d-flex">
								<Select
									options={memberOptions}
									value={memberOptions.find(
										(option: any) => option.value === reporter,
									)}
									onChange={(e: any) => {
										if (e.value !== reporter) {
											setPagination((prev) => ({ ...prev, skip: 0 }));
											setBugs([]);
											setReporter(e?.value);
										}
									}}
									placeholder="Reporter"
									components={{ DropdownIndicator: CustomDropdownIndicator }}
									styles={{
										menuList: (provided: any) => ({
											...provided,
											// padding: 0, // Remove padding to get rid of the small strip
											backgroundColor: darkMode ? "#1C2A4C" : "#F7F6FB", // Set the background color
										}),
										menu: (provided: any) => ({
											...provided,
											width: 200, // Set the same width as the control
										}),
										control: (provided: any, state: any) => ({
											...provided,
											// backgroundColor: state.isFocused ? "white" : "#F7F6FB",
											backgroundColor: darkMode ? "#1C2A4C" : "#F7F6FB",
											marginBottom: "10px",
											border: "none",
											borderRadius: "6px",
											color: state.isFocused ? "#356EFF" : "#356EFF",
											minHeight: "32px",
											maxHeight: "32px",
											textAlign: "left",
											width: 200,
										}),
										option: (provided: any, state: any) => ({
											...provided,
											// eslint-disable-next-line no-nested-ternary
											backgroundColor: state.isHovered
												? "#7EA2FE"
												: // eslint-disable-next-line no-nested-ternary
													state.isSelected
													? "#356EFF"
													: darkMode
														? "#1C2A4C"
														: "#F7F6FB",
											color:
												state.isHovered || state.isSelected || darkMode
													? "white"
													: "black",
											minHeight: "32px",
											maxHeight: "32px",
											textAlign: "left",
										}),
										singleValue: (provided: any) => ({
											...provided,
											color: "#356EFF",
											textAlign: "left",
										}),
										placeholder: (provided: any) => ({
											...provided,
											color: "#356EFF",
											textAlign: "left",
										}),
									}}
								/>

								<div
									className={`d-flex align-items-center justify-content-between ${classNames["switch-view-container"]}`}
								>
									<div
										className={`d-flex justify-content-start align-items-center h-100
								${classNames["grid-button"]}
								${!tableView ? classNames.highlighted : ""}`}
										onClick={handleTableView}
										onKeyDown={handleTableView}
										role="button"
										tabIndex={0}
									>
										<img
											className={classNames["switch-icon"]}
											src={gridIcon}
											alt="grid-icon"
										/>
									</div>
									<div
										className={`d-flex justify-content-end align-items-center h-100 
								${classNames["table-button"]}
								${tableView ? classNames.highlighted : ""}`}
										onClick={handleTableView}
										onKeyDown={handleTableView}
										role="button"
										tabIndex={0}
									>
										<img
											className={classNames["switch-icon"]}
											src={tableIcon}
											alt="table-icon"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row pb-4 mx-0 table-background">
							{tableView ? (
								<>
									<Table
										Headers={Headers}
										RowData={bugs}
										onRowClick={handleRowClick}
									/>
									<TableFooter
										totalEntries={totalBugs}
										currentPage={pagination.skip + 1}
										onPageChange={(page: number) =>
											setPagination({ ...pagination, skip: page - 1 })
										}
										entriesPerPage={pagination.limit}
									/>
								</>
							) : (
								<>
									{bugs?.length > 0 && (
										<>
											<div className="d-flex flex-wrap gap-3 px-2 mb-2">
												{bugs.map((bug) => {
													return <GridBug details={bug} />;
												})}
												{isFetchingWorkspaceIssues && renderMyComponents()}
											</div>

											<div ref={observerTarget} />
										</>
									)}
									{bugs.length === 0 &&
										workspaceIssues?.data?.length === 0 &&
										!isFetchingWorkspaceIssues && (
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													height: 200,
													fontSize: 16,
													fontWeight: 500,
												}}
											>
												NO BUGS FOUND
											</div>
										)}
									{isFetchingWorkspaceIssues && bugs.length === 0 && (
										<div className="d-flex flex-wrap gap-3 px-2 mb-2">
											<div
												style={{
													minWidth: "31.5%",
													maxWidth: "33.33%",
													height: "250px",
													background: "white",
													border: "1px solid lightgray",
												}}
											>
												<Skeleton count={1} width="100%" height={150} />
												<div style={{ marginTop: 20 }} />
												<div className="d-flex justify-content-around">
													<Skeleton count={1} width={150} />
													<Skeleton count={1} width={100} />
												</div>
											</div>

											<div
												style={{
													width: "31%",
													maxWidth: "33%",
													height: "250px",
													background: "white",
													border: "1px solid lightgray",
												}}
											>
												<Skeleton count={1} width="100%" height={150} />
												<div style={{ marginTop: 20 }} />
												<div className="d-flex justify-content-around">
													<Skeleton count={1} width={150} />
													<Skeleton count={1} width={100} />
												</div>
											</div>

											<div
												style={{
													width: "31%",
													maxWidth: "33%",
													height: "250px",
													background: "white",
													border: "1px solid lightgray",
												}}
											>
												<Skeleton count={1} width="100%" height={150} />
												<div style={{ marginTop: 20 }} />
												<div className="d-flex justify-content-around">
													<Skeleton count={1} width={150} />
													<Skeleton count={1} width={100} />
												</div>
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			)}

			<CreateWorkspaceModal
				show={showCreateWorkspaceModal}
				onHide={() => setShowCreateWorkspaceModal(false)}
			/>
			<TutorialModal
				show={showTutorialModal}
				onHide={() => setShowTutorialModal(false)}
			/>
		</div>
	);
}

export default Workspace;
