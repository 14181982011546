import { useState } from "react";

import arrowIcon from "../../assets/icon/arrow.svg";
import classNames from "./styles.module.scss";

interface HeaderInterface {
	name: string;
	dataIndex?: string;
	sorter?: boolean;
	onClick?: () => void;
	render?: (params: any) => JSX.Element | string;
}
interface TableProps {
	Headers: HeaderInterface[];
	RowData: Array<any>;
	// eslint-disable-next-line react/require-default-props
	onRowClick?: (rowData: any) => void;
}
function Table(props: TableProps) {
	const { Headers, RowData, onRowClick } = props;

	const [sortableHeaders, setSortableHeaders] = useState<{
		[idx: number]: boolean;
	}>();

	const handleSorting = (idx: number) => {
		const header = Headers[idx];
		if (header.sorter) {
			setSortableHeaders({
				...sortableHeaders,
				[idx]: sortableHeaders ? !sortableHeaders[idx] : true,
			});
			if (header.onClick) {
				header.onClick();
			}
		}
	};
	const renderer = (idx: number, data: any, rowKey: string) => {
		if (Headers[idx]) {
			const { render, dataIndex } = Headers[idx];
			if (render) {
				return (
					<td className="text-color" key={rowKey}>
						{render(data)}
					</td>
				);
			}
			if (dataIndex)
				return (
					<td key={rowKey} className="text-color">
						{data[dataIndex]}
					</td>
				);
		}
		return null;
	};

	const transformLogo = (index: number) => {
		if (!sortableHeaders) return "scaleY(1)";

		return sortableHeaders && sortableHeaders[index]
			? "scaleY(-1)"
			: "scaleY(1)";
	};

	return (
		<div style={{ overflowX: "scroll", width: "100%" }}>
			<table className={`table ${classNames.table} `}>
				<thead>
					<tr>
						{Headers?.map((header, index) => {
							return (
								<th
									onClick={() => handleSorting(index)}
									className={`th-${index} ${
										header?.sorter ? classNames.sortable : ""
									} table-border`}
									scope="col"
								>
									{header?.sorter ? (
										<span>
											{header?.name}
											<img
												style={{
													transform: transformLogo(index),
												}}
												className="sort-icon"
												alt="sort"
												src={`${arrowIcon}`}
											/>
										</span>
									) : (
										<span className="table-header-text">{header?.name}</span>
									)}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{RowData &&
						RowData.map((data, key) => {
							return (
								// eslint-disable-next-line
								<tr
									onClick={() => onRowClick && onRowClick(data)}
									role="button"
									tabIndex={0}
									// eslint-disable-next-line react/no-array-index-key
									key={key.toString()}
									className={`table-border ${onRowClick && "clickable"}`}
								>
									{Object.keys(data).map((rowKey, index) => {
										return renderer(index, data, rowKey);
									})}
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}

export default Table;
