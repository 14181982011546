import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

// interface ICreateInvite {
// 	notification: Array<Object>;
// }

export const notificationApi = createApi({
	reducerPath: "notification",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getNotification: builder.query<any, any>({
			query: (data: any) => ({
				url: "/notification",
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					count: data.count,
					skip: data.skip,
					...(data.limit && { limit: 10 }),
				},
			}),
		}),
	}),
});

export const { useGetNotificationQuery } = notificationApi;
