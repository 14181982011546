import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { useCreateWorkspaceMutation } from "../../shared/services/workspace";
import classNames from "./createWorkspaceModal.module.scss";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import "./modal.scss";

function CreateWorkspaceModal(props: any) {
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;

	const { show, onHide } = props;
	const [isSubmitted, setIsSubmitted] = useState(false);

	const [createWorkspace] = useCreateWorkspaceMutation();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	}: any = useForm({
		mode: "onChange",
	});
	const onSubmit = async (data: any) => {
		if (data.workspaceName.trim().length > 0 && !isSubmitted) {
			setIsSubmitted(true);
			const response: any = await createWorkspace({
				name: data?.workspaceName,
			});
			if (!response?.error) {
				toast.success("Workspace created successfully!");
				reset();
				onHide();
				setIsSubmitted(false);
			} else {
				toast.error(response?.error?.data?.message);
				setIsSubmitted(false);
			}

			console.log({ response });
		}
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body
				style={{
					backgroundColor: darkMode ? "#090D17" : "#FFFFFF",
					borderRadius: 18,
					border: "1px solid #356EFF",
					height: 237,
				}}
			>
				<div>
					<div
						className="d-flex justify-content-between w-100"
						style={{ marginBottom: 33 }}
					>
						<div
							className="tb-modal-heading"
							style={{
								color: darkMode ? "white" : "black",
							}}
						>
							NEW WORKSPACE
						</div>
						{darkMode ? (
							<svg
								onClick={onHide}
								style={{
									cursor: "pointer",
								}}
								width="28"
								height="28"
								viewBox="0 0 28 28"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="28" height="28" rx="14" fill="#356EFF" />
								<path
									d="M19.8327 8.16669L8.16602 19.8334"
									stroke="white"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.16602 8.16669L19.8327 19.8334"
									stroke="white"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						) : (
							<svg
								onClick={onHide}
								style={{
									cursor: "pointer",
								}}
								width="28"
								height="28"
								viewBox="0 0 28 28"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="0.5"
									y="0.5"
									width="27"
									height="27"
									rx="13.5"
									stroke="#356EFF"
								/>
								<path
									d="M19.8327 8.16675L8.16602 19.8334"
									stroke="#356EFF"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.16602 8.16675L19.8327 19.8334"
									stroke="#356EFF"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<input
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							type="text"
							id="workspace"
							className="workspace w-100"
							// className="form-control"
							{...register("workspaceName", {
								required: true,
							})}
							autoComplete="off"
							aria-autocomplete="none"
							style={{
								borderRadius: 6,
								background: darkMode ? "#1C2A4C" : "#f7f6fb",
								border: darkMode ? "none" : "1px solid #356EFF",
								padding: 10,
								outline: "none",
								color: darkMode ? "white" : "black",
							}}
							placeholder="Enter Workspace Name"
						/>
						{errors?.workspaceName && (
							<span className="text-danger">Workspace name is required</span>
						)}

						<div
							className="d-flex justify-content-center w-100"
							style={{ marginTop: 40 }}
						>
							<button
								className={classNames["create-workspace-button"]}
								type="submit"
								style={{
									width: 149,
									height: 34,
									background: isSubmitted ? "#CCCCCC" : "#356EFF",
									border: "none",
									color: "white",
									padding: "12px 9px 12px 9px",
									fontSize: 12,
									fontWeight: 600,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									margin: 0,
									cursor: isSubmitted ? "not-allowed" : "pointer",
								}}
								disabled={isSubmitted}
							>
								CREATE WORKSPACE
							</button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
}
export default CreateWorkspaceModal;
