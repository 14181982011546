export default function Microsoft() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.6665 14.668L3.99984 14.668L3.99984 4.0013L14.6665 4.0013L14.6665 14.668Z"
				fill="#FF5722"
			/>
			<path
				d="M28 14.668L17.3333 14.668V4.0013L28 4.0013V14.668Z"
				fill="#4CAF50"
			/>
			<path d="M28 28H17.3333V17.3333H28V28Z" fill="#FFC107" />
			<path
				d="M14.6665 28H3.99984L3.99984 17.3333H14.6665L14.6665 28Z"
				fill="#03A9F4"
			/>
		</svg>
	);
}
