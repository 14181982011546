import { useState } from "react";

import CreateWorkspaceModal from "../../Modal/createWorkspaceModal";
import classNames from "./styles.module.scss";

function PrimaryButton() {
	const [show, setShow] = useState(false);
	return (
		<>
			<button
				onClick={() => setShow(true)}
				type="button"
				className={`text-white text-uppercase ${classNames["primary-button"]}`}
			>
				+ New Workspace
			</button>
			<CreateWorkspaceModal show={show} onHide={() => setShow(false)} />
		</>
	);
}

export default PrimaryButton;
