/* eslint-disable */

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import axios from "axios";

import deleteIcon from "../../../assets/deleteIcon.svg";
import inviteIcon from "../../../assets/inviteIcon.svg";
import plusIcon from "../../../assets/plusIcon.svg";
import classNames from "./styles.module.scss";
import DeleteMemberModal from "../../../components/Modal/deleteMemberModal";
import { useSendInviteMutation } from "../../../shared/services/invite";
import { emailRegex } from "../../../utils/constants";
import crossIcon from "../../../assets/crossIcon.svg";
import DeleteDomainModal from "../../../components/Modal/deleteDomainModal";
import Table from "../../../components/Table";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import TableFooter from "../../../components/Table/TableFooter";
import { useGetMembersQuery } from "../../../shared/services/members";
import {
	useGetWorkspaceQuery,
	useUpdateWorkspaceMutation,
} from "../../../shared/services/workspace";

function CustomDropdownIndicator({ selectProps }: any) {
	const handleKeyDown = (event: any) => {
		if (event.key === "Enter" || event.key === " ") {
			selectProps.onMenuOpen();
		}
	};
	return (
		<div
			className={classNames["custom-dropdown-indicator"]}
			onClick={selectProps.onMenuOpen}
			onKeyDown={handleKeyDown}
			role="button"
			tabIndex={0} // Makes the div focusable
			aria-label="Open dropdown" // Provides an accessible name
		>
			<svg
				width="12"
				height="6"
				viewBox="0 0 12 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M6 6L12 0H0L6 6Z" fill="#356EFF" />
			</svg>
		</div>
	);
}
function Members(props: any) {
	const { workspaceId, memberInfo } = props;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [title, setTitle] = useState<any>("");
	const {
		register: registerDomain,
		handleSubmit: handleSubmitDomain,
		control: controlDomain,
		reset: resetDomain,
		formState: { errors: errorsDomain },
	} = useForm({
		defaultValues: {
			domains: [{ domainName: "" }],
		},
	});

	const {
		register: registerInvitee,
		handleSubmit: handleSubmitInvitee,
		control: controlInvitee,
		reset: resetInvitee,
		setError,
		formState: { errors: errorsInvitee },
	} = useForm({
		defaultValues: {
			invitee: [
				{
					email: "",
					userType: "",
					workspaceId: `${workspaceId}`,
				},
			],
		},
	});

	const {
		fields: domainFields,
		append: appendDomain,
		remove: removeDomain,
	} = useFieldArray({
		control: controlDomain,
		name: "domains",
	});

	const {
		fields: inviteeFields,
		append: appendInvitee,
		remove: removeInvitee,
	} = useFieldArray({
		control: controlInvitee,
		name: "invitee",
	});

	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;
	const [pagination, setPagination] = useState({
		skip: 0,
		limit: 10,
	});
	const [sendInvite] = useSendInviteMutation();

	const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
	const [deleteMemberIdx, setDeleteMemberIdx] = useState<any>(null);
	const [showDeleteDomainModal, setShowDeleteDomainModal] = useState(false);
	const [deleteModalIdx, setDeleteModalIdx] = useState<any>(null);
	const [workspaceMemberList, setWorkspaceMmebrlist] = useState<any>([]);
	const [allowedDomains, setAllowedDomains] = useState<any>([]);
	const [members, setMembers] = useState<any>([]);
	const [selectedRole, setSelectedRole] = useState<any>(null);
	const [skip, setSkip] = useState(0);
	const [limit, setLimit] = useState(5);
	const { data: workspaceMmebers } = useGetMembersQuery(
		{
			workspaceId: workspaceId,
			count: false,
			title,
			role: selectedRole ? selectedRole.value : null,
			skip: skip,
			limit: limit,
		},
		{
			refetchOnMountOrArgChange: true,
		},
	);
	const { data: membersCount } = useGetMembersQuery(
		{
			workspaceId: workspaceId,
			count: true,
			title,
			role: selectedRole ? selectedRole.value : null,
		},
		{
			refetchOnMountOrArgChange: true,
		},
	);

	const handleRoleChange = (selectedOption: any) => {
		setSelectedRole(selectedOption);
	};
	useEffect(() => {
		if (workspaceMmebers) {
			let arr: any = workspaceMmebers?.data;
			let list: any = [];
			arr.map((member: any) => {
				let obj: any = {
					firstName: member?.user?.firstName,
					lastName: member?.user?.lastName,
					email: member?.user?.email,
					role: member?.role,
					_id: member?.user?._id,
				};
				list.push(obj);
			});
			setMembers(list);
		}
	}, [workspaceMmebers]);
	const { data: workspaceData, refetch: refetchWorkspaceData } =
		useGetWorkspaceQuery(
			{
				id: workspaceId,
			},
			{
				refetchOnMountOrArgChange: true,
			},
		);
	useEffect(() => {
		setAllowedDomains(workspaceData?.data?.domainAccess?.domains || []);
	}, [workspaceData]);

	const memberOptions = [
		{ label: "Admin", value: "admin" },
		{ label: "Member", value: "member" },
	];

	const [updateWorkspace] = useUpdateWorkspaceMutation();

	const handleDomainAccessSwitch = async (e: any) => {
		const domainAccess = {
			status: e.target.checked,
			domains: allowedDomains,
		};
		await updateWorkspace({
			_id: workspaceId,
			domainAccess,
		});
		refetchWorkspaceData();
		toast.success("Domain Access status changed successfully!");
	};

	const handleAddMoreDomain = () => {
		appendDomain({ domainName: "" });
	};
	const handleAddMoreInvitee = () => {
		if (workspaceMemberList.length > workspaceData?.membersAllowed) {
			toast.error(
				"Maximum number of members reached! Upgrade plan to add more",
			);
			return;
		}

		appendInvitee({
			email: "",
			userType: "",
			workspaceId: workspaceId,
		});
	};

	const handleDeleteMember = async (idx: number) => {
		const id: any = workspaceMemberList[idx]._id;
		await axios.delete(
			`${process.env.REACT_APP_API_URL}/workspace/member/${id}`,
		);
		const updatedMemberOptions = [
			...members.slice(0, idx),
			...members.slice(idx + 1),
		];
		setMembers(updatedMemberOptions);

		setShowDeleteMemberModal(false);
		toast.success("Member Deleted Successfully!");
	};
	const handleDeleteDomain = async (idx: number) => {
		const updateDomainOpitons = [
			...allowedDomains.slice(0, idx),
			...allowedDomains.slice(idx + 1),
		];
		setAllowedDomains(updateDomainOpitons);
		const domainAccess = {
			status: workspaceData?.data?.domainAccess?.status,
			domains: updateDomainOpitons,
		};
		await updateWorkspace({
			_id: workspaceId,
			domainAccess,
		});
		toast.success("Domain Deleted Successfully!");
		setShowDeleteDomainModal(false);
		refetchWorkspaceData();
	};

	const customInviteMemberSelectStyle = {
		menuList: (provided: any) => ({
			...provided,
			// padding: 0, // Remove padding to get rid of the small strip
			backgroundColor: darkMode ? "#1C2A4C" : "white", // Set the background color
		}),
		menu: (provided: any) => ({
			...provided,
			width: 142, // Set the same width as the control
		}),
		control: (provided: any, state: any) => ({
			...provided,
			color: state.isFocused ? "#356EFF" : "#356EFF",
			minHeight: "32px",
			maxHeight: "32px",
			textAlign: "left",
			marginBottom: "10px",
			backgroundColor: darkMode ? "#1C2A4C" : "white",
			border: "1px solid #356EFF",
			borderRadius: "6px",
			width: 142,
		}),
		option: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isHovered
				? "#7EA2FE"
				: state.isSelected
					? "#356EFF"
					: darkMode
						? "#1C2A4C"
						: "white",
			color:
				state.isHovered || state.isSelected || darkMode ? "white" : "black",
			minHeight: "32px",
			maxHeight: "32px",
			textAlign: "left",
		}),
		singleValue: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
		}),
		input: (provided: any) => ({
			...provided,
			color: "#356eff",
		}),
	};
	const isDomainValid = (email: string) => {
		const domain = email.split("@")[1];
		if (workspaceData && workspaceData?.data?.domainAccess?.status === true) {
			return allowedDomains.some(
				(allowedDomain: any) =>
					domain.toLowerCase() === allowedDomain.domainName.toLowerCase(),
			);
		} else return true;
	};

	const onSubmit = async (data: any) => {
		const domainAccess = {
			status: workspaceData?.data?.domainAccess?.status,
			domains: [...allowedDomains, ...(data?.domains || [])],
		};
		await updateWorkspace({
			_id: workspaceId,
			domainAccess,
		});
		// refetchWorkspaceData();
		toast.success("Domains Added successfully!");
		resetDomain({ domains: [{ domainName: "" }] });
		refetchWorkspaceData();
	};

	const handleSubmitInvite = async (data: any) => {
		try {
			let isError = false;
			data?.invitee?.forEach((item: any, index: number) => {
				const existingMemberIndex = members.findIndex(
					(member: any) => member.email === item.email,
				);
				if (existingMemberIndex !== -1) {
					isError = true;
					setError(`invitee.${index}.email`, {
						type: "manual",
						message: "User is already a member",
					});

					return;
				}
			});
			if (!isError) {
				const inviteSentData: any = await sendInvite({
					invitee: data?.invitee,
				});
				console.log({ inviteSentData });
				if (inviteSentData?.data.success) {
					toast.success(inviteSentData?.data?.message);

					resetInvitee({
						invitee: [
							{
								email: "",
								userType: "",
								workspaceId: workspaceId,
							},
						],
					});
				} else {
					const errorMessage =
						inviteSentData?.data?.message ||
						"An error occurred. Please try again.";
					toast.error(errorMessage);
				}
			}
		} catch (err: any) {
			toast.error(err);
		}
	};
	const Headers = [
		{
			name: "Full Name",
			render: ({
				firstName,
				lastName,
			}: {
				firstName: string;
				lastName: string;
			}) => {
				return <>{`${firstName} ${lastName}`}</>;
			},
		},
		{
			name: "Email",
			dataIndex: "email",
		},
		{
			name: "Role",
			dataIndex: "role",
		},
		{
			name: "Action",
			dataIndex: "Action",
			render: ({ _id }: any) => {
				return (
					<div className="d-flex gap-2">
						<div>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M21.5303 6.93757L17.0624 2.46874C16.9139 2.32013 16.7375 2.20225 16.5433 2.12182C16.3492 2.0414 16.1411 2 15.931 2C15.7208 2 15.5128 2.0414 15.3186 2.12182C15.1245 2.20225 14.9481 2.32013 14.7995 2.46874L2.46899 14.8003C2.31978 14.9483 2.20147 15.1245 2.12096 15.3187C2.04045 15.5128 1.99934 15.721 2.00001 15.9312V20.4001C2.00001 20.8244 2.16857 21.2313 2.46862 21.5314C2.76867 21.8314 3.17562 22 3.59995 22H8.06878C8.27896 22.0007 8.48718 21.9595 8.68134 21.879C8.87549 21.7985 9.0517 21.6802 9.19973 21.531L21.5303 9.20048C21.6789 9.05191 21.7968 8.87552 21.8772 8.68138C21.9576 8.48724 21.999 8.27916 21.999 8.06903C21.999 7.85889 21.9576 7.65081 21.8772 7.45667C21.7968 7.26253 21.6789 7.08614 21.5303 6.93757ZM8.06878 20.4001H3.59995V15.9312L12.3996 7.13156L16.8684 11.6004L8.06878 20.4001ZM17.9994 10.4684L13.5306 6.00061L15.9305 3.6007L20.3993 8.06853L17.9994 10.4684Z"
									fill="#A59F9F"
								/>
							</svg>
						</div>
						<div>
							<svg
								width="19"
								height="20"
								viewBox="0 0 19 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M17.6923 3.07692H13.8462V2.30769C13.8462 1.69565 13.603 1.10868 13.1702 0.675907C12.7375 0.243131 12.1505 0 11.5385 0H6.92308C6.31104 0 5.72407 0.243131 5.29129 0.675907C4.85852 1.10868 4.61538 1.69565 4.61538 2.30769V3.07692H0.769231C0.565218 3.07692 0.369561 3.15797 0.225303 3.30223C0.0810439 3.44648 0 3.64214 0 3.84615C0 4.05017 0.0810439 4.24582 0.225303 4.39008C0.369561 4.53434 0.565218 4.61538 0.769231 4.61538H1.53846V18.4615C1.53846 18.8696 1.70055 19.2609 1.98907 19.5494C2.27758 19.8379 2.6689 20 3.07692 20H15.3846C15.7926 20 16.184 19.8379 16.4725 19.5494C16.761 19.2609 16.9231 18.8696 16.9231 18.4615V4.61538H17.6923C17.8963 4.61538 18.092 4.53434 18.2362 4.39008C18.3805 4.24582 18.4615 4.05017 18.4615 3.84615C18.4615 3.64214 18.3805 3.44648 18.2362 3.30223C18.092 3.15797 17.8963 3.07692 17.6923 3.07692ZM6.15385 2.30769C6.15385 2.10368 6.23489 1.90802 6.37915 1.76376C6.52341 1.61951 6.71906 1.53846 6.92308 1.53846H11.5385C11.7425 1.53846 11.9381 1.61951 12.0824 1.76376C12.2266 1.90802 12.3077 2.10368 12.3077 2.30769V3.07692H6.15385V2.30769ZM15.3846 18.4615H3.07692V4.61538H15.3846V18.4615ZM7.69231 8.46154V14.6154C7.69231 14.8194 7.61126 15.0151 7.46701 15.1593C7.32275 15.3036 7.12709 15.3846 6.92308 15.3846C6.71906 15.3846 6.52341 15.3036 6.37915 15.1593C6.23489 15.0151 6.15385 14.8194 6.15385 14.6154V8.46154C6.15385 8.25753 6.23489 8.06187 6.37915 7.91761C6.52341 7.77335 6.71906 7.69231 6.92308 7.69231C7.12709 7.69231 7.32275 7.77335 7.46701 7.91761C7.61126 8.06187 7.69231 8.25753 7.69231 8.46154ZM12.3077 8.46154V14.6154C12.3077 14.8194 12.2266 15.0151 12.0824 15.1593C11.9381 15.3036 11.7425 15.3846 11.5385 15.3846C11.3344 15.3846 11.1388 15.3036 10.9945 15.1593C10.8503 15.0151 10.7692 14.8194 10.7692 14.6154V8.46154C10.7692 8.25753 10.8503 8.06187 10.9945 7.91761C11.1388 7.77335 11.3344 7.69231 11.5385 7.69231C11.7425 7.69231 11.9381 7.77335 12.0824 7.91761C12.2266 8.06187 12.3077 8.25753 12.3077 8.46154Z"
									fill="#929292"
								/>
							</svg>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<div>
			{memberInfo?.role !== "member" && (
				<div className="d-flex gap-4">
					<div className={`${classNames["domain-access-text"]} text-color`}>
						Domain Access
					</div>
					<div
						style={{
							alignSelf: "flex-start",
							marginTop: "2px",
						}}
						className="form-check form-switch align-self-flex-start"
					>
						<input
							style={{
								height: "20px",
								width: "40px",
							}}
							className="form-check-input"
							type="checkbox"
							checked={workspaceData?.data?.domainAccess?.status}
							onChange={(e) => handleDomainAccessSwitch(e)}
							role="switch"
							name="domainAccess"
							id="domainAccessSwitch"
						/>
					</div>
				</div>
			)}
			{memberInfo?.role !== "member" && (
				<div className={`mt-2 ${classNames["domain-access-description"]}`}>
					Allow team member with an email from allowed domain will be able to
					join with workspace by invite link
				</div>
			)}

			{workspaceData?.data?.domainAccess?.status &&
				memberInfo?.role !== "member" && (
					<div className="mt-2">
						<div className={`${classNames["allowed-domain-text"]} text-color`}>
							Allowed Domain
						</div>
						<form onSubmit={handleSubmitDomain(onSubmit)}>
							<div className={` ${classNames["allowed-domain-container"]}`}>
								{domainFields.map((field: any, index: number) => {
									return (
										<div className="d-flex gap-4" key={field.id}>
											<div className="mt-2">
												<input
													type="text"
													className={`p-2 form-control ${
														errorsDomain.domains?.[index] ? "is-invalid" : ""
													} ${
														classNames["allowed-domain-input"]
													} input-color-settings`}
													placeholder="example.com"
													{...registerDomain(`domains.${index}.domainName`, {
														required: "Domain is required",
														pattern: {
															value: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
															message: "Invalid domain format",
														},
													})}
												/>
												{errorsDomain.domains?.[index] && (
													<div
														className={`text-danger ${classNames["warning-text"]}`}
													>
														Invalid domain format
													</div>
												)}
											</div>
											{index !== 0 && (
												<div
													onClick={() => removeDomain(index)}
													onKeyDown={(e) =>
														e.key === "Enter" && removeDomain(index)
													}
													role="button"
													tabIndex={0}
												>
													<img
														src={crossIcon}
														alt="crossIcon"
														style={{ paddingTop: "10px", cursor: "pointer" }}
													/>
												</div>
											)}
										</div>
									);
								})}

								<div className="d-flex justify-content-between mt-2">
									<div
										className="d-flex gap-2"
										style={{ cursor: "pointer" }}
										onClick={handleAddMoreDomain}
										onKeyDown={(e) =>
											e.key === "Enter" && handleAddMoreDomain()
										}
										role="button"
										tabIndex={0}
									>
										<div>
											<img
												src={plusIcon}
												alt="plusicon"
												className="image-invert"
											/>
										</div>
										<div
											className={`${classNames["add-more-members-text"]} text-color`}
										>
											Add More
										</div>
									</div>
									<button
										className={`${classNames["add-domain-btn"]} add-status-btn-color`}
										// onClick={handleAddDomain}
										type="submit"
										style={{ cursor: "pointer", color: "#356eff" }}
									>
										Add Domain
									</button>
								</div>
							</div>
						</form>
					</div>
				)}
			{workspaceData?.data?.domainAccess?.status &&
				memberInfo?.role !== "member" &&
				allowedDomains &&
				allowedDomains.map((domain: any, index: any) => {
					return (
						<div className="d-flex gap-4 mt-3">
							<div>
								<input
									className={`form-control p-2 ${classNames["domain-access-input"]} input-color-settings`}
									type="text"
									value={domain?.domainName}
								/>
							</div>
							<div
								className="d-flex justify-content-center align-items-center"
								onClick={() => {
									setDeleteModalIdx(index);
									setShowDeleteDomainModal(true);
								}}
								role="button"
								tabIndex={0}
								onKeyDown={(e) =>
									e.key === "Enter" && setShowDeleteDomainModal(true)
								}
							>
								<div>
									<img
										src={deleteIcon}
										alt="deleteIcon"
										style={{ paddingBottom: "6px", cursor: "pointer" }}
									/>
								</div>
							</div>
						</div>
					);
				})}
			{memberInfo?.role !== "member" && (
				<form onSubmit={handleSubmitInvitee(handleSubmitInvite)}>
					<div id="invite-members-section" className="mt-4">
						<div className="d-flex gap-2">
							<div className={`${classNames["domain-access-text"]} text-color`}>
								Invite Members
							</div>
							<div
								className={`mt-1 ${classNames["domain-access-description"]}`}
							>
								Invite your team to see and add to your workspace.
							</div>
						</div>
						{inviteeFields.map((inviteMmeber: any, index: number) => {
							return (
								<div
									className={`d-flex gap-3 mt-2 ${classNames["invite-members-options"]}`}
								>
									<div className={`${classNames["error-email-text"]}`}>
										<input
											type="text"
											className={`form-control p-2 ${classNames["invite-member-input"]} input-color-settings`}
											{...registerInvitee(`invitee.${index}.email`, {
												required: "Email is required",
												pattern: {
													value: emailRegex,
													message: "Invalid email format",
												},
												validate: {
													domainCheck: (value: any) =>
														isDomainValid(value) ||
														"Email domain is not allowed",
												},
											})}

											// onChange={(e: any) => {
											// 	handleInviteEmailChange(e, index);
											// }}
											// value={valueInvitee[index]?.email}
										/>
										{errorsInvitee.invitee &&
											errorsInvitee.invitee[index]?.email && (
												<div
													className={`text-danger ${classNames["warning-text"]}`}
												>
													{(errorsInvitee as any).invitee[index].email.message}
												</div>
											)}
									</div>
									<div className={` ${classNames["invite-member-select"]}`}>
										<Controller
											name={`invitee.${index}.userType`}
											control={controlInvitee}
											rules={{ required: "User type is required" }}
											render={({ field }) => (
												<Select
													{...field}
													options={memberOptions as any}
													onChange={(option) =>
														field.onChange(option ? option.value : "")
													}
													value={memberOptions.find(
														(option) => option.value === field.value,
													)}
													styles={customInviteMemberSelectStyle}
													components={{
														DropdownIndicator: CustomDropdownIndicator,
													}}
												/>
											)}
										/>
										{/* {errorsInvitee.invitee && errorsInvitee.invitee[index]?.userType && (
										<div className="text-danger">
											{(errorsInvitee as any).invitee[index].userType.message}
										</div>
									)} */}
									</div>
									{index !== 0 && (
										<div
											onClick={() => removeInvitee(index)}
											role="button"
											tabIndex={0}
											onKeyDown={(e) =>
												e.key === "Enter" && removeInvitee(index)
											}
										>
											<img
												src={crossIcon}
												alt="crossIcon"
												style={{ paddingLeft: "6px", cursor: "pointer" }}
											/>
										</div>
									)}
								</div>
							);
						})}

						<div
							className={`d-flex justify-content-between gap-2 mt-2 ${classNames["add-more-members-container"]} `}
						>
							<div
								className="d-flex gap-2"
								style={{ cursor: "pointer" }}
								onClick={handleAddMoreInvitee}
								role="button"
								tabIndex={0}
								onKeyDown={(e) => e.key === "Enter" && handleAddMoreInvitee()}
							>
								<div>
									<img src={plusIcon} alt="plusicon" className="image-invert" />
								</div>
								<div
									className={`${classNames["add-more-members-text"]} text-color`}
								>
									Add More
								</div>
							</div>

							<button
								className={`d-flex justify-content-center align-items-center ${classNames["invite-member-btn"]} add-status-btn-color`}
								// onClick={handleSendInvite}
								type="submit"
							>
								<div>
									<img
										src={inviteIcon}
										alt="inviteIcon"
										style={{ paddingBottom: "2px" }}
									/>
								</div>
								<div className={`${classNames["invite-member-text"]}`}>
									INVITE
								</div>
							</button>
						</div>
					</div>
				</form>
			)}

			<div id="manage-members-section" className="mt-4">
				<div className={`${classNames["domain-access-text-table"]} text-color`}>
					{memberInfo?.role === "member" ? "Member List" : "Manage Members"}
				</div>
				<div
					className={`mt-2 ${classNames["manage-members-container"]} table-background w-100`}
				>
					<div className="d-flex gap-3 p-2">
						<div>
							<input
								type="text"
								placeholder="Search Member"
								onChange={(e: any) => setTitle(e.target.value)}
								className={`form-control p-2 ${classNames["search-member-input"]} table-input`}
							/>
						</div>
						<div className={classNames["filter-by-role"]}>
							<Select
								options={memberOptions}
								onChange={handleRoleChange}
								placeholder="Filter by Role"
								components={{ DropdownIndicator: CustomDropdownIndicator }}
								styles={{
									menuList: (provided: any) => ({
										...provided,
										// padding: 0, // Remove padding to get rid of the small strip
										backgroundColor: darkMode ? "#1C2A4C" : "#F7F6FB", // Set the background color
									}),
									menu: (provided) => ({
										...provided,
										width: 200, // Set the same width as the control
									}),
									control: (provided: any, state: any) => ({
										...provided,
										// backgroundColor: state.isFocused ? "white" : "#F7F6FB",
										backgroundColor: darkMode ? "#1C2A4C" : "#F7F6FB",
										marginBottom: "10px",
										border: "none",
										borderRadius: "6px",
										color: state.isFocused ? "#356EFF" : "#356EFF",
										minHeight: "32px",
										maxHeight: "32px",
										textAlign: "left",
										width: 200,
									}),
									option: (provided: any, state: any) => ({
										...provided,
										backgroundColor: state.isHovered
											? "#7EA2FE"
											: state.isSelected
												? "#356EFF"
												: darkMode
													? "#1C2A4C"
													: "#F7F6FB",
										color:
											state.isHovered || state.isSelected || darkMode
												? "white"
												: "black",
										minHeight: "32px",
										maxHeight: "32px",
										textAlign: "left",
									}),
									singleValue: (provided: any) => ({
										...provided,
										color: "#356EFF",
										textAlign: "left",
									}),
									placeholder: (provided: any) => ({
										...provided,
										color: "#356EFF",
										textAlign: "left",
									}),
									input: (provided: any) => ({
										...provided,
										color: "#356eff",
									}),
								}}
							/>
						</div>
					</div>
					<div className="p-2 members-table">
						<Table Headers={Headers} RowData={members} />
						<TableFooter
							totalEntries={membersCount}
							currentPage={skip + 1}
							onPageChange={(page: number) => setSkip(page - 1)}
							entriesPerPage={5}
						/>
						{/* <div className="row p-2">
							<div className={`col-3 text-start ${classNames["table-header"]}`}>
								Full Name
							</div>
							<div className={`col-3 text-start ${classNames["table-header"]}`}>
								Email ID
							</div>
							<div className={`col-1 text-start ${classNames["table-header"]}`}>
								Role
							</div>
							<div className={`col-5 text-start ${classNames["table-header"]}`}>
								Action
							</div>
						</div> */}
						{/* {members.map((member: any, idx: number) => {
							return (
								<Member
									member={member}
									idx={idx}
									setShowDeleteMemberModal={setShowDeleteMemberModal}
									setDeleteMemberIdx={setDeleteMemberIdx}
								/>
							);
						})} */}
					</div>
				</div>
			</div>
			<DeleteMemberModal
				show={showDeleteMemberModal}
				onHide={() => setShowDeleteMemberModal(false)}
				handleDelete={() => handleDeleteMember(deleteMemberIdx)}
			/>
			<DeleteDomainModal
				show={showDeleteDomainModal}
				onHide={() => setShowDeleteDomainModal(false)}
				handleDelete={() => handleDeleteDomain(deleteModalIdx)}
			/>
		</div>
	);
}

export default Members;
