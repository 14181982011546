import { Modal } from "react-bootstrap";

import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import "./modal.scss";

function TutorialModal(props: any) {
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;

	const { show, onHide } = props;

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			size="lg"
		>
			<Modal.Body
				style={{
					backgroundColor: darkMode ? "#090D17" : "#FFFFFF",
					borderRadius: 18,
					border: "1px solid #356EFF",
					height: "70vh",
					padding: 0,
				}}
			>
				<div
					style={{
						position: "relative",
						// paddingBottom: "56.25%",
						height: "100%",
						width: "100%",
						overflow: "hidden",
						borderRadius: "18px",
					}}
				>
					<iframe
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							borderRadius: "16px",
						}}
						src="https://www.youtube.com/embed/nV4Uoj08NFw?si=77kDW-JeUadMPZhX"
						title="YouTube video player"
						// frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						// allowfullscreen
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default TutorialModal;
