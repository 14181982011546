import { useEffect } from "react";

function Youtrack() {
	// const [jiraIntegration] = useJiraIntegrationMutation();

	const api = async () => {
		// await jiraIntegration(data);
		setTimeout(() => {
			window.close();
		}, 5000);
	};

	useEffect(() => {
		api();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex flex-column justify-content-center align-items-center primary-background">
			<div>Authorisation Success</div>
			<div>You may close this tab now</div>
		</div>
	);
}

export default Youtrack;
