enum NotificationTypes {
	// GENERAL
	INVITE = "INVITE",
	FORGOTPASSWORD = "FORGOTPASSWORD",
	VERIFICATION = "VERIFICATION",
	WELCOMEUSER = "WELCOMEUSER",
	FEEDBACK = "FEEDBACK",

	// WORKSPACE ACTIONS
	WORKSPACE_CREATED = "WORKSPACE_CREATED",
	// name update, status update, deleted
	WORKSPACE_UPDATED = "WORKSPACE_UPDATED",
	WORKSPACE_NAME_UPDATED = "WORKSPACE_NAME_UPDATED",
	WORKSPACE_STATUS_UPDATED = "WORKSPACE_STATUS_UPDATED",
	WORKSPACE_DELETION = "WORKSPACE_DELETION",

	WORKSPACE_MEMBER_UPDATED = "WORKSPACE_MEMBER_UPDATED",
	TESTBUDDY_AI = "TESTBUDDY_AI",

	// ISSUE
	ISSUE_CREATED = "ISSUE_CREATED",
	BUG_STATUS_CHANGED = "BUG_STATUS_CHANGED",

	// TAGGED IN COMMENTS
	TAGGED = "TAGGED",

	// SUBSCRIPTION
	SUBSCRIPTION_RENEWAL = "SUBSCRIPTION_RENEWAL",
}

export default NotificationTypes;
