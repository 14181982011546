import {
	BaseQueryApi,
	FetchArgs,
	fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

// import { setCredentials, logout } from '@/redux/auth/authSlice';

// import { RootState } from "../../redux/store";
import config from "../../utils/config";
import { setCredentials } from "../features/auth";

// create a new mutex
const mutex = new Mutex();
// Configuration object containing a `baseUrl`, a `prepareHeaders` and the `credentials` option
const baseQuery = fetchBaseQuery({
	baseUrl: config.app.backendURL,
	fetchFn: fetch,
	prepareHeaders(headers, { getState }) {
		// Add type
		const { token } = (getState() as any).auth;
		if (token) {
			headers.set("Content-Type", "application/json");
			headers.set("authorization", `Bearer ${token}`);
		}
		return headers;
	},
	credentials: "include",
});

// Extends the behavior of the `baseQuery`
const baseQueryWithReauth = async (
	args: string | FetchArgs,
	api: BaseQueryApi,
	extraOptions: {},
) => {
	await mutex.waitForUnlock();
	let result = await baseQuery(args, api, extraOptions);
	if (result.error && result.error.status === 401) {
		// checking whether the mutex is locked
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
			try {
				const refreshResult = baseQuery(
					{ url: "/user/refresh", method: "POST", credentials: "include" },
					api,
					extraOptions,
				) as {
					data: { data: { accessToken: string } };
				};
				if (refreshResult.data) {
					api.dispatch(
						setCredentials({ token: refreshResult.data.data.accessToken }),
					);
					// retry the original query with new access token
					result = await baseQuery(args, api, extraOptions);
				} else {
					// api.dispatch(loggedOut());
				}
			} finally {
				// release must be called once the mutex should be released again.
				release();
			}
		} else {
			// wait until the mutex is available without locking it
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	}
	return result;
};

export default baseQueryWithReauth;
/* ----------------------------------------------------EXPLAINATION--------------------------------------------------------

This code sets up an API slice using Redux Toolkit's `createApi` and `fetchBaseQuery` functions to handle API requests, including authentication with JWT (JSON Web Tokens) tokens. Let's go through the code step-by-step:

1. Import Statements: The code imports various modules and functions from different files, including Redux Toolkit's query-related components (`BaseQueryApi`, `FetchArgs`, `createApi`, `fetchBaseQuery`), Redux actions (`setCredentials`, `logout`), and other custom utility files.

2. `baseQuery`: It sets up the `baseQuery` by calling `fetchBaseQuery`. The `baseQuery` function is responsible for making HTTP requests to the server. It takes a configuration object containing a `baseUrl`, a `prepareHeaders` function to set headers, and the `credentials` option for how to send credentials with the requests.

3. `baseQueryWithReauth`: This is an asynchronous function that extends the behavior of the `baseQuery` by intercepting and handling 403 (Forbidden) errors. If a request results in a 403 error, it means the access token has expired or is invalid.

   - The function receives three parameters:
     - `args`: The URL or fetch arguments for the API request.
     - `api`: The `BaseQueryApi` object provided by Redux Toolkit's `createApi`.
     - `extraOptions`: Additional options for the API request (not used in this implementation).

   - If the request results in a 403 error, the function attempts to refresh the access token by making a request to the `/refresh` endpoint.
   
   - If the token is successfully refreshed, it updates the token in the store (`authSlice`) using the `setCredentials` action, then retries the original request with the new access token.
   
   - If the token refresh fails or no new token is obtained, it logs out the user by dispatching the `logout` action.

   - Finally, it returns the result of the API request, which could be the original response or the retried response after token refresh.

4. `apiSlice`: The `createApi` function is called to create the API slice. It takes an object with a `baseQuery` property, which is set to `baseQueryWithReauth`. The `endpoints` property is an empty object `{}` in this implementation, which means there are no additional endpoints defined.

In summary, this code sets up an API slice with Redux Toolkit's `createApi` and extends the behavior of the `baseQuery` to handle token refresh in case of 403 errors. The `baseQueryWithReauth` function acts as a middleware to handle authentication and token refreshing before making the actual API requests.

*/
