import "./BugDetailsSkeleton.scss";
import "../index.scss";

function BugDetailsSkeleton() {
	return (
		<div className="d-flex flex-column justify-content-start align-items-between skeleton-wrapper">
			<div className="d-flex skeleton-cta-wrapper">
				<div className="skeleton workspace-skeleton" />
				<div className="d-flex gap-2">
					<div className="skeleton copy-link-skeleton" />
					<div className="skeleton share-bug-skeleton" />
					<div className="skeleton delete-skeleton" />
				</div>
			</div>
			<div className="d-flex skelete-data-wrapper">
				<div className="left-skeleton">
					<div className="skeleton image-skeleton" />
					<div className="skeleton title-skeleton" />
					<div className="skeleton desc-skeleton" />
					<div className="d-flex justify-content-between skeleton-title-wrapper">
						<div className="skeleton comments-head-skeleton" />
						<div className="skeleton comments-count-skeleton" />
					</div>
					<div className="comments-skeleton skeleton" />
				</div>
				<div className="skeleton-tab-wrapper">
					<div className="skeleton tabs-skeleton" />
					<div className="skeleton-tab-pane skeleton tab-pane-skeleton" />
				</div>
			</div>
		</div>
	);
}

export default BugDetailsSkeleton;
