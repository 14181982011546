import axios from "axios";

const serverURL = process.env.REACT_APP_API_URL;
let urlIndex = 0;
let urlIndex1 = 0;
let Generatedscript;
let lines;
export const attributesArrayFilter = (attributes: any) => {
	const filteredAttributes = attributes.filter((obj: any) => {
		const keys = Object.keys(obj);
		return (
			keys.includes("class") || keys.includes("id") || keys.includes("name")
		);
	});

	const result = filteredAttributes
		.map((obj: any) => {
			const keys = Object.keys(obj);
			return keys.map((key) => `${key}: ${obj[key]}`).join(" ");
		})
		.join(" ");

	return result;
};

export const asyncSeleniumJavaScriptGeneration = async (
	steps: any,
	urlArray: any,
) => {
	const seleniumJava = await axios.post(
		`${serverURL}/testscript/seleniumjava`,
		{
			steps,
		},
	);
	console.log({ seleniumJava });
	let seleniumJavaScript = seleniumJava.data.seleniumJava;

	urlIndex = 0;
	seleniumJavaScript = seleniumJavaScript.replace(
		/\/\/.*url\d+/g,
		(match: any) => {
			// eslint-disable-next-line no-plusplus
			urlIndex++;
			return match.replace(/url\d+/, `${urlArray[urlIndex - 1]}`);
		},
	);

	urlIndex1 = 0;
	seleniumJavaScript = seleniumJavaScript.replace(/url\d+/g, () => {
		// eslint-disable-next-line no-plusplus
		urlIndex1++;
		return `${urlArray[urlIndex1 - 1]}`;
	});

	// code to remove template literal, Notes undefined from script

	Generatedscript = seleniumJavaScript;
	lines = Generatedscript.split("\n");
	if (Generatedscript.startsWith("```")) {
		// Remove the first line by slicing the array
		lines.shift();

		// Join the remaining lines back into a single string
		Generatedscript = lines.join("\n");
	}
	lines = Generatedscript.split("\n");
	if (
		lines[lines.length - 1].trim().startsWith("Note: ") ||
		lines[lines.length - 1].trim().startsWith("```")
	) {
		// Remove the last line
		if (lines[lines.length - 1].trim().startsWith("Note: ")) {
			lines.pop();
			if (lines[lines.length - 1].trim().startsWith("```")) {
				lines.pop();
			}
		} else {
			lines.pop();
		}
	}
	Generatedscript = lines.join("\n");
	return Generatedscript;
};

export const seleniumCSScriptGeneration = async (steps: any, urlArray: any) => {
	const seleniumCS = await axios.post(`${serverURL}/testscript/seleniumcs`, {
		steps,
	});
	console.log({ seleniumCS });
	let seleniumCSScript = seleniumCS.data.seleniumCS;

	urlIndex = 0;
	seleniumCSScript = seleniumCSScript.replace(/\/\/.*url\d+/g, (match: any) => {
		// eslint-disable-next-line no-plusplus
		urlIndex++;
		return match.replace(/url\d+/, `${urlArray[urlIndex - 1]}`);
	});

	urlIndex1 = 0;
	seleniumCSScript = seleniumCSScript.replace(/url\d+/g, () => {
		// eslint-disable-next-line no-plusplus
		urlIndex1++;
		return `${urlArray[urlIndex1 - 1]}`;
	});

	Generatedscript = seleniumCSScript;
	lines = Generatedscript.split("\n");
	if (Generatedscript.startsWith("```")) {
		// Remove the first line by slicing the array
		lines.shift();

		// Join the remaining lines back into a single string
		Generatedscript = lines.join("\n");
	}
	lines = Generatedscript.split("\n");
	if (
		lines[lines.length - 1].trim().startsWith("Note: ") ||
		lines[lines.length - 1].trim().startsWith("```")
	) {
		// Remove the last line
		if (lines[lines.length - 1].trim().startsWith("Note: ")) {
			lines.pop();
			if (lines[lines.length - 1].trim().startsWith("```")) {
				lines.pop();
			}
		} else {
			lines.pop();
		}
	}
	Generatedscript = lines.join("\n");
	return Generatedscript;
};

export const seleniumPYScriptGeneration = async (steps: any, urlArray: any) => {
	const seleniumPY = await axios.post(`${serverURL}/testscript/seleniumpy`, {
		steps,
	});
	console.log({ seleniumPY });
	let seleniumPYScript = seleniumPY.data.seleniumPy;

	urlIndex = 0;
	seleniumPYScript = seleniumPYScript.replace(/\/\/.*url\d+/g, (match: any) => {
		// eslint-disable-next-line no-plusplus
		urlIndex++;
		return match.replace(/url\d+/, `${urlArray[urlIndex - 1]}`);
	});
	// /\/\/.*|#.*/g
	let urlIndex2 = 0;
	seleniumPYScript = seleniumPYScript.replace(
		/\/\/.*|#.*url\d+/g,
		(match: any) => {
			// eslint-disable-next-line no-plusplus
			urlIndex2++;
			return match.replace(/url\d+/, `${urlArray[urlIndex2 - 1]}`);
		},
	);

	urlIndex1 = 0;
	seleniumPYScript = seleniumPYScript.replace(/url\d+/g, () => {
		// eslint-disable-next-line no-plusplus
		urlIndex1++;
		return `${urlArray[urlIndex1 - 1]}`;
	});

	Generatedscript = seleniumPYScript;
	lines = Generatedscript.split("\n");
	if (Generatedscript.startsWith("```")) {
		// Remove the first line by slicing the array
		lines.shift();

		// Join the remaining lines back into a single string
		Generatedscript = lines.join("\n");
	}
	lines = Generatedscript.split("\n");
	if (
		lines[lines.length - 1].trim().startsWith("Note: ") ||
		lines[lines.length - 1].trim().startsWith("```")
	) {
		// Remove the last line
		if (lines[lines.length - 1].trim().startsWith("Note: ")) {
			lines.pop();
			if (lines[lines.length - 1].trim().startsWith("```")) {
				lines.pop();
			}
		} else {
			lines.pop();
		}
	}
	Generatedscript = lines.join("\n");
	return Generatedscript;
};

export const asyncCypressScriptGeneration = async (
	steps: any,
	urlArray: any,
) => {
	const cypress = await axios.post(`${serverURL}/testscript/cypressjs`, {
		steps,
	});
	console.log({ cypress });
	let { cypressScript } = cypress.data;

	urlIndex = 0;

	urlIndex1 = 0;

	// Split the cypressScript into lines
	const scriptLines = cypressScript.split("\n");

	// Process each line individually
	for (let i = 0; i < scriptLines.length; i += 1) {
		const line = scriptLines[i].trim();

		// Check if the line starts with "it"
		if (!line.startsWith("it")) {
			// Perform the replacement for URLs in this line
			// eslint-disable-next-line @typescript-eslint/no-loop-func
			scriptLines[i] = line.replace(/url\d+/g, () => {
				// eslint-disable-next-line no-plusplus
				urlIndex1++;
				return `${urlArray[urlIndex1 - 1]}`;
			});
		}
	}

	// Join the lines back into a single string
	cypressScript = scriptLines.join("\n");
	urlIndex1 = 0;
	cypressScript = cypressScript.replace(/url\d+/g, () => {
		// eslint-disable-next-line no-plusplus
		urlIndex1++;
		return `${urlArray[urlIndex1 - 1]}`;
	});
	// Function to add indentation to each line
	function addIndentation(script: any, spaces: any) {
		lines = script.split("\n");
		const currentIndent = 0;
		const indentedLines = lines.map((line: any) => {
			const trimmedLine = line.trim();
			if (trimmedLine.startsWith("cy.")) {
				// If the line starts with 'cy.', use the current indent level plus additional spaces
				return `${" ".repeat(currentIndent)}${" ".repeat(spaces)}${trimmedLine}`;
			}
			// Otherwise, maintain the current indent level
			return `${" ".repeat(currentIndent)}${line}`;
		});
		return indentedLines.join("\n");
	}

	const script = addIndentation(cypressScript, 2);
	console.log("on cypress");
	Generatedscript = script;
	lines = Generatedscript.split("\n");
	if (Generatedscript.startsWith("```")) {
		// Remove the first line by slicing the array
		lines.shift();

		// Join the remaining lines back into a single string
		Generatedscript = lines.join("\n");
	}
	lines = Generatedscript.split("\n");
	if (
		lines[lines.length - 1].trim().startsWith("Note: ") ||
		lines[lines.length - 1].trim().startsWith("```")
	) {
		// Remove the last line
		if (lines[lines.length - 1].trim().startsWith("Note: ")) {
			lines.pop();
			if (lines[lines.length - 1].trim().startsWith("```")) {
				lines.pop();
			}
		} else {
			lines.pop();
		}
	}
	Generatedscript = lines.join("\n");
	return Generatedscript;
};

export const asyncPlayWrightJavaScriptGeneration = async (
	steps: any,
	urlArray: any,
) => {
	const playwrightJava = await axios.post(
		`${serverURL}/testscript/playwrightjava`,
		{
			steps,
		},
	);
	console.log({ playwrightJava });
	let playwrightJavaScript = playwrightJava.data.playwrightJava;
	urlIndex = 0;
	playwrightJavaScript = playwrightJavaScript.replace(
		/\/\/.*url\d+/g,
		(match: any) => {
			// eslint-disable-next-line no-plusplus
			urlIndex++;
			return match.replace(/url\d+/, `\`${urlArray[urlIndex - 1]}\``);
		},
	);

	urlIndex1 = 0;
	playwrightJavaScript = playwrightJavaScript.replace(/url\d+/g, () => {
		// eslint-disable-next-line no-plusplus
		urlIndex1++;
		return `${urlArray[urlIndex1 - 1]}`;
	});

	console.log(playwrightJavaScript);
	Generatedscript = playwrightJavaScript;
	lines = Generatedscript.split("\n");
	if (Generatedscript.startsWith("```")) {
		// Remove the first line by slicing the array
		lines.shift();

		// Join the remaining lines back into a single string
		Generatedscript = lines.join("\n");
	}
	lines = Generatedscript.split("\n");
	if (
		lines[lines.length - 1].trim().startsWith("Note: ") ||
		lines[lines.length - 1].trim().startsWith("```")
	) {
		// Remove the last line
		if (lines[lines.length - 1].trim().startsWith("Note: ")) {
			lines.pop();
			if (lines[lines.length - 1].trim().startsWith("```")) {
				lines.pop();
			}
		} else {
			lines.pop();
		}
	}
	Generatedscript = lines.join("\n");
	return Generatedscript;
};

export const asyncPlaywrightCSScriptGeneration = async (
	steps: any,
	urlArray: any,
) => {
	const playwrightCS = await axios.post(
		`${serverURL}/testscript/playwrightcs`,
		{
			steps,
		},
	);
	console.log({ playwrightCS });
	let playwrightCSScript = playwrightCS.data.playwrightCS;

	let CSurlIndex = 0;
	playwrightCSScript = playwrightCSScript.replace(
		/\/\/.*url\d+/g,
		(match: any) => {
			// eslint-disable-next-line no-plusplus
			CSurlIndex++;
			return match.replace(/url\d+/, `${urlArray[CSurlIndex - 1]}`);
		},
	);

	// playwrightCSScript = playwrightCSScript.replace(/\/\/.*text\d+/g, (match) => {
	//   const urlPlaceholder = `text${CStextIndex + 1}`;
	//   CStextIndex++;
	//   return match.replace(/text\d+/, `\`${textArray[CStextIndex - 1]}\``);
	// });

	let CSurlIndex1 = 0;
	playwrightCSScript = playwrightCSScript.replace(/url\d+/g, () => {
		// eslint-disable-next-line no-plusplus
		CSurlIndex1++;
		return `${urlArray[CSurlIndex1 - 1]}`;
	});
	// let CStextIndex1 = 0;
	// playwrightCSScript = playwrightCSScript.replace(/text\d+/g, (match) => {
	//   const urlPlaceholder = `url${CStextIndex1 + 1}`;
	//   CStextIndex1++;
	//   return `\`${textArray[CStextIndex1 - 1]}\``;
	// });
	Generatedscript = playwrightCSScript;
	lines = Generatedscript.split("\n");
	if (Generatedscript.startsWith("```")) {
		// Remove the first line by slicing the array
		lines.shift();

		// Join the remaining lines back into a single string
		Generatedscript = lines.join("\n");
	}
	lines = Generatedscript.split("\n");
	if (
		lines[lines.length - 1].trim().startsWith("Note: ") ||
		lines[lines.length - 1].trim().startsWith("```")
	) {
		// Remove the last line
		if (lines[lines.length - 1].trim().startsWith("Note: ")) {
			lines.pop();
			if (lines[lines.length - 1].trim().startsWith("```")) {
				lines.pop();
			}
		} else {
			lines.pop();
		}
	}
	Generatedscript = lines.join("\n");
	return Generatedscript;
};

export const asyncPlayWrightPYScriptGeneration = async (
	steps: any,
	urlArray: any,
) => {
	const playwrightPY = await axios.post(
		`${serverURL}/testscript/playwrightpy`,
		{
			steps,
		},
	);
	console.log({ playwrightPY });
	let playwrightPyScript = playwrightPY.data.playwrightPy;

	let PYurlIndex = 0;
	playwrightPyScript = playwrightPyScript.replace(
		/\/\/.*url\d+/g,
		(match: any) => {
			// eslint-disable-next-line no-plusplus
			PYurlIndex++;
			return match.replace(/url\d+/, `${urlArray[PYurlIndex - 1]}`);
		},
	);
	// /\/\/.*|#.*/g
	let PYurlIndex2 = 0;
	playwrightPyScript = playwrightPyScript.replace(
		/\/\/.*|#.*url\d+/g,
		(match: any) => {
			// eslint-disable-next-line no-plusplus
			PYurlIndex2++;
			return match.replace(/url\d+/, `${urlArray[PYurlIndex2 - 1]}`);
		},
	);
	let PYurlIndex1 = 0;
	playwrightPyScript = playwrightPyScript.replace(/url\d+/g, () => {
		// eslint-disable-next-line no-plusplus
		PYurlIndex1++;
		return `${urlArray[PYurlIndex1 - 1]}`;
	});
	Generatedscript = playwrightPyScript;
	lines = Generatedscript.split("\n");
	if (Generatedscript.startsWith("```")) {
		// Remove the first line by slicing the array
		lines.shift();

		// Join the remaining lines back into a single string
		Generatedscript = lines.join("\n");
	}
	lines = Generatedscript.split("\n");
	if (
		lines[lines.length - 1].trim().startsWith("Note: ") ||
		lines[lines.length - 1].trim().startsWith("```")
	) {
		// Remove the last line
		if (lines[lines.length - 1].trim().startsWith("Note: ")) {
			lines.pop();
			if (lines[lines.length - 1].trim().startsWith("```")) {
				lines.pop();
			}
		} else {
			lines.pop();
		}
	}
	Generatedscript = lines.join("\n");
	return Generatedscript;
};

export const asyncPuppeteerScriptGeneration = async (
	steps: any,
	urlArray: any,
) => {
	const puppeteer = await axios.post(`${serverURL}/testscript/puppeteerjs`, {
		steps,
	});
	console.log({ puppeteer });
	let puppeteerScript = puppeteer.data.puppeteerJS;

	urlIndex = 0;
	puppeteerScript = puppeteerScript.replace(/\/\/.*url\d+/g, (match: any) => {
		// eslint-disable-next-line no-plusplus
		urlIndex++;
		return match.replace(/url\d+/, `${urlArray[urlIndex - 1]}`);
	});

	urlIndex1 = 0;
	puppeteerScript = puppeteerScript.replace(/url\d+/g, () => {
		// eslint-disable-next-line no-plusplus
		urlIndex1++;
		return `${urlArray[urlIndex1 - 1]}`;
	});
	return puppeteerScript;
};
