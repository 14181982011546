import { createSlice } from "@reduxjs/toolkit";

// import { useGetDarkmodeQuery } from "../services/bugDetails";

export interface IThemeState {
	darkMode: boolean;
	themeVariables: {
		[key: string]: string;
	};
}

// const { data } = useGetDarkmodeQuery({});

const initialState: IThemeState = {
	darkMode: false,
	themeVariables: {
		primaryColor: "",
	},
};
export const themeSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {
		toggleTheme: (state) => {
			// document.querySelector(".body")?.classList.toggle("dark");
			return { ...state, darkMode: !state.darkMode };
		},
		setDarkMode: (state, action) => {
			return { ...state, darkMode: action.payload };
		},
	},
});
export const { toggleTheme, setDarkMode } = themeSlice.actions;
export default themeSlice.reducer;
