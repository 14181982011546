import "./chatmessage.scss";

import CodeBlock from "./CodeBlock";

export default function ChatMessage(props: any) {
	const { chat, showSvg } = props;

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{chat.type === "code" && (
				<CodeBlock code={chat?.content} language={chat?.language} />
			)}
			{chat?.role === "assistant" && chat?.type !== "code" && (
				<div className="d-flex">
					{showSvg && (
						<svg
							width="40"
							height="40"
							viewBox="0 0 40 40"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="20" cy="20" r="20" fill="white" />
							<path
								d="M28.7651 25.7845V13.649C28.7651 13.1525 28.3621 12.75 27.8656 12.75H12.1351C11.6386 12.75 11.2361 13.1525 11.2361 13.649V25.7845C11.2361 26.281 11.6386 26.6835 12.1351 26.6835H14.1016L16.0676 31.25L18.0341 26.6835H27.8661C28.3626 26.6835 28.7651 26.281 28.7651 25.7845Z"
								stroke="url(#paint0_linear_204_463)"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M16.6291 18.8544C17.2496 18.8544 17.7526 18.3514 17.7526 17.7309C17.7526 17.1104 17.2496 16.6074 16.6291 16.6074C16.0086 16.6074 15.5056 17.1104 15.5056 17.7309C15.5056 18.3514 16.0086 18.8544 16.6291 18.8544Z"
								stroke="url(#paint1_linear_204_463)"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M23.3711 18.8544C23.9916 18.8544 24.4946 18.3514 24.4946 17.7309C24.4946 17.1104 23.9916 16.6074 23.3711 16.6074C22.7506 16.6074 22.2476 17.1104 22.2476 17.7309C22.2476 18.3514 22.7506 18.8544 23.3711 18.8544Z"
								stroke="url(#paint2_linear_204_463)"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M24.4951 21.2324C24.4951 22.1664 23.6381 23.0294 22.2476 23.4964C20.8571 23.9634 19.1436 23.9634 17.7526 23.4964C16.3616 23.0294 15.5056 22.1664 15.5056 21.2324H24.4951Z"
								stroke="url(#paint3_linear_204_463)"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_204_463"
									x1="11.2361"
									y1="22"
									x2="28.7651"
									y2="22"
									gradientUnits="userSpaceOnUse"
								>
									<stop stop-color="#D5BD6A" />
									<stop offset="1" stop-color="#6F6237" />
								</linearGradient>
								<linearGradient
									id="paint1_linear_204_463"
									x1="15.5056"
									y1="17.7309"
									x2="17.7526"
									y2="17.7309"
									gradientUnits="userSpaceOnUse"
								>
									<stop stop-color="#D5BD6A" />
									<stop offset="1" stop-color="#6F6237" />
								</linearGradient>
								<linearGradient
									id="paint2_linear_204_463"
									x1="22.2476"
									y1="17.7309"
									x2="24.4946"
									y2="17.7309"
									gradientUnits="userSpaceOnUse"
								>
									<stop stop-color="#D5BD6A" />
									<stop offset="1" stop-color="#6F6237" />
								</linearGradient>
								<linearGradient
									id="paint3_linear_204_463"
									x1="15.5056"
									y1="22.5395"
									x2="24.4951"
									y2="22.5395"
									gradientUnits="userSpaceOnUse"
								>
									<stop stop-color="#D5BD6A" />
									<stop offset="1" stop-color="#6F6237" />
								</linearGradient>
							</defs>
						</svg>
					)}
					<div
						className={chat?.role === "assistant" ? "assistant" : "user"}
						style={{ marginLeft: showSvg ? "15px" : "50px" }}
					>
						{chat?.content?.trim()}
					</div>
				</div>
			)}
			{chat.role === "user" && (
				<div className={chat?.role === "assistant" ? "assistant" : "user"}>
					{chat?.content?.trim()}
				</div>
			)}
		</>
	);
}
