import { Modal } from "react-bootstrap";

import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import "./modal.scss";

function DeleteWorkspaceModal(props: any) {
	const { show, onHide, handleDelete } = props;

	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body
				style={{
					backgroundColor: darkMode ? "#090D17" : "#FFFFFF",
					borderRadius: 18,
					border: "1px solid #356EFF",
				}}
			>
				<div>
					<div
						className="d-flex justify-content-between w-100"
						style={{ marginBottom: 33 }}
					>
						<div
							className="tb-modal-heading"
							style={{
								color: darkMode ? "white" : "black",
							}}
						>
							DELETE WORKSPACE
						</div>
						{darkMode ? (
							<svg
								onClick={onHide}
								style={{
									cursor: "pointer",
								}}
								width="28"
								height="28"
								viewBox="0 0 28 28"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="28" height="28" rx="14" fill="#356EFF" />
								<path
									d="M19.8327 8.16669L8.16602 19.8334"
									stroke="white"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.16602 8.16669L19.8327 19.8334"
									stroke="white"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						) : (
							<svg
								onClick={onHide}
								style={{
									cursor: "pointer",
								}}
								width="28"
								height="28"
								viewBox="0 0 28 28"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="0.5"
									y="0.5"
									width="27"
									height="27"
									rx="13.5"
									stroke="#356EFF"
								/>
								<path
									d="M19.8327 8.16675L8.16602 19.8334"
									stroke="#356EFF"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.16602 8.16675L19.8327 19.8334"
									stroke="#356EFF"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
					</div>
					<div className="d-flex justify-content-center">
						<div
							style={{
								color: darkMode ? "white" : "black",
							}}
						>
							Are you sure want to delete the workspace?
						</div>
					</div>
					<div
						className="d-flex justify-content-center"
						style={{ marginTop: 40 }}
					>
						<button
							onClick={handleDelete}
							className="btn btn-danger"
							type="button"
							style={{
								width: 149,
								height: 34,
								border: "none",
								color: "white",
								padding: "12px 9px 12px 9px",
								fontSize: 12,
								fontWeight: 600,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								margin: 0,
							}}
						>
							Delete
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DeleteWorkspaceModal;
