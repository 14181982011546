export default function GithubOAuth() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.0002 3.19922C8.9314 3.19922 3.2002 8.93042 3.2002 15.9992C3.2002 21.9971 7.33033 27.0157 12.8983 28.4046C12.8386 28.2318 12.8002 28.0312 12.8002 27.7827V25.595C12.2807 25.595 11.4103 25.595 11.1917 25.595C10.3159 25.595 9.53726 25.2184 9.15966 24.5187C8.74046 23.7411 8.66793 22.5518 7.629 21.8243C7.32073 21.5822 7.5554 21.3059 7.9106 21.3432C8.5666 21.5288 9.1106 21.979 9.6226 22.6467C10.1325 23.3155 10.3725 23.4669 11.325 23.4669C11.7869 23.4669 12.4781 23.4403 13.1287 23.3379C13.4786 22.4494 14.0834 21.6312 14.8226 21.2451C10.5602 20.8067 8.52606 18.6862 8.52606 15.8072C8.52606 14.5678 9.05406 13.3688 9.95113 12.3587C9.65673 11.356 9.2866 9.31122 10.0642 8.53255C11.9821 8.53255 13.1415 9.77629 13.4199 10.1123C14.3757 9.78482 15.4253 9.59922 16.5282 9.59922C17.6333 9.59922 18.6871 9.78482 19.645 10.1144C19.9202 9.78055 21.0807 8.53255 23.0029 8.53255C23.7837 9.31228 23.4093 11.3656 23.1117 12.3662C24.0034 13.3742 24.5282 14.5699 24.5282 15.8072C24.5282 18.684 22.4973 20.8035 18.2413 21.244C19.4125 21.8552 20.2669 23.5725 20.2669 24.8664V27.7827C20.2669 27.8936 20.2423 27.9736 20.2295 28.0686C25.2173 26.3203 28.8002 21.5843 28.8002 15.9992C28.8002 8.93042 23.069 3.19922 16.0002 3.19922Z"
				fill="black"
			/>
		</svg>
	);
}
