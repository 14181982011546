import React, { useState, useEffect } from "react";
import Select from "react-select";

import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
	useAddJiraMetaMutation,
	useGetJiraIssueTypeMutation,
	useGetJiraProjectsMutation,
	useGetJiraSitesMutation,
	useRemoveJiraMetaMutation,
} from "../../../shared/services/integration";
import "./selectStyle.css";
import classNames from "./styles.module.scss";

function JiraIntegration(props: any) {
	const {
		jiraIntegration,
		workspaceId,
		openModal,
		setOpenModal,
		refetchAllIntegrations,
	} = props;
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;
	const [getJiraProjects] = useGetJiraProjectsMutation();
	const [getJiraSites] = useGetJiraSitesMutation();
	const [getJiraIssueType] = useGetJiraIssueTypeMutation();
	const [selectedProject, setSelectedProject] = useState<any>(null);
	// const [selectedLabel, setSelectedLabel] = useState<any>("");
	// const [selectedStatus, setSelectedStatus] = useState<any>("");
	const [selectedIssueType, setSelectedIssueType] = useState<any>(null);
	const [projectOptions, setProjectOptions] = useState([]);
	const [issueTypeOptions, setIssueTypeOptions] = useState([]);
	const [siteOptions, setSiteOptions] = useState([]);
	const [selectedSite, setSelectedSite] = useState<any>(null);
	const [isEnabled, setIsEnabled] = useState(false);
	const [removeJiraMeta] = useRemoveJiraMetaMutation();

	const styleCustom = {
		menuList: (provided: any) => ({
			...provided,
			// padding: 0, // Remove padding to get rid of the small strip
			backgroundColor: darkMode ? "#1C2A4C" : "white", // Set the background color
		}),
		control: (provided: any, state: any) => ({
			...provided,
			color: state.isFocused ? "#356EFF" : "#356EFF",
			minHeight: "32px",
			maxHeight: "32px",
			width: "180px",
			textAlign: "left",
			backgroundColor: darkMode ? "#1C2A4C" : "white",
			border: "1px solid #356EFF",
			borderRadius: "6px",
			// marginBottom: "10px",
		}),
		// Dropdowns
		option: (provided: any, state: any) => ({
			...provided,
			// eslint-disable-next-line no-nested-ternary
			backgroundColor: state.isSelected
				? "#356EFF"
				: darkMode
					? "#1C2A4C"
					: "white",
			color: state.isSelected || darkMode ? "white" : "black",
			minHeight: "32px",
			width: "180px", // Set the width to 180px
			maxHeight: "32px",
			textAlign: "left",
			zIndex: 99999,
		}),
		// Content
		singleValue: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
			// marginBottom: "10px",
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
			// marginBottom: "10px",
		}),
	};
	const handleGetIssueTypeAndStatuses = async (
		projectId: any,
		cloudId: any,
	) => {
		const data = {
			accessToken: jiraIntegration.accessToken,
			cloudId,
			projectId,
		};
		const response: any = await getJiraIssueType(data);
		const { issueTypes } = response.data;
		const status = response.data.statuses;
		const typeArr: any = [];
		issueTypes.map((project: any) => {
			const obj = { label: project?.name, value: project.id };
			typeArr.push(obj);
			return project;
		});
		const statusArr: any = [];
		status.map((project: any) => {
			const obj = { label: project?.name, value: project.id };
			statusArr.push(obj);
			return project;
		});
		setIssueTypeOptions(typeArr);
	};

	useEffect(() => {
		// Check if jiraIntegration has data
		if (jiraIntegration) {
			if (jiraIntegration?.metadata) {
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const { selectedIssueType, selectedProject, selectedSite } =
					jiraIntegration?.metadata; // eslint-disable-line no-unsafe-optional-chaining
				// eslint-disable-next-line @typescript-eslint/no-shadow
				if (selectedProject && selectedIssueType && selectedSite) {
					setIsEnabled(true);
					handleGetIssueTypeAndStatuses(
						selectedProject.value,
						selectedSite.value,
					);
				}
				// Update state based on selectedIssueType
				setSelectedIssueType(selectedIssueType || null);

				// Update state based on selectedProject
				setSelectedProject(selectedProject || null);

				setSelectedSite(selectedSite || null);
			} else {
				setSelectedIssueType(null);
				setSelectedProject(null);
				setSelectedSite(null);
			}
		}
	}, [jiraIntegration]);
	const [addJiraMeta] = useAddJiraMetaMutation();
	async function addJiraDetails() {
		await addJiraMeta({
			id: jiraIntegration._id,
			selectedProject,
			selectedIssueType,
			selectedSite,
		});
		setOpenModal("");
		setIsEnabled(true);
	}
	async function handleRemoveJiraMeta() {
		const receivedData = await removeJiraMeta({
			id: jiraIntegration?._id,
		});
		if (receivedData) {
			setOpenModal("");
			setIsEnabled(false);
			refetchAllIntegrations();
		}
	}
	// eslint-disable-next-line react/no-unstable-nested-components
	function CustomDropdownIndicator({ selectProps }: any) {
		const handleKeyDown = (event: any) => {
			// Example: handle Enter key
			if (event.key === "Enter") {
				selectProps.onMenuOpen();
			}
		};

		return (
			<div
				className={classNames["custom-dropdown-indicator"]}
				onClick={selectProps.onMenuOpen}
				onKeyDown={handleKeyDown}
				role="button"
				tabIndex={0} // Makes the div focusable
				aria-label="Open dropdown" // Descriptive label for the button
			>
				<svg
					width="12"
					height="6"
					viewBox="0 0 12 6"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M6 6L12 0H0L6 6Z" fill="#356EFF" />
				</svg>
			</div>
		);
	}

	// const styleCustom = {
	// 	control: (provided: any) => ({
	// 		...provided,
	// 		// color: state.isFocused ? "#356EFF" : "#356EFF",
	// 		minHeight: "32px",
	// 		maxHeight: "32px",
	// 		width: "180px", // Set the width to 180px
	// 		backgroundColor: darkMode ? "1C2A4C" : "#fff",
	// 		textAlign: "left",
	// 		marginBottom: "10px",
	// 		border: "1px solid black ",
	// 	}),
	// 	option: (provided: any, state: any) => ({
	// 		...provided,
	// 		backgroundColor:
	// 			state.isFocused || state.isSelected ? "#8c95a8" : "black",
	// 		color: "white",

	// 		minHeight: "32px",
	// 		width: "180px", // Set the width to 180px
	// 		maxHeight: "32px",
	// 		fontSize: "12px",
	// 		zIndex: 99999,
	// 	}),
	// 	singleValue: (provided: any) => ({
	// 		...provided,
	// 		color: "#fff",
	// 		textAlign: "left",
	// 		fontSize: "12px",
	// 		marginBottom: "10px",
	// 	}),
	// 	placeholder: (provided: any) => ({
	// 		...provided,
	// 		color: "#8c95a8",
	// 		textAlign: "left",
	// 		fontSize: "12px",
	// 		marginBottom: "10px",
	// 	}),
	// };
	const fetchProjectsNLabels = async (cloudId: string) => {
		const data: any = await getJiraProjects({ workspaceId, cloudId });
		const labels = data?.data?.labels;
		const projects = data?.data?.projects;
		const labelArr: any = [];
		labels.map((label: any) => {
			const obj = { label, value: label };
			labelArr.push(obj);
			return label;
		});
		const projectArr: any = [];
		projects.map((project: any) => {
			const obj = { label: project?.name, value: project.id };
			projectArr.push(obj);
			return project;
		});
		setProjectOptions(projectArr);
	};
	const fetchSites = async () => {
		const data: any = await getJiraSites({ workspaceId });
		console.log({ data });
		const clouds = data?.data?.clouds;
		const labelArr: any = [];
		clouds.map((label: any) => {
			const obj = { label: label.url, value: label.id };
			labelArr.push(obj);
			return label;
		});

		setSiteOptions(labelArr);
	};
	useEffect(() => {
		if (openModal === "Jira") {
			fetchSites();
		}
	}, [openModal]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div
			className="d-flex align-items-center justify-content-between"
			style={{ position: "relative" }}
		>
			<div
				className={`d-flex gap-3 ${classNames["pmt-logo"]}`}
				style={{ marginLeft: "2rem" }}
			>
				<div>
					<p className="primary-font-color m-auto">Team Recordings</p>
				</div>
			</div>
			<div className=" d-flex gap-2" style={{ cursor: "pointer" }}>
				<div>
					{selectedSite ? (
						<p className="primary-font-color m-auto">
							Site : {selectedSite.label}{" "}
							{selectedProject && (
								<span>/ Project : {selectedProject.label}</span>
							)}
							{selectedIssueType && (
								<span>/ Issue type : {selectedIssueType.label}</span>
							)}
						</p>
					) : (
						<p className="primary-font-color m-auto">Do Not Create</p>
					)}
				</div>
				{/* eslint-disable jsx-a11y/control-has-associated-label */}
				<div
					role="button"
					tabIndex={0}
					onKeyDown={() => {
						if (openModal === "Jira") {
							setOpenModal("");
						} else {
							setOpenModal("Jira");
						}
					}}
					onClick={() => {
						if (openModal === "Jira") {
							setOpenModal("");
						} else {
							setOpenModal("Jira");
						}
					}}
				>
					<svg
						style={{
							marginBottom: "2px",
							transform: openModal === "Jira" ? "rotate(180deg)" : "none",
							transition: "transform 0.3s",
						}}
						xmlns="http://www.w3.org/2000/svg"
						height="14"
						width="14"
						viewBox="0 0 512 512"
						fill={darkMode ? "white" : "black"}
					>
						<path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
					</svg>
				</div>
			</div>
			{openModal === "Jira" && (
				<div
					className={` ${classNames["modal-settings-container"]} integration-popup-container`}
				>
					<div className={classNames["options-container"]}>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label
							className={`${classNames["btn-gray"]} primary-font-color`}
							htmlFor="projectSelect"
						>
							Sites <span className="text-danger">*</span>
						</label>
						<Select
							id="siteSelect"
							options={siteOptions}
							onChange={(val: any) => {
								setSelectedSite({
									label: val.label,
									value: val.value,
								});
								fetchProjectsNLabels(val.value);
								// (val.value);
							}}
							value={
								selectedSite
									? {
											label: selectedSite.label,
											value: selectedSite.value,
										}
									: null
							}
							styles={styleCustom}
							components={{
								DropdownIndicator: CustomDropdownIndicator,
							}}
							placeholder="Select Site"
						/>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label
							className={`${classNames["btn-gray"]} primary-font-color`}
							htmlFor="projectSelect"
						>
							Project <span className="text-danger">*</span>
						</label>
						<Select
							id="projectSelect"
							options={projectOptions}
							onChange={(val: any) => {
								setSelectedProject({
									label: val.label,
									value: val.value,
								});
								handleGetIssueTypeAndStatuses(val.value, selectedSite.value);
							}}
							value={
								selectedProject
									? {
											label: selectedProject.label,
											value: selectedProject.value,
										}
									: null
							}
							styles={styleCustom}
							components={{
								DropdownIndicator: CustomDropdownIndicator,
							}}
							placeholder="Select Project"
						/>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label
							className={`${classNames["btn-gray"]} primary-font-color`}
							htmlFor="issueTypeSelect"
						>
							Issue Type <span className="text-danger">*</span>
						</label>
						<Select
							id="issueTypeSelect"
							options={issueTypeOptions}
							onChange={(val: any) => {
								setSelectedIssueType({
									label: val.label,
									value: val.value,
								});
							}}
							value={
								selectedIssueType
									? {
											label: selectedIssueType.label,
											value: selectedIssueType.value,
										}
									: null
							}
							styles={styleCustom}
							placeholder="Select Type"
							components={{
								DropdownIndicator: CustomDropdownIndicator,
							}}
						/>
					</div>

					<div
						className={`d-flex ${isEnabled ? "justify-content-between" : "justify-content-end"} align-items-center gap-3`}
						style={{ marginTop: "5px", marginRight: "5px" }}
					>
						{isEnabled && (
							<div
								role="button"
								tabIndex={0}
								className={`${classNames["remove-btn"]}`}
								onClick={handleRemoveJiraMeta}
								onKeyDown={handleRemoveJiraMeta}
							>
								Remove
							</div>
						)}
						<div className="d-flex justify-content-end align-items-center gap-3">
							<div
								className={`${classNames["link-gray"]} primary-font-color`}
								role="button"
								tabIndex={0}
								onKeyDown={() => {
									setOpenModal("");
									setSelectedProject(null);
									setSelectedIssueType(null);
								}}
								onClick={() => {
									setOpenModal("");
									setSelectedProject(null);
									setSelectedIssueType(null);
								}}
							>
								Cancel
							</div>
							<div
								role="button"
								tabIndex={0}
								onKeyDown={addJiraDetails}
								className={`${classNames["add-btn"]} ${
									selectedProject && selectedIssueType
										? ""
										: classNames.disabled
								}`}
								onClick={addJiraDetails}
							>
								{isEnabled ? "Save" : "Add"}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default JiraIntegration;
