import { createApi } from "@reduxjs/toolkit/query/react";

// import { API_BASE_URL } from "../../config/environment";
import baseQueryWithReauth from "../api/apiSlice";

interface ICreateInvite {
	invitee: Array<Object>;
}

export const inviteApi = createApi({
	reducerPath: "invite",
	baseQuery: baseQueryWithReauth,

	endpoints: (builder) => ({
		sendInvite: builder.mutation<any, ICreateInvite>({
			query: (data: ICreateInvite) => ({
				url: "/invite",
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					authorization: "Bearer 92dd8c10-bb14-4799-b168-2b2d13b27ddd",
				},
				body: data,
			}),
		}),
		acceptInvite: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/invite/${data.id}`,
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
					authorization: "Bearer 92dd8c10-bb14-4799-b168-2b2d13b27ddd",
				},
				body: data,
			}),
		}),
	}),
});

export const { useSendInviteMutation, useAcceptInviteMutation } = inviteApi;
