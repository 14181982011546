import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

interface IWorkspaceCreate {
	name: string;
}
interface IGetIssues {
	skip?: number;
	limit?: number;
	workspaceId: string;
	sortOrder: string;
	title?: string;
	reporter?: string;
}

interface IGetIssueCount {
	workspaceId: string;
	title: string;
}
export interface IIssues {
	_id: string;
	author: {
		_id: string;
		firstName: string;
		lastName: string;
	};
	thumbnail: string;
	comments: number;
	title: string;
	createdAt: string;
	updatedAt: string;
	description: string;
	priority: string;
	screenshot: string;
	issueId: string;
	pmtIssues: {
		github: {
			posted: string;
			issueLink: string;
			errors: string;
		};
		gitlab: {
			posted: string;
			issueLink: string;
			errors: string;
		};
		azure: {
			posted: string;
			issueLink: string;
			errors: string;
		};
		slack: {
			posted: string;
			issueLink: string;
			errors: string;
		};
		jira: {
			posted: string;
			issueLink: string;
			errors: string;
		};
		clickup: {
			posted: string;
			issueLink: string;
			errors: string;
		};
	};
}

interface GetIssuesResponse {
	message: string;
	data: IIssues[];
	success: boolean;
}

export const workspaceApi = createApi({
	reducerPath: "workspaceAPI",
	baseQuery: baseQueryWithReauth,
	tagTypes: ["GET_WORKSPACE", "DELETE_ISSUE", "UPDATE_WORKSPACE"],
	endpoints: (builder) => ({
		createWorkspace: builder.mutation<any, IWorkspaceCreate>({
			query: (data: IWorkspaceCreate) => ({
				url: "/workspace/create",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
			invalidatesTags: ["GET_WORKSPACE"],
		}),
		getAllWorkspace: builder.query<any, any>({
			query: () => {
				return {
					url: "/workspace",
					method: "GET",
					headers: { "Content-Type": "application/json" },
				};
			},
			providesTags: ["UPDATE_WORKSPACE"],
		}),
		getWorkspace: builder.query<any, any>({
			query: (data?: any) => {
				return {
					url: `/workspace${data?.id ? `/${data?.id}` : ""}`,
					method: "GET",
					headers: { "Content-Type": "application/json" },
				};
			},
			providesTags: ["GET_WORKSPACE"],
		}),
		updateWorkspace: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/workspace/${data?._id}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["UPDATE_WORKSPACE", "GET_WORKSPACE"],
		}),
		purchasePlan: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/payment/${data?._id}`,
				method: "POST",
				body: data,
			}),
		}),
		deleteBug: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/issues/${data?._id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["DELETE_ISSUE"],
		}),
		getIssueCount: builder.query<any, IGetIssueCount>({
			query: (data: IGetIssueCount) => ({
				url: `/workspace/${data.workspaceId}/issues`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
				params: {
					...(data.title && { title: data.title }),
					count: true,
				},
			}),
		}),

		getIssues: builder.query<GetIssuesResponse, IGetIssues>({
			query: (data: IGetIssues) => ({
				url: `/workspace/${data.workspaceId}/issues`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
				params: {
					sortOrder: data.sortOrder,
					basicInfo: true,
					...(data.skip && { skip: data.skip }),
					...(data.limit && { limit: data.limit }),
					...(data.title && { title: data.title }),
					...(data.reporter && { reporter: data.reporter }),
				},
			}),
			providesTags: ["DELETE_ISSUE"],
		}),
	}),
});

// Reset the entire cache for the API
export const resetApiState = () => {
	workspaceApi.util.resetApiState();
};

export const {
	useCreateWorkspaceMutation,
	useGetWorkspaceQuery,
	useGetAllWorkspaceQuery,
	useUpdateWorkspaceMutation,
	usePurchasePlanMutation,
	useGetIssueCountQuery,
	useGetIssuesQuery,
	useDeleteBugMutation,
} = workspaceApi;
