import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

interface ISignupForm {
	name: string;
	email: string;
	password: string;
}

interface ILoginForm {
	email: string;
	password: string;
	rememberMe?: boolean;
}

export const logoutUser = async () => {
	return "logout";
};

export const authApi = createApi({
	reducerPath: "authAPI",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		loginUser: builder.mutation<any, ILoginForm>({
			query: (data: ILoginForm) => ({
				url: "/auth/login",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		signupUser: builder.mutation<void, ISignupForm>({
			query: (data: ISignupForm) => ({
				url: "/auth/register",
				method: "POST",
				body: data,
			}),
		}),
		refreshToken: builder.mutation<void, void>({
			query: () => ({
				url: "/auth/refresh",
				method: "POST",
				cache: "no-cache",
			}),
		}),
		self: builder.mutation<any, void>({
			query: () => ({
				url: "/uer/self",
				method: "POST",
				body: { user: "jo" },
			}),
		}),
		logout: builder.mutation<void, void>({
			query: () => ({
				url: "/auth/refresh",
				body: { intent: "logout" },
				method: "POST",
				cache: "no-cache",
			}),
		}),
		getVerificationCode: builder.query<any, any>({
			query: () => ({
				url: "/user/resend-otp",
			}),
		}),
		verifyEmail: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/user/auth/verify",
				method: "POST",
				body: data,
			}),
		}),
	}),
});

export const {
	useLoginUserMutation,
	useSignupUserMutation,
	useGetVerificationCodeQuery,
	useRefreshTokenMutation,
	useSelfMutation,
	useVerifyEmailMutation,
	useLogoutMutation,
} = authApi;
