import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
	useAddGitlabMetaMutation,
	useGetGitlabReposQuery,
	useRemoveGitlabMetaMutation,
} from "../../../shared/services/integration";
import "./selectStyle.css";
import classNames from "./styles.module.scss";

function GitlabIntegration(props: any) {
	const { gitlabIntegration, openModal, setOpenModal, refetchAllIntegrations } =
		props;
	const [isEnabled, setIsEnabled] = useState(false);
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;
	const [selectedProject, setSelectedProject] = useState<any>(null);
	const [projectOptions, setProjectOptions] = useState([]);
	const [removeGitlabMeta] = useRemoveGitlabMetaMutation();
	useEffect(() => {
		// Check if githubIntegration has data

		if (gitlabIntegration && gitlabIntegration.metadata) {
			const { selectedRepo } = gitlabIntegration.metadata; // eslint-disable-line @typescript-eslint/no-shadow
			if (selectedRepo) {
				setIsEnabled(true);
			}
			// Update state based on selectedProject
			setSelectedProject(selectedRepo || null);
		}
	}, [gitlabIntegration]);

	const { data: GitlabRepos, refetch } = useGetGitlabReposQuery(
		{
			id: gitlabIntegration._id,
		},
		{
			skip: openModal !== "Gitlab",
		},
	);
	useEffect(() => {
		if (GitlabRepos) {
			const statusArr: any = [];
			GitlabRepos.map((project: any) => {
				const obj = { label: project?.name, value: project.id };
				statusArr.push(obj);
				return project;
			});
			setProjectOptions(statusArr);
		}
	}, [GitlabRepos]);
	const [addGitlabMeta] = useAddGitlabMetaMutation();

	async function saveGithubMeta() {
		const newData = await addGitlabMeta({
			id: gitlabIntegration?._id,
			selectedRepo: selectedProject,
		});
		if (newData) {
			setOpenModal("");
			refetch();
			toast.success("Gitlab Details Saved Successfully!");
			setIsEnabled(true);
		}
	}

	async function handleRemoveGitLabMeta() {
		const receivedData = await removeGitlabMeta({
			id: gitlabIntegration?._id,
		});
		if (receivedData) {
			setOpenModal("");
			setIsEnabled(false);
			refetchAllIntegrations();
		}
	}

	// eslint-disable-next-line react/no-unstable-nested-components
	function CustomDropdownIndicator({ selectProps }: any) {
		const handleKeyDown = (event: any) => {
			// Example: handle Enter key
			if (event.key === "Enter") {
				selectProps.onMenuOpen();
			}
		};

		return (
			<div
				className={classNames["custom-dropdown-indicator"]}
				onClick={selectProps.onMenuOpen}
				onKeyDown={handleKeyDown}
				role="button"
				tabIndex={0} // Makes the div focusable
				aria-label="Open dropdown" // Descriptive label for the button
			>
				<svg
					width="12"
					height="6"
					viewBox="0 0 12 6"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M6 6L12 0H0L6 6Z" fill="#8c95a8" />
				</svg>
			</div>
		);
	}

	const styleCustom = {
		menuList: (provided: any) => ({
			...provided,
			// padding: 0, // Remove padding to get rid of the small strip
			backgroundColor: darkMode ? "#1C2A4C" : "white", // Set the background color
		}),
		control: (provided: any, state: any) => ({
			...provided,
			color: state.isFocused ? "#356EFF" : "#356EFF",
			minHeight: "32px",
			maxHeight: "32px",
			width: "180px",
			textAlign: "left",
			backgroundColor: darkMode ? "#1C2A4C" : "white",
			border: "1px solid #356EFF",
			borderRadius: "6px",
			// marginBottom: "10px",
		}),
		// Dropdowns
		option: (provided: any, state: any) => ({
			...provided,
			// eslint-disable-next-line no-nested-ternary
			backgroundColor: state.isSelected
				? "#356EFF"
				: darkMode
					? "#1C2A4C"
					: "white",
			color: state.isSelected || darkMode ? "white" : "black",
			minHeight: "32px",
			width: "180px", // Set the width to 180px
			maxHeight: "32px",
			textAlign: "left",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
			zIndex: 99999,
		}),
		// Content
		singleValue: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
			// marginBottom: "10px",
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
			// marginBottom: "10px",
		}),
	};

	return (
		<div
			className="d-flex align-items-center justify-content-between"
			style={{ position: "relative" }}
		>
			<div
				className={`d-flex gap-3 ${classNames["pmt-logo"]}`}
				style={{ marginLeft: "2rem" }}
			>
				<div>
					<p className="primary-font-color m-auto">Team Recordings</p>
				</div>
			</div>
			<div className=" d-flex gap-2" style={{ cursor: "pointer" }}>
				<div>
					{selectedProject ? (
						<p className="primary-font-color m-auto">
							Repository : {selectedProject.label}{" "}
						</p>
					) : (
						<p className="primary-font-color m-auto">Do Not Create</p>
					)}
				</div>
				{/* eslint-disable jsx-a11y/control-has-associated-label */}
				<div
					role="button"
					tabIndex={0}
					onKeyDown={() => {
						if (openModal === "Gitlab") {
							setOpenModal("");
						} else {
							setOpenModal("Gitlab");
						}
					}}
					onClick={() => {
						if (openModal === "Gitlab") {
							setOpenModal("");
						} else {
							setOpenModal("Gitlab");
						}
					}}
				>
					<svg
						style={{
							marginBottom: "2px",
							transform: openModal === "Gitlab" ? "rotate(180deg)" : "none",
							transition: "transform 0.3s",
						}}
						fill={darkMode ? "white" : "black"}
						xmlns="http://www.w3.org/2000/svg"
						height="14"
						width="14"
						viewBox="0 0 512 512"
					>
						<path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
					</svg>
				</div>
			</div>
			{openModal === "Gitlab" && (
				<div
					className={` ${classNames["modal-settings-container"]} integration-popup-container`}
				>
					<div className={classNames["options-container"]}>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label
							className={`${classNames["btn-gray"]} primary-font-color`}
							htmlFor="projectSelect"
						>
							Repositories <span className="text-danger">*</span>
						</label>
						<Select
							id="projectSelect"
							options={projectOptions}
							onChange={(val: any) => {
								setSelectedProject({
									label: val.label,
									value: val.value,
								});
							}}
							styles={styleCustom}
							components={{
								DropdownIndicator: CustomDropdownIndicator,
							}}
							value={
								selectedProject
									? {
											label: selectedProject.label,
											value: selectedProject.value,
										}
									: null
							}
							placeholder="Select a Repository"
						/>
					</div>

					<div
						className={`d-flex ${isEnabled ? "justify-content-between" : "justify-content-end"} align-items-center gap-3`}
						style={{ marginTop: "5px", marginRight: "5px" }}
					>
						{isEnabled && (
							<div
								role="button"
								tabIndex={0}
								className={`${classNames["remove-btn"]}`}
								onClick={handleRemoveGitLabMeta}
								onKeyDown={handleRemoveGitLabMeta}
							>
								Remove
							</div>
						)}
						<div className="d-flex justify-content-end align-items-center gap-3">
							<div
								className={`${classNames["link-gray"]} primary-font-color`}
								role="button"
								tabIndex={0}
								onKeyDown={() => {
									setOpenModal("");
									setSelectedProject(null);
								}}
								onClick={() => {
									setOpenModal("");
									setSelectedProject(null);
								}}
							>
								Cancel
							</div>
							<div
								role="button"
								tabIndex={0}
								className={`${classNames["add-btn"]} ${
									selectedProject ? "" : classNames.disabled
								}`}
								onClick={saveGithubMeta}
								onKeyDown={saveGithubMeta}
							>
								{isEnabled ? "Save" : "Add"}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default GitlabIntegration;
