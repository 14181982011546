import PublicTopNav from "../../components/TopNav/PublicTopNav";

function PageNotFound() {
	return (
		<div>
			<PublicTopNav />
			<div
				style={{
					height: "100vh",
					width: "100vw",
					paddingTop: 80,
				}}
				className="d-flex justify-content-center align-items-center"
			>
				<h1>404! PAGE NOT FOUND</h1>
			</div>
		</div>
	);
}

export default PageNotFound;
