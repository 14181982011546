import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

export const usersApi = createApi({
	reducerPath: "workspaceAPI",
	baseQuery: baseQueryWithReauth,
	tagTypes: ["GET_WORKSPACE"],
	endpoints: (builder) => ({
		forgotPassword: builder.mutation<any, any>({
			query: (data: any) => ({
				method: "PUT",
				url: "/user/forgot-password",
				body: {
					email: data.email,
					...(data.extensionId && { extensionId: data.extensionId }),
				},
			}),
		}),
		resetPassword: builder.mutation<any, any>({
			query: (data: any) => ({
				method: "PUT",
				url: "/user/reset-password",
				body: {
					password: data.password,
					token: data.token,
				},
			}),
		}),
	}),
});

export const { useForgotPasswordMutation, useResetPasswordMutation } = usersApi;
