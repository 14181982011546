import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

interface IJira {
	name: string;
	code: string;
	workspaceId: string;
}

export const integrationApi = createApi({
	reducerPath: "integration",
	baseQuery: baseQueryWithReauth,

	endpoints: (builder) => ({
		jiraIntegration: builder.mutation<any, IJira>({
			query: (data: IJira) => ({
				url: "/integration/jira",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		sendJiraAccessToken: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/integration/jira/accesstoken",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		youtrackIntegration: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/integration/youtrack",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		getYoutrackProjects: builder.query<any, any>({
			query: (data: any) => ({
				url: `/integration/youtrack/${data.id}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),

		slackIntegration: builder.mutation<any, IJira>({
			query: (data: IJira) => ({
				url: "/integration/slack",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),

		azureIntegration: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/integration/azuredevops",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		getAzureAccounts: builder.query<any, any>({
			query: (data: any) => ({
				url: `/integration/azuredevops/accounts/${data.id}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),
		getAzureProjects: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/azuredevops/projects/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		getAzureWorkItemTypes: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/azuredevops/workitemtypes/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),

		clickupIntegration: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/integration/clickup",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		getClickupWorkspaces: builder.query<any, any>({
			query: (data: any) => ({
				url: `/integration/clickup/workspaces/${data.id}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),
		getClickupSpaces: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/clickup/spaces/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		getClickupLists: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/clickup/lists/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		sendClickupAccessToken: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/integration/clickup/send/accesstoken",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		githubIntegration: builder.mutation<any, IJira>({
			query: (data: IJira) => ({
				url: "/integration/github",
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: data,
			}),
		}),
		getGithubRepos: builder.query<any, any>({
			query: (data: any) => ({
				url: `/integration/github/repos/${data.id}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),
		gitlabIntegration: builder.mutation<any, IJira>({
			query: (data: IJira) => ({
				url: "/integration/gitlab",
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: data,
			}),
		}),
		getGitlabRepos: builder.query<any, any>({
			query: (data: any) => ({
				url: `/integration/gitlab/repos/${data.id}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),
		getAllIntegration: builder.query<any, any>({
			query: (data: any) => ({
				url: `/integration/${data.id}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),
		deleteIntegration: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/${data.id}`,
				method: "DELETE",
				headers: { "Content-Type": "application/json" },
			}),
		}),
		getJiraProjects: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/jira/data/${data.workspaceId}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		getJiraSites: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/jira/sites/${data.workspaceId}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		getJiraIssueType: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/integration/jira/issuetype",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		getSlackChannels: builder.query<any, any>({
			query: (data: any) => ({
				url: `/integration/slack/${data.workspaceId}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),
		addJiraMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/jira/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		addYoutrackMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/youtrack/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		addSlackMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/slack/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		addClickupMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/clickup/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		addGithubMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/github/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		removeGithubMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/github/${data.id}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		removeJiraMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/jira/${data.id}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		removeSlackMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/slack/${data.id}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		removeGitlabMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/gitlab/${data.id}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		removeClickupMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/clickup/${data.id}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		removeYoutrackMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/youtrack/${data.id}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		removeAzureMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/azuredevops/${data.id}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),

		addGitlabMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/gitlab/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
		addAzureMeta: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/integration/azuredevops/${data.id}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
		}),
	}),
});

export const {
	useJiraIntegrationMutation,
	useAzureIntegrationMutation,
	useClickupIntegrationMutation,
	useSlackIntegrationMutation,
	useGetAllIntegrationQuery,
	useDeleteIntegrationMutation,
	useGetJiraProjectsMutation,
	useGetJiraIssueTypeMutation,
	useGetSlackChannelsQuery,
	useAddJiraMetaMutation,
	useGithubIntegrationMutation,
	useGitlabIntegrationMutation,
	useGetGithubReposQuery,
	useGetGitlabReposQuery,
	useGetClickupWorkspacesQuery,
	useGetClickupListsMutation,
	useGetClickupSpacesMutation,
	useGetAzureAccountsQuery,
	useGetAzureProjectsMutation,
	useGetAzureWorkItemTypesMutation,
	useAddAzureMetaMutation,
	useAddClickupMetaMutation,
	useAddGithubMetaMutation,
	useAddGitlabMetaMutation,
	useAddSlackMetaMutation,
	useRemoveGithubMetaMutation,
	useRemoveAzureMetaMutation,
	useRemoveClickupMetaMutation,
	useRemoveGitlabMetaMutation,
	useRemoveJiraMetaMutation,
	useRemoveSlackMetaMutation,
	useSendJiraAccessTokenMutation,
	useSendClickupAccessTokenMutation,
	useYoutrackIntegrationMutation,
	useGetYoutrackProjectsQuery,
	useAddYoutrackMetaMutation,
	useRemoveYoutrackMetaMutation,
	useGetJiraSitesMutation,
} = integrationApi;
