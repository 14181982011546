export default function Google() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M29.0738 13.39H27.9998V13.3346H15.9998V18.668H23.5352C22.4358 21.7726 19.4818 24.0013 15.9998 24.0013C11.5818 24.0013 7.99984 20.4193 7.99984 16.0013C7.99984 11.5833 11.5818 8.0013 15.9998 8.0013C18.0392 8.0013 19.8945 8.77063 21.3072 10.0273L25.0785 6.25597C22.6972 4.03664 19.5118 2.66797 15.9998 2.66797C8.6365 2.66797 2.6665 8.63797 2.6665 16.0013C2.6665 23.3646 8.6365 29.3346 15.9998 29.3346C23.3632 29.3346 29.3332 23.3646 29.3332 16.0013C29.3332 15.1073 29.2412 14.2346 29.0738 13.39Z"
				fill="#FFC107"
			/>
			<path
				d="M4.2041 9.7953L8.58477 13.008C9.7701 10.0733 12.6408 8.0013 16.0001 8.0013C18.0394 8.0013 19.8948 8.77064 21.3074 10.0273L25.0788 6.25597C22.6974 4.03664 19.5121 2.66797 16.0001 2.66797C10.8788 2.66797 6.43743 5.5593 4.2041 9.7953Z"
				fill="#FF3D00"
			/>
			<path
				d="M15.9999 29.3338C19.4439 29.3338 22.5732 28.0158 24.9392 25.8725L20.8125 22.3805C19.4739 23.3945 17.8099 24.0005 15.9999 24.0005C12.5319 24.0005 9.58722 21.7891 8.47788 18.7031L4.12988 22.0531C6.33655 26.3711 10.8179 29.3338 15.9999 29.3338Z"
				fill="#4CAF50"
			/>
			<path
				d="M29.074 13.3874H28V13.332H16V18.6654H23.5353C23.0073 20.1567 22.048 21.4427 20.8107 22.3794L20.8127 22.378L24.9393 25.87C24.6473 26.1354 29.3333 22.6654 29.3333 15.9987C29.3333 15.1047 29.2413 14.232 29.074 13.3874Z"
				fill="#1976D2"
			/>
		</svg>
	);
}
