/* eslint-disable no-console */
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import classNames from "./app.module.scss";
import AllRoutes from "./routes/routes";
import { useRefreshTokenMutation } from "./shared/services/auth";
import useEffectOnce from "./hooks/useEffectOnce";
import { setCredentials, setUser } from "./shared/features/auth";
import "./theme.scss";
import { RootState } from "./redux/store";
import { IThemeState, setDarkMode } from "./shared/features/themeSlice";
import Loader from "./components/Loader";

function App() {
	const { darkMode } = useSelector<RootState>(
		(state) => state.theme,
	) as IThemeState;

	// console.log = () => {};
	// console.error = () => {};
	// console.warn = () => {};
	// console.info = () => {};

	const [generateToken, { isLoading }] = useRefreshTokenMutation();
	const dispatch = useDispatch();

	useEffectOnce(() => {
		generateToken()
			.unwrap()
			.then((refreshResult: any) => {
				dispatch(setCredentials({ token: refreshResult.data.accessToken }));
				dispatch(
					setUser({
						firstName: refreshResult.data.firstName,
						lastName: refreshResult.data.lastName,
					}),
				);
				dispatch(setDarkMode(refreshResult?.data?.darkmode ?? false));
			})
			.catch((error) => {
				// eslint-disable-next-line
				console.error("this is the error ", error);
			});
	});

	return !isLoading ? (
		<div className={` ${classNames.App} theme-${darkMode ? "dark" : "light"}`}>
			<ToastContainer />
			<AllRoutes />
		</div>
	) : (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{ height: "100vh" }}
		>
			<Loader theme="primary" />
		</div>
	);
}

export default App;
