export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const getColors = (darkMode: boolean) => {
	if (darkMode) {
		return {
			primaryColor: "white",
			navbarColor: "#07163c",
		};
	}
	return {
		primaryColor: "#07163c",
		navbarColor: "#1c2a4c",
	};
};

export const consoleTypes = {
	LOG: "log",
	WARN: "warn",
	ERROR: "error",
	VERBOSE: "verbose",
	EXCEPTION: "exception",
	DEBUG: "debug",
	INFO: "info",
	ALL: "all",
};
