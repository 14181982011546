import React from "react";

import classNames from "./styles.module.scss";

interface IProps {
	name: string;
	selected: boolean;
}
function WorkspacePill(props: IProps) {
	const { name, selected } = props;
	return (
		<div className="d-flex align-items-center flex-row w-100">
			<div className={`workspace-logo ${classNames["my-workspace-logo"]}`}>
				{name.charAt(0).toUpperCase()}
			</div>
			<h1
				className={`text-uppercase d-block text-left mb-0 ${
					classNames["private-workspace-h1"]
				} ${selected ? classNames.highlight : ""}`}
				style={{
					color: selected ? "#356EFF" : "white",
				}}
			>
				{name}
			</h1>
		</div>
	);
}

export default WorkspacePill;
