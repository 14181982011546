import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import Carousel from "../../components/Carousel/index";
import { useSignupUserMutation } from "../../shared/services/auth";
import { IAuthState } from "../../shared/features/auth";
import { RootState } from "../../redux/store";
import useEffectOnce from "../../hooks/useEffectOnce";
import classNames from "./styles.module.scss";
import Google from "../../assets/icon/google";
import Microsoft from "../../assets/icon/microsoft";
import Github from "../../assets/icon/githubOAuth";
import { getGoogleOAuthURL } from "../../utils/helpers";
import { useLoginWithMicrosoftMutation } from "../../shared/services/oauth";
import {
	REACT_APP_GITHUB_SSO_CLIENT_ID,
	REACT_APP_GITHUB_SSO_REDIRECT_URL,
	// REACT_APP_MSAL_APPID,
	// REACT_APP_URL,
} from "../../config/environment";
import PublicTopNav from "../../components/TopNav/PublicTopNav";
import IError from "../../utils/types";

function Login() {
	const [signupUser, { isLoading }] = useSignupUserMutation();
	const [loginWithMicrosoft] = useLoginWithMicrosoftMutation();
	const authState = useSelector<RootState>((state) => state.auth) as IAuthState;
	const navigate = useNavigate();
	const { state } = useLocation();
	const params = new URLSearchParams(useLocation().search);
	const extensionId = params.get("extensionId");

	const [viewPassword, setViewPassword] = useState<boolean>(false);

	useEffectOnce(() => {
		document.title = "TestBuddy | Sign Up";
		if (authState.token) {
			navigate(state?.from || "/workspace");
		}
	}, [authState.token, navigate, state?.from]);

	const onSubmitHandler = async (data: {
		email: string;
		name: string;
		password: string;
	}) => {
		try {
			const response: any = await signupUser(data);
			const { error } = response;

			if (error) {
				toast.error(error.data.message);
			}

			if (!error) {
				toast.success(
					"Signed Up successfully! Please verify the email using verification link sent on given email.",
				);
				if (extensionId) {
					navigate(`/login?extensionId=${extensionId}`);
				} else {
					navigate("/login");
				}
			}
		} catch (err) {
			toast.error("Something went wrong");
		}
	};
	const {
		register,
		handleSubmit,
		formState: { errors },
	}: any = useForm();

	const handleGoogleLogin = (e: any) => {
		e.preventDefault();
		window.open(getGoogleOAuthURL(extensionId), "_blank");
	};

	const handleGithubLogin = (e: any) => {
		e.preventDefault();
		window.open(
			`https://github.com/login/oauth/authorize?client_id=${REACT_APP_GITHUB_SSO_CLIENT_ID}&redirect_uri=${REACT_APP_GITHUB_SSO_REDIRECT_URL}${extensionId ? `&state=${extensionId}` : ""}`,
			"_blank",
		);
	};

	const msalConfig: any = {
		scopes: ["user.read"],
	};

	const handleMicrosoftLogin = async (e: any) => {
		e.preventDefault();

		// await publicClientApplication.handleRedirectPromise();
		const publicClientApplication: any = await new PublicClientApplication({
			auth: {
				clientId:
					process.env.REACT_APP_MSAL_APPID ||
					"a099b791-8e7c-4173-b556-31f9c76a6879",
				// authority:
				// 	"https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a",
				redirectUri:
					process.env.REACT_APP_MSAL_REDIRECT_URL ||
					"https://app-dev.testbuddy.io/azure", // Adjust as needed
			},
		});
		await publicClientApplication.initialize();
		await publicClientApplication.handleRedirectPromise();

		const popup: any = await publicClientApplication.loginPopup({
			scopes: msalConfig.scopes,
			prompt: "select_account",
		});

		try {
			const firstName = popup.account.name.split(" ")?.[0];
			const lastName = popup.account.name.split(" ")?.[1];
			const email = popup.account.username;

			const data = {
				firstName,
				lastName,
				email,
				rememberMe: false,
				accessToken: popup.accessToken,
				...(extensionId && { state: extensionId }),
			};
			await loginWithMicrosoft(data)
				.then(async () => {
					setTimeout(() => {
						navigate("/workspace");
					}, 1000);
				})
				.catch((err: IError) => {
					toast.error(err?.data.message || "Something went wrong");
				});
		} catch (err) {
			toast.error("Something went wrong");
		}
	};

	const carouselRef: any = useRef(null);
	const signupRef: any = useRef(null);

	useEffect(() => {
		if (signupRef && carouselRef) {
			// Check if loginRef.current is truthy before accessing its properties
			const signInElement: any = signupRef.current;
			if (!signInElement) return;

			const divTop = signInElement.getBoundingClientRect().top;

			// Check if carouselRef.current is truthy before updating its style
			const carouselElement = carouselRef.current;
			if (carouselElement) {
				carouselElement.style.paddingTop = `${divTop - 60}px`;
			}
		}
	}, [carouselRef, signupRef]);

	const handleShowPassword = () => {
		setViewPassword(!viewPassword);
	};

	return (
		<>
			<PublicTopNav isBugDetailsPage={false} />

			<div
				style={{ minHeight: "100vh", height: "100%" }}
				className={`overflow-x-hidden w-100 d-flex flex-row ${classNames.container}`}
			>
				{" "}
				<div
					ref={carouselRef}
					className="w-50 d-flex bg-primary justify-content-center"
				>
					<Carousel />
				</div>
				<div
					className={`w-50 h-100 bg-white d-flex flex-column align-items-center justify-content-center ${classNames["signup-parent"]}`}
				>
					<div
						className={`${classNames.loginContent} d-flex flex-column align-items-start
				`}
					>
						<form
							className={`${classNames.loginForm} d-flex flex-column align-items-start w-100`}
							onSubmit={handleSubmit(onSubmitHandler)}
							action="submit"
						>
							<h1
								id="signup"
								ref={signupRef}
								className={classNames.loginHeading}
							>
								Signup
							</h1>

							<label htmlFor="name" style={{ marginBottom: 16 }}>
								Your Name
								<input
									{...register("name", {
										required: "Name is required.",
									})}
									id="name"
									// style={{ backgroundColor: "red" }}
									className={`w-100 ${classNames.input}`}
									type="text"
								/>
								{errors?.name && (
									<span className="text-danger">{errors?.name?.message}</span>
								)}
							</label>

							<label htmlFor="email" style={{ marginBottom: 16 }}>
								Email
								<input
									{...register("email", {
										required: "Email is required.",
										pattern: {
											value:
												/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: "Please enter a valid email",
										},
									})}
									style={{
										border: "1px solid #d1d5e1 !important",
									}}
									className={`w-100 bg-white ${classNames.input}`}
									type="text"
								/>
								{errors?.email && (
									<span className="text-danger">{errors?.email?.message}</span>
								)}
							</label>

							<label htmlFor="password" style={{ marginBottom: 16 }}>
								Password
								<div
									className={`${classNames["inner-addon-rp"]} ${classNames["right-addon-rp"]}`}
									style={{
										width: "100%",
									}}
								>
									<input
										autoComplete="new-password"
										{...register("password", {
											required: "Password is required.",
											pattern: {
												value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
												message:
													"Password must be minimum 8 characters, and have at least 1 uppercase letter, 1 lowercase letter and 1 number",
											},
										})}
										className={`w-100 ${classNames.input}`}
										type={viewPassword ? "text" : "password"}
									/>
									{errors?.password && (
										<span className="text-danger">
											{errors?.password?.message}
										</span>
									)}
									<i
										className={`${classNames.icon} bi ${
											viewPassword ? "bi-eye-slash" : "bi-eye"
										} cursor-pointer`}
										style={{
											top: "24%",
											pointerEvents: "all",
											right: 16,
										}}
										aria-label="show pass"
										onClick={handleShowPassword}
										onKeyDown={handleShowPassword}
										role="button"
										tabIndex={0}
										id="togglePassword"
									/>
								</div>
							</label>

							<button
								className={`w-100 text-uppercase ${classNames.loginButton}`}
								disabled={isLoading}
								type="submit"
								aria-label="Signup"
							>
								Signup
							</button>
						</form>
						<div
							className={`d-flex align-items-center ${classNames.signupLink}`}
						>
							<h2
								style={{
									marginRight: 5,
								}}
							>
								Already have an account?
							</h2>
							<a
								style={{
									fontSize: 16,
								}}
								href="/login"
							>
								Login here
							</a>
						</div>
						<div className={`d-flex align-items-center ${classNames.or}`}>
							<hr />
							<span>OR</span> <hr />
						</div>
						<div
							className={`d-flex flex-column w-100 ${classNames.oauthButtons}`}
						>
							<button
								aria-label="Continue with Google"
								type="button"
								className={`${classNames.loginButton} bg-white text-dark ${classNames.oauthButton}`}
								onClick={handleGoogleLogin}
							>
								<Google />
								<p className="d-inline"> Continue with Google </p>
							</button>
							<button
								type="button"
								aria-label="Continue with Github"
								className={`${classNames.loginButton} bg-white text-dark ${classNames.oauthButton}`}
								onClick={handleGithubLogin}
							>
								<Github />
								<p className="d-inline">Continue with Github</p>
							</button>
							<button
								type="button"
								aria-label="Continue with Microsoft"
								className={`${classNames.loginButton} bg-white text-dark ${classNames.oauthButton}`}
								onClick={handleMicrosoftLogin}
							>
								<Microsoft />
								<p className="d-inline">Continue with Microsoft</p>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
