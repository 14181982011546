import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
import "../rrweb.css";

console.log = function () {};
console.error = function () {};
console.warn = function () {};

function RecordReplay(props: any) {
	const { data, totalHeight } = props;
	const rrElement = useRef();
	const [RRarrayBuffer, setRRarrayBuffer] = useState<any>([]);
	const [shown, setShown] = useState(false);
	async function extractArrayBufferFromS3URL(s3URL: any) {
		const response = await axios.get(s3URL);
		if (response.status === 200) {
			const jsonData = response.data;
			console.log({ jsonData });
			setRRarrayBuffer(jsonData);
			return jsonData;
		}
		throw new Error("Failed to download JSON from S3 URL.");
	}
	useEffect(() => {
		const totalWidth = window.innerWidth * 0.75;
		if (rrElement?.current && RRarrayBuffer.length > 0 && !shown) {
			const events: any = RRarrayBuffer;
			// eslint-disable-next-line new-cap, @typescript-eslint/no-unused-vars
			const player = new rrwebPlayer({
				target: rrElement?.current,
				props: {
					events: JSON.parse(JSON.stringify(events)),
					showController: true,
					autoPlay: false,
					width: totalWidth,
					height: totalHeight - 80,
				},
			});
			setShown(true);
		}
	}, [RRarrayBuffer]);
	useEffect(() => {
		const s3URL = data;
		extractArrayBufferFromS3URL(s3URL);
	}, [data]);

	// useEffect(() => {
	// 	if (rrElement?.current && data) {
	// 		const events = data;
	// 		if (!alreadyAddedBuffer && events && events?.length > 0) {
	// 			setAlreadyAddedBuffer(true);
	// 			// eslint-disable-next-line no-new, new-cap
	// 			new rrwebPlayer({
	// 				target: rrElement?.current,
	// 				props: {
	// 					events,
	// 					showController: true,
	// 					autoPlay: false,
	// 					width: 673,
	// 					height: 330,
	// 				},
	// 			});
	// 		}
	// 	}
	// }, [data, rrElement]);

	return (
		<div
			ref={rrElement as any}
			id="tb-rrweb"
			className={`rounded ${RRarrayBuffer?.length > 0 ? "" : "skeleton"}`}
			style={{
				width: "100%",
				height: RRarrayBuffer?.length > 0 ? "" : "40vh",
				border: "0.5px solid #796c3c",
			}}
		/>
	);
}

export default RecordReplay;
