import { useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import classNames from "./notification.module.scss";
import {
	useGetNotificationPreferenceQuery,
	useUpdateNotificationPreferenceMutation,
} from "../../../shared/services/notificationPreference";

interface NotificationOption {
	name: string;
	label: string;
}

function Notifications(props: any) {
	const { workspaceId } = props;
	const { data, isLoading } = useGetNotificationPreferenceQuery(workspaceId);
	const [updateNotificationPreference] =
		useUpdateNotificationPreferenceMutation();
	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			notifications: {
				workspaceActions: { email: true, inApp: false },
				taggedInComments: { email: false, inApp: false },
				bugStatusChanged: { email: false, inApp: false },
				workspaceMemberUpdate: { email: false, inApp: false },
				newIssueCreated: { email: false, inApp: false },
				subscriptionRenewal: { email: false, inApp: false },
			},
		},
	});

	useEffect(() => {
		const defaultValues = {
			notifications: {
				workspaceActions: { email: false, app: false },
				taggedInComments: { email: false, app: false },
				bugStatusChanged: { email: false, inApp: false },
				workspaceMemberUpdate: { email: false, inApp: false },
				newIssueCreated: { email: false, inApp: false },
				subscriptionRenewal: { email: false, inApp: false },
			},
		};
		if (data && !isLoading) {
			// Create a new object for the updated notification values
			const updatedNotifications: any = {};

			// Update only the relevant fields in notifications
			Object.keys(defaultValues.notifications).forEach((key) => {
				if (data[key]) {
					updatedNotifications[key] = data[key];
				}
			});

			// Reset form values with the updated notifications
			reset({ notifications: updatedNotifications });
		}
	}, [data, isLoading, reset]);

	const formRef = useRef<HTMLFormElement>(null);

	const onSubmit = async (datas: any) => {
		const updatedData = {
			...datas,
			workspaceId,
		};
		const response = await updateNotificationPreference(updatedData);
		if (response) {
			toast.success("Notification Preference Updated Successfully!");
		}
		// ... handle form submission
	};

	const handleSaveClick = () => {
		handleSubmit(onSubmit)();
	};
	const notificationOptions: NotificationOption[] = [
		{
			name: "workspaceActions",
			label: "Any Action Performed in this Workspace",
		},
		{
			name: "taggedInComments",
			label: "Someone tag you in comment and description",
		},
		{ name: "bugStatusChanged", label: "Change in the Bug status" },
		{ name: "workspaceMemberUpdate", label: "When anyone joins the workspace" },
		{
			name: "newIssueCreated",
			label: "When someone added a new bug to this workspace",
		},
		{
			name: "subscriptionRenewal",
			label: "Subscription renewal and expiry related notifications",
		},
	];

	return (
		<div>
			<div
				className={`${classNames["notification-heading"]} primary-font-color`}
			>
				Notifications
			</div>
			<div
				className={`${classNames["notification-preference-container"]} table-background`}
			>
				<div className={`row ${classNames["notification-preference-header"]}`}>
					<div
						className={`col-5 text-start ${classNames["notification-preference-thead"]}`}
					>
						Notification
					</div>
					<div
						className={`col-2  ${classNames["notification-preference-thead"]}`}
					>
						Email
					</div>
					<div
						className={`col-2 ${classNames["notification-preference-thead"]}`}
					>
						In App
					</div>
				</div>
				<form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
					{notificationOptions.map((option: any) => {
						return (
							<div
								className={`row ${classNames["notification-preference-tbody"]} table-border`}
								key={option.name}
							>
								<div
									className={`col-5 text-start ${classNames["notification-preference-name"]} text-color`}
								>
									{option.label}
								</div>
								<div className="col-2 ">
									<div className="w-100 d-flex justify-content-center">
										<Controller
											name={`notifications.${option.name}.email` as any}
											control={control}
											render={({ field }) => (
												<div
													className={`d-flex justify-content-center align-items-center  ${
														field.value
															? "border-checked"
															: "checkbox-border input-color-settings"
													}`}
													style={{
														border: "1px solid #7ea2fe",
														height: 22,
														width: "fit-content",
														padding: 3,
														borderRadius: 6,
													}}
												>
													<input
														type="checkbox"
														className={`form-check-input 
														${field.value ? "" : " input-color-settings"}

														`}
														style={{
															marginTop: 0,
															border: "none",
															outline: "none",
															cursor: "pointer",
														}}
														{...field} // This will include onChange, onBlur, name, and ref
														checked={field.value} // Ensure the checkbox is checked based on the field's value
													/>
												</div>
											)}
										/>
									</div>
								</div>
								<div className="col-2 ">
									<div className="w-100 d-flex justify-content-center">
										<Controller
											name={`notifications.${option.name}.app` as any}
											control={control}
											render={({ field }) => (
												<div
													className={`d-flex justify-content-center align-items-center  ${
														field.value
															? "border-checked"
															: "checkbox-border input-color-settings"
													}`}
													style={{
														border: "1px solid #7ea2fe",
														height: 22,
														width: "fit-content",
														padding: 3,
														borderRadius: 6,
													}}
												>
													<input
														type="checkbox"
														className={`form-check-input 
														${field.value ? "" : " input-color-settings"}

														`}
														style={{
															marginTop: 0,
															border: "none",
															outline: "none",
															cursor: "pointer",
														}}
														{...field} // This will include onChange, onBlur, name, and ref
														checked={field.value} // Ensure the checkbox is checked based on the field's value
													/>
												</div>
											)}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</form>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<button
					className={`d-flex justify-content-center align-items-center ${classNames["save-btn"]}`}
					tabIndex={0}
					onClick={handleSaveClick}
					onKeyDown={handleSaveClick}
					type="button"
					aria-label="Save"
				>
					SAVE PREFERENCES
				</button>
			</div>
		</div>
	);
}

export default Notifications;
