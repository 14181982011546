import { useState, useEffect } from "react";
// import ReactJson from "react-json-view";

// import {
// 	getRowColor,
// 	getRowTextColor,
// } from "../../../shared/services/bugDetails";
import "./ConsoleEvent.scss";
import { consoleTypes } from "../../../utils/constants";
import warningimg from "../../../assets/warning_console.svg";
import errorimg from "../../../assets/error_console.svg";

function ErrorConsole({ consoleEvent }: any) {
	const [showFiles, setShowFiles] = useState(false);

	const handleDropdownClick = () => {
		setShowFiles(!showFiles);
	};

	return (
		<div className="error_console">
			<div className="error_icon">
				<img src={errorimg} alt="error icon" />
			</div>
			<div className="error_details">
				{consoleEvent.value.length > 0 &&
					// eslint-disable-next-line array-callback-return  consistent-return
					consoleEvent.value.map((value: any) => {
						if (value?.length > 0) {
							return (
								<div key={value?.length} className="error_value">
									{value}
									<span
										className="error_url"
										onClick={handleDropdownClick}
										onKeyDown={handleDropdownClick}
										role="button"
										tabIndex={0}
									>
										{consoleEvent.short_url}
									</span>
								</div>
							);
						}
					})}

				{showFiles && (
					<div className="dropdown_content">
						<a
							href={consoleEvent.full_url}
							target="_blank"
							rel="noopener noreferrer"
						>
							{consoleEvent.full_url}
						</a>
					</div>
				)}
			</div>
		</div>
	);
}
function WarningConsole({ consoleEvent }: any) {
	const [showFiles, setShowFiles] = useState(false);

	const handleDropdownClick = () => {
		setShowFiles(!showFiles);
	};

	const parseText = (text: any) => {
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		const parts = text?.split(urlRegex).map((part: any) =>
			urlRegex.test(part) ? (
				<a
					key={part}
					href={part}
					target="_blank"
					rel="noopener noreferrer"
					className="link"
				>
					{part}
				</a>
			) : (
				part
			),
		);
		return parts;
	};

	return (
		<div className="console_event warning">
			<div className="event_icon">
				<img src={warningimg} alt="warning icon" />
			</div>
			<div className="event_details">
				{consoleEvent.value.length > 0 &&
					consoleEvent.value.map((value: any, i: any) => (
						<div key={consoleEvent.short_url} className="event_value">
							{parseText(value) ?? ""}
							{i === 0 && (
								<span className="event_url" onClick={handleDropdownClick}>
									{consoleEvent.short_url}
								</span>
							)}
						</div>
					))}
				{showFiles && (
					<div className="dropdown_content">
						<a
							href={consoleEvent.full_url}
							target="_blank"
							rel="noopener noreferrer"
						>
							{consoleEvent.full_url}
						</a>
					</div>
				)}
			</div>
		</div>
	);
}
function LogConsole({ consoleEvent }: any) {
	const [showFiles, setShowFiles] = useState(false);

	const parseText = (text: any) => {
		if (text?.length > 0) {
			const urlRegex = /(https?:\/\/[^\s]+)/g;
			const parts = text?.split(urlRegex).map((part: any, index: number) =>
				urlRegex.test(part) ? (
					<a
						key={index}
						href={part}
						target="_blank"
						rel="noopener noreferrer"
						className="link"
					>
						{part}
					</a>
				) : (
					part
				),
			);
			return parts;
		}
	};
	const handleDropdownClick = () => {
		setShowFiles(!showFiles);
	};

	return (
		<div className="console_event log">
			<div className="event_details">
				{consoleEvent.value.length > 0 &&
					consoleEvent.value.map((value: any, i: any) => (
						<div key={i} className="event_value">
							{parseText(value) ?? ""}
							{i === 0 && (
								<span className="event_url" onClick={handleDropdownClick}>
									{consoleEvent.short_url}
								</span>
							)}
						</div>
					))}
				{showFiles && (
					<div className="dropdown_content">
						<a
							href={consoleEvent.full_url}
							target="_blank"
							rel="noopener noreferrer"
						>
							{consoleEvent.full_url}
						</a>
					</div>
				)}
			</div>
		</div>
	);
}

function ConsoleTab(props: any) {
	const { consoleEvents } = props;

	const [selectedType, setSelectedType] = useState(consoleTypes.ALL);
	const [searchText, setSearchText] = useState("");
	const [filteredConsoleEvents, setFilteredConsoleEvents] = useState(
		consoleEvents || [],
	);
	console.log({ filteredConsoleEvents });
	const [maxHeight, setMaxHeight] = useState("100%");
	const [isOpen, setIsOpen] = useState(false);
	const [selectedLevels, setSelectedLevels] = useState([
		"Verbose",
		"Error",
		"Info",
		"Warning",
	]);

	const levels = ["Verbose", "Error", "Info", "Warning"];

	const handleSelect = (level: any) => {
		setSelectedLevels((prev) =>
			prev.includes(level)
				? prev.filter((item) => item !== level)
				: [...prev, level],
		);
	};

	const handleSelectAll = () => {
		if (selectedLevels.length === levels.length) {
			setSelectedLevels([]);
		} else {
			setSelectedLevels(levels);
		}
	};

	const getLabel = () => {
		if (selectedLevels.length === 0) {
			return "No Levels";
		}
		if (selectedLevels.length === levels.length) {
			return "All Levels";
		}
		if (selectedLevels.length === 1) {
			return `${selectedLevels[0]} only`;
		}
		return "Custom Levels";
	};
	const handleChange = (e: any) => {
		setSearchText(e.target.value);
	};

	useEffect(() => {
		const handleResize = () => {
			setMaxHeight(`${window.innerHeight / 2 - 50}px`);
		};

		window.addEventListener("resize", handleResize);
		handleResize(); // Call initially to set the value

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	useEffect(() => {
		console.log("hello");
	}, []);
	// const handleSearchInputChange = (e: any) => {
	// 	setSearchText(e.target.value);
	// 	const searchTextLower = e.target.value.toLowerCase();

	// 	const filteredConsoleBasedOnText = consoleEvents?.filter(
	// 		(consoleEvent: any) => {
	// 			if (consoleEvent.type === consoleTypes.EXCEPTION) {
	// 				return consoleEvent?.value?.toLowerCase().includes(searchTextLower);
	// 			}

	// 			const firstValue = consoleEvent?.value[0];
	// 			return (
	// 				typeof firstValue !== "object" &&
	// 				JSON.stringify(firstValue).toLowerCase().includes(searchTextLower)
	// 			);
	// 		},
	// 	);

	// 	let filteredByType = filteredConsoleBasedOnText;

	// 	if (selectedType && selectedType !== consoleTypes.ALL) {
	// 		filteredByType = filteredConsoleBasedOnText?.filter(
	// 			(consoleEvent: any) => {
	// 				return consoleEvent.type === selectedType;
	// 			},
	// 		);
	// 	}

	// 	setFilteredConsoleEvents(filteredByType);
	// };

	// const handleClickFilterConsoleType = (type: any) => {
	// 	if (consoleEvents) {
	// 		const arr = consoleEvents?.filter((consoleEvent: any) => {
	// 			console.log({ consoleEvent });
	// 			if (consoleEvent.type === consoleTypes.EXCEPTION) {
	// 				return (
	// 					consoleEvent?.value
	// 						?.toLowerCase()
	// 						?.indexOf(searchText?.toLowerCase()) !== -1
	// 				);
	// 			}
	// 			if (typeof consoleEvent?.value[0] === "object") {
	// 				return false;
	// 			}
	// 			return (
	// 				consoleEvent?.value[0]
	// 					?.toLowerCase()
	// 					?.indexOf(searchText?.toLowerCase()) !== -1
	// 			);
	// 		});
	// 		if (type === consoleTypes.LOG) {
	// 			if (selectedType === type) {
	// 				setSelectedType("");
	// 				setFilteredConsoleEvents(arr);
	// 			} else {
	// 				const filteredConsole = arr?.filter((ele: any) => {
	// 					return ele.type === consoleTypes.LOG;
	// 				});
	// 				console.log({ filteredConsole });
	// 				setFilteredConsoleEvents(filteredConsole);
	// 				setSelectedType(type);
	// 			}
	// 		} else if (type === consoleTypes.WARN) {
	// 			if (selectedType === type) {
	// 				setSelectedType("");
	// 				setFilteredConsoleEvents(arr);

	// 				return;
	// 			}
	// 			const filteredConsole = arr?.filter((ele: any) => {
	// 				return ele.type === consoleTypes.WARN;
	// 			});
	// 			console.log({ filteredConsole });
	// 			setFilteredConsoleEvents(filteredConsole);
	// 			setSelectedType(type);
	// 		} else if (type === consoleTypes.ERROR) {
	// 			if (selectedType === type) {
	// 				setSelectedType("");
	// 				setFilteredConsoleEvents(arr);
	// 				return;
	// 			}
	// 			const filteredConsole = arr?.filter((ele: any) => {
	// 				return (
	// 					ele.type === consoleTypes.ERROR ||
	// 					ele.type === consoleTypes.EXCEPTION
	// 				);
	// 			});
	// 			console.log({ filteredConsole });
	// 			setFilteredConsoleEvents(filteredConsole);
	// 			setSelectedType(type);
	// 		} else {
	// 			setSelectedType(consoleTypes.ALL);
	// 			setFilteredConsoleEvents(arr);
	// 		}
	// 	}
	// };
	const errorCount = consoleEvents.filter((consoleEvent: any) => {
		const matchesType =
			selectedLevels.includes("Error") && consoleEvent.type === "error";

		// Ensure consoleEvent.value is an array before calling some
		const matchesFilter =
			Array.isArray(consoleEvent?.value) &&
			consoleEvent.value.some((val: any) =>
				String(val).toLowerCase().includes(searchText.toLowerCase()),
			);

		// Ensure consoleEvent.value is an array before accessing its length
		const isValidError =
			Array.isArray(consoleEvent.value) &&
			(consoleEvent.value.length > 1 ||
				(consoleEvent.value.length === 1 &&
					Object.keys(consoleEvent.value[0]).length > 0));

		return matchesType && matchesFilter && isValidError;
	}).length;
	const warningCount = consoleEvents.filter((consoleEvent: any) => {
		const matchesType =
			selectedLevels.includes("Warning") && consoleEvent.type === "warn";

		// Ensure consoleEvent.value is an array before calling some
		const matchesFilter =
			Array.isArray(consoleEvent?.value) &&
			consoleEvent.value.some((val: any) =>
				String(val).toLowerCase().includes(searchText.toLowerCase()),
			);

		const isValidWarning =
			Array.isArray(consoleEvent.value) &&
			(consoleEvent.value.length > 1 ||
				(consoleEvent.value.length === 1 &&
					Object.keys(consoleEvent.value[0]).length > 0));

		return matchesType && matchesFilter && isValidWarning;
	}).length;
	const logCount = consoleEvents.filter((consoleEvent: any) => {
		const matchesType = consoleEvent.type === "log";

		return matchesType;
	}).length;

	console.log("count", logCount, errorCount, warningCount);

	return (
		<div
			className="tab-pane active show"
			style={{
				maxHeight,
				overflowY: "scroll",
				background: "#1f1f1f",
				minHeight: maxHeight,
			}}
		>
			{errorCount === 0 && warningCount === 0 && logCount === 0 && (
				<div className="p-4">
					<div
						className="project-setting-title"
						style={{ fontSize: "14px", lineHeight: "16px", color: "#fff" }}
					>
						*No logs in this site.
					</div>
				</div>
			)}

			{(errorCount > 0 || warningCount > 0 || logCount > 0) && (
				<div className="console-filters-parent">
					<div style={{ position: "relative" }} id="filter-input">
						<input
							type="text"
							placeholder="Filter"
							className="console-filter-container"
							onChange={(e) => handleChange(e)}
						/>
						<div className="filter-svg">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M11.9907 3.61606C9.33849 3.31964 6.66154 3.31964 4.00935 3.61606C3.96869 3.62072 3.93002 3.63621 3.89739 3.66091C3.86476 3.68561 3.83935 3.71861 3.82382 3.75648C3.80829 3.79435 3.80321 3.83569 3.80911 3.87619C3.81501 3.91669 3.83167 3.95486 3.85735 3.98673L6.20201 6.88539C7.04186 7.92366 7.50003 9.21865 7.50001 10.5541V12.4801L8.50001 13.2134V10.5534C8.50015 9.21821 8.95832 7.92348 9.79801 6.88539L12.1427 3.98673C12.1684 3.95486 12.185 3.91669 12.1909 3.87619C12.1968 3.83569 12.1917 3.79435 12.1762 3.75648C12.1607 3.71861 12.1353 3.68561 12.1026 3.66091C12.07 3.63621 12.0313 3.62072 11.9907 3.61606ZM3.89868 2.62206C6.62464 2.31739 9.37606 2.31739 12.102 2.62206C13.066 2.73006 13.5307 3.86073 12.9207 4.61539L10.576 7.51406C9.87991 8.37433 9.50009 9.44743 9.50001 10.5541V14.2001C9.50008 14.2925 9.47451 14.3832 9.42614 14.462C9.37776 14.5408 9.30849 14.6046 9.22601 14.6464C9.14353 14.6882 9.05107 14.7063 8.95892 14.6987C8.86677 14.6911 8.77853 14.6581 8.70401 14.6034L6.70401 13.1367C6.64073 13.0902 6.58928 13.0295 6.55384 12.9594C6.5184 12.8894 6.49996 12.8119 6.50001 12.7334V10.5534C6.50001 9.44673 6.12001 8.37406 5.42468 7.51339L3.08001 4.61606C2.46935 3.86139 2.93335 2.73006 3.89868 2.62206Z"
									fill="#E3E3E3"
								/>
							</svg>
						</div>
					</div>

					<div className="console-status-select-container">
						<div
							className="console-status-select"
							onClick={() => setIsOpen(!isOpen)}
							onKeyDown={() => setIsOpen(!isOpen)}
							tabIndex={0}
							role="button"
						>
							<div className="console-status-selected">
								{getLabel()}
								<svg
									width="8"
									height="8"
									viewBox="0 0 8 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_43_129)">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4.43328 6.95091C4.38941 7.02699 4.32628 7.09017 4.25024 7.13411C4.1742 7.17804 4.08793 7.20117 4.00011 7.20117C3.91229 7.20117 3.82602 7.17804 3.74998 7.13411C3.67393 7.09017 3.61081 7.02699 3.56694 6.95091L0.688941 1.96624C0.645058 1.89023 0.621954 1.80401 0.621954 1.71624C0.621953 1.62847 0.645055 1.54225 0.688938 1.46624C0.732821 1.39024 0.795938 1.32712 0.871946 1.28323C0.947954 1.23935 1.03417 1.21624 1.12194 1.21624L6.87794 1.21624C6.96571 1.21624 7.05193 1.23935 7.12794 1.28323C7.20395 1.32712 7.26706 1.39023 7.31095 1.46624C7.35483 1.54225 7.37793 1.62847 7.37793 1.71624C7.37793 1.80401 7.35483 1.89023 7.31094 1.96624L4.43294 6.95091L4.43328 6.95091Z"
											fill="#E3E3E3"
										/>
									</g>
									<defs>
										<clipPath id="clip0_43_129">
											<rect
												width="8"
												height="8"
												fill="white"
												transform="translate(8 8) rotate(180)"
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
							{isOpen && (
								<div className="console-status-options">
									<div
										className="console-status-option"
										onClick={handleSelectAll}
										onKeyDown={handleSelectAll}
										tabIndex={0}
										role="button"
									>
										<input
											type="checkbox"
											checked={selectedLevels.length === levels.length}
											readOnly
										/>
										<span style={{ marginLeft: "4px" }}>Select all</span>
									</div>
									{levels.map((level) => (
										<div
											className="console-status-option"
											key={level}
											onClick={() => handleSelect(level)}
											onKeyDown={() => handleSelect(level)}
											tabIndex={0}
											role="button"
										>
											<input
												type="checkbox"
												checked={selectedLevels.includes(level)}
												readOnly
											/>
											<span style={{ marginLeft: "4px" }}>{level}</span>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ marginLeft: "255px" }}
					>
						<div className="errors-only-count d-flex">
							<div className="error_icon">
								<img src={errorimg} alt="error icon" />
							</div>
							<div>{errorCount}</div>
						</div>
						<div
							className="warning-only-count d-flex"
							style={{ marginLeft: "20px" }}
						>
							<div className="event_icon">
								<img
									src={warningimg}
									alt="warning icon"
									width={16}
									height={16}
								/>
							</div>
							<div>{warningCount}</div>
						</div>
					</div>
				</div>
			)}

			{consoleEvents?.length > 0 && (
				<div style={{ marginTop: "3px", height: "320px", background: "#333" }}>
					{consoleEvents
						.filter((consoleEvent: any) => {
							const matchesType =
								(selectedLevels.includes("Error") &&
									consoleEvent.type === "error") ||
								(selectedLevels.includes("Warning") &&
									consoleEvent.type === "warn") ||
								(selectedLevels.includes("Verbose") &&
									consoleEvent.type === "log");
							const matchesFilter =
								Array.isArray(consoleEvent?.value) &&
								consoleEvent.value.some((val: any) =>
									String(val).toLowerCase().includes(searchText.toLowerCase()),
								);

							return matchesType && matchesFilter;
						})
						.map((consoleEvent: any, index: any) => {
							if (
								consoleEvent.type === "error" &&
								!(
									consoleEvent.value.length === 1 &&
									Object.keys(consoleEvent.value[0]).length === 0
								)
							) {
								return <ErrorConsole key={index} consoleEvent={consoleEvent} />;
							}
							if (
								consoleEvent.type === "warn" &&
								!(
									consoleEvent.value.length === 1 &&
									Object.keys(consoleEvent.value[0]).length === 0
								)
							) {
								return (
									<WarningConsole key={index} consoleEvent={consoleEvent} />
								);
							}
							if (consoleEvent.type === "log") {
								return <LogConsole key={index} consoleEvent={consoleEvent} />;
							}
							return null;
						})}
				</div>
			)}
		</div>
	);
}

export default ConsoleTab;
