import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useSendJiraAccessTokenMutation } from "../../shared/services/integration";

function ReceiveJira() {
	const params = new URLSearchParams(useLocation().search);
	const [sendJiraAccessToken] = useSendJiraAccessTokenMutation();
	const codee: any = params.get("code") ?? null;
	const api = async () => {
		const code: any = params.get("code") ?? null;
		const extensionId: string = params.get("state") ?? "";
		const data: any = {
			code,
		};
		if (code) {
			const response: any = await sendJiraAccessToken(data);
			console.log({ response });
			if (
				extensionId &&
				response?.data?.success &&
				response?.data?.data?.accessToken
			) {
				chrome.runtime.sendMessage(
					extensionId,
					{
						msg: "JIRA-ACCESS-CBP",
						data: {
							accessToken: response.data.data.accessToken,
							cloudId: response.data.data.cloudId,
							siteUrl: response.data.data.siteUrl,
							refreshToken: response.data.data.refreshToken,
						},
					},
					(respne: any) => {
						console.log({ respne });
						setTimeout(() => {
							window.close();
						}, 1500);
					},
				);
			} else {
				toast.error("Some error occured. Please try again");
				setTimeout(() => {
					window.close();
				}, 1500);
			}
		} else {
			setTimeout(() => {
				window.close();
			}, 1500);
		}
	};

	useEffect(() => {
		api();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex flex-column justify-content-center align-items-center primary-background">
			<div>{codee ? "Authorization success" : "Authorization failed"}</div>
			<div>Please wait ...</div>
		</div>
	);
}

export default ReceiveJira;
