import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

export const commentApi = createApi({
	reducerPath: "comment",
	baseQuery: baseQueryWithReauth,
	tagTypes: ["COMMENT"],

	endpoints: (builder) => ({
		createComment: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/comment/${data.issueId}`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
			invalidatesTags: ["COMMENT"],
		}),

		uploadCommentFile: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/comment/upload",
				headers: { "Content-Type": "multipart/form-data" },
				method: "POST",
				body: data,
			}),
		}),

		updateComment: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/comment/${data.commentId}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: data,
			}),
			invalidatesTags: ["COMMENT"],
		}),

		deleteComment: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/comment/${data.commentId}`,
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
			}),
			invalidatesTags: ["COMMENT"],
		}),

		getComments: builder.query<any, any>({
			query: (data: any) => ({
				url: `/comment/${data.issueId}`,
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}),
			providesTags: ["COMMENT"],
		}),
	}),
});

export const {
	useCreateCommentMutation,
	useDeleteCommentMutation,
	useUpdateCommentMutation,
	useGetCommentsQuery,
	useUploadCommentFileMutation,
} = commentApi;
