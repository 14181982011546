import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import "./chatmessage.scss";
import { toast } from "react-toastify";

function CodeBlock({ language, code }: any) {
	const copyToClipboard = () => {
		navigator.clipboard.writeText(code);
		toast.success("Code copied to clipboard!");
	};

	return (
		<div className="code-block-container">
			<div className="code-block-header">
				<span className="code-language">{language}</span>
				<button className="copy-button" onClick={copyToClipboard} type="button">
					Copy code
				</button>
			</div>
			<SyntaxHighlighter language="java" style={darcula} className="code-block">
				{code}
			</SyntaxHighlighter>
		</div>
	);
}

export default CodeBlock;
