import { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Player, BigPlayButton } from "video-react";

import "video-react/dist/video-react.css";
import "./videoplayer.scss";

function VideoPlayer(props: any) {
	const { data, totalHeight } = props;

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoaded(true);
		}, 2000);
	});

	useEffect(() => {
		if (data && loaded) {
			setTimeout(() => {
				const canvasContainer: any = document.querySelector(".video-react");

				if (canvasContainer) {
					// Set the height to 100% with !important

					canvasContainer.style.setProperty("padding-top", "42%", "important");
					canvasContainer.style.setProperty(
						"height",
						`${totalHeight}px`,
						"important",
					);
				}
			}, 500);
		}
	}, [totalHeight, data, loaded]);

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "flex-start",
				height: totalHeight,
			}}
		>
			{data && loaded ? (
				<Player playsInline>
					<BigPlayButton position="center" />
					<source
						src={`${data}#t=190`}
						width="100%"
						height="100%"
						style={{ objectFit: "cover" }}
					/>
				</Player>
			) : (
				<div
					style={{
						width: "100%",
						height: "40vh",
					}}
					className="skeleton video-loading"
					role="status"
				/>
			)}
		</div>
	);
}

export default VideoPlayer;
