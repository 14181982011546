import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PublicTopNav from "../../components/TopNav/PublicTopNav";
import { useVerifyEmailMutation } from "../../shared/services/auth";

function VerifyUser() {
	const [isEmailVerified, setIsEmailVerified] = useState<any>("pending");
	const { token } = useParams();
	const navigate = useNavigate();
	const [verifyEmail] = useVerifyEmailMutation();
	// using ref so that verify email function run only once
	const isApiCalled = useRef(false);

	useEffect(() => {
		document.title = "TestBuddy | Verify Email";
		const handleVerifyEmail = async (code: any) => {
			// Check if the API call has already been made
			if (isApiCalled.current) return;

			// Set the ref to true to indicate the API has been called
			isApiCalled.current = true;

			const data: any = await verifyEmail({ token: code });
			if (data?.data?.success === true) {
				toast.success("Email Verified Successfully!", {
					toastId: "1", // it prevents showing duplicate toast message
				});
				setIsEmailVerified("verified");
				setTimeout(() => {
					navigate("/login");
				}, 5000);
			} else {
				setIsEmailVerified("error");
				setTimeout(() => {
					navigate("/login");
				}, 5000);
			}
		};

		if (token) {
			handleVerifyEmail(token);
		}
	}, [token]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<PublicTopNav />
			<div style={{ paddingTop: "100px" }}>
				{isEmailVerified === "pending" && <h3>Verifying your email...</h3>}
				{isEmailVerified === "verified" && (
					<h3>Email Verified Successfully! Redirecting to login page ...</h3>
				)}
				{isEmailVerified === "error" && (
					<>
						<h4>Email is already verified . Please Sign In.</h4>
						<h4>Redirecting to login page ...</h4>
					</>
				)}
			</div>
		</div>
	);
}

export default VerifyUser;
