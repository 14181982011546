import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import className from "./styles.module.scss";
import UserInfoPill from "./UserInfoPill";
import Notification from "./Notification";
import TBLogo from "../../assets/img/tbLogo";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import TBLogoDark from "../../assets/img/tbLogoDark";

interface IProps {
	openRoute?: boolean; // eslint-disable-line  react/require-default-props
	isBugDetailsPage: boolean;
}

function TopNav(props: IProps) {
	const { openRoute, isBugDetailsPage } = props;

	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;
	const navigate = useNavigate();
	const [notificationClicked, setNotificationClicked] =
		useState<boolean>(false);

	const handleNotificationClick = (e: any) => {
		e.preventDefault();
		setNotificationClicked(!notificationClicked);
	};
	return (
		<header
			className={`${className.navbar} padding-0 position-fixed w-100 ${
				// eslint-disable-next-line no-nested-ternary
				isBugDetailsPage
					? "navbar-background-bugdetails"
					: !openRoute
						? "navbar-background"
						: ""
			} d-flex justify-content-between align-items-center ${
				darkMode ? "" : className.dropShadow
			}`}
		>
			<div
				className={`d-flex gap-3 ${isBugDetailsPage ? " " : "navbar-background"}`}
			>
				{/* {!openRoute && (
					<svg
						className="d-sm-block d-md-none d-xl-none"
						width="30"
						fill={darkMode ? "#fff" : "black"}
						style={{ cursor: "pointer" }}
						version="1.1"
						id="Capa_1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 283.426 283.426"
						onClick={() => {
							const sidenavContainer: any =
								document.getElementById("sidenav-container");
							if (sidenavContainer) {
								if (sidenavContainer.classList.contains("d-sm-none")) {
									sidenavContainer.classList.remove("d-sm-none");
									sidenavContainer.classList.add("d-sm-flex");
								} else if (sidenavContainer.classList.contains("d-sm-flex")) {
									sidenavContainer.classList.remove("d-sm-flex");
									sidenavContainer.classList.add("d-sm-none");
								}
							}
						}}
					>
						<g>
							<rect x="0" y="40.84" width="283.426" height="37.735" />
							<rect x="0" y="117.282" width="283.426" height="37.735" />
							<rect x="0" y="194.851" width="283.426" height="37.735" />
						</g>
					</svg>
				)} */}
				<div
					className={`${isBugDetailsPage ? "" : "nav-logo"}`}
					role="button"
					tabIndex={0}
					onKeyDown={() => {
						navigate("/workspace");
					}}
					onClick={() => {
						navigate("/workspace");
					}}
					aria-label="home button"
					style={{ cursor: "pointer" }}
				>
					{darkMode || isBugDetailsPage ? <TBLogoDark /> : <TBLogo />}
				</div>
			</div>
			<div className="d-flex gap-3">
				<OverlayTrigger
					trigger="click"
					key="bottom-end"
					placement="bottom-end"
					rootClose
					overlay={
						<Popover
							style={{ zIndex: 99999, padding: 0 }}
							id={`popover-positioned-${"bottom-end"}`}
						>
							<Popover.Body style={{ padding: 0, width: 400 }}>
								<Notification />
							</Popover.Body>
						</Popover>
					}
				>
					<div
						className="nav-item  dropdown d-none d-md-flex "
						id="notification"
					>
						<div
							id="notification"
							className={`btn btn-icon lh-1 button-icon-outline ${
								notificationClicked ? "show" : ""
							}`}
							style={{ position: "relative" }}
							data-bs-toggle="dropdown"
							tabIndex={0}
							aria-label="Show notifications"
							aria-expanded={notificationClicked}
							role="button" // Add the appropriate role
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									handleNotificationClick(e);
								}
							}}
							onClick={handleNotificationClick}
						>
							{
								// !notificationCountIsFetching &&
								// 	notificationCount?.unseenCount !== 0 && (
								<div
									style={{
										position: "absolute",
										top: -5,
										right: -5,
										borderRadius: 100,
										border: "1px solid lightgray",
										fontSize: 12,
										color: "white",
										padding: 2,
										width: "fit-content",
										height: "fit-content",
										background: "#0850c4",
										display: "none",
									}}
								>
									{/* {!notificationCountIsFetching &&
										notificationCount?.unseenCount} */}
								</div>
								// )
							}
							<svg
								style={{
									fill: "white",
									height: 24,
									width: 24,
									filter: darkMode || isBugDetailsPage ? "none" : "invert(1)",
								}}
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-bell"
								viewBox="0 0 16 16"
							>
								<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
							</svg>
						</div>
					</div>
				</OverlayTrigger>
				{!openRoute && <UserInfoPill />}
			</div>
		</header>
	);
}

export default TopNav;
