// import PublicBugDetails from "../pages/PublicBugDetails";
import BugDetails from "../pages/BugDetails/BugDetails";
import Login from "../pages/login";
import Azure from "../pages/Pmt/azure";
import Clickup from "../pages/Pmt/clickup";
import Github from "../pages/Pmt/github";
import Gitlab from "../pages/Pmt/gitlab";
import Jira from "../pages/Pmt/Jira";
import Slack from "../pages/Pmt/slack";
import Signup from "../pages/signup";
import VerifyUser from "../pages/verifyEmail";
import Workspace from "../pages/Workspace";
import WorkspaceSettings from "../pages/WorkspaceSettings";
import ForgotPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/resetPassword";
import TestInvite from "../pages/testInvite/testInvite";
import ReceiveJira from "../pages/ReceiveJira";
import ReceiveClickup from "../pages/ReceiveClickup";
import Youtrack from "../pages/Pmt/youtrack";

interface IRoute {
	path: string;
	component: () => JSX.Element;
}

interface IPageRoute extends IRoute {
	children?: IRoute[];
}

export const PUBLIC_ROUTES: IPageRoute[] = [
	{
		path: "/login",
		component: Login,
	},
	{
		path: "/signup",
		component: Signup,
	},
	{
		path: "/slack",
		component: Slack,
	},
	{
		path: "/jira",
		component: Jira,
	},
	{
		path: "/github",
		component: Github,
	},
	{
		path: "/gitlab",
		component: Gitlab,
	},
	{
		path: "/clickup",
		component: Clickup,
	},
	{
		path: "/azure",
		component: Azure,
	},

	{
		path: "/user/verify/:token",
		component: VerifyUser,
	},
	{
		path: "/user/forgot-password",
		component: ForgotPassword,
	},
	{
		path: "/user/reset-password/:id",
		component: ResetPassword,
	},
	{
		path: "/bug/details/public/:id",
		component: BugDetails,
	},
	{
		path: "/receive/jira",
		component: ReceiveJira,
	},
	{
		path: "/receive/clickup",
		component: ReceiveClickup,
	},
	{
		path: "/youtrack",
		component: Youtrack,
	},
];

export const PRIVATE_ROUTES: IPageRoute[] = [
	{
		path: "/workspace",
		component: Workspace,
	},
	{
		path: "/workspace/:id",
		component: Workspace,
	},
	{
		path: "/workspace/:id/settings",
		component: WorkspaceSettings,
	},
	{
		path: "/",
		component: Workspace,
	},
	{
		path: "/bugdetails/:id",
		component: BugDetails,
	},
	{
		path: "/invite/accept/:id",
		component: TestInvite,
	},
];

export const OPEN_ROUTES = PUBLIC_ROUTES.map((route) => route.path);
