import classNames from "./newUserCard.module.scss";

function NewUserCard(props: any) {
	const {
		heading,
		description,
		buttonText,
		buttonIcon,
		action,
		setShowCreateWorkspaceModal,
		setShowTutorialModal,
	} = props;

	const handleAction = () => {
		if (action === "createWorkspace") {
			// logic for modal opening
			setShowCreateWorkspaceModal(true);
		} else if (action === "watchTutorial") {
			// write logic for tutorial
			setShowTutorialModal(true);
		}
	};

	return (
		<div
			className={`table-background ${classNames["custom-card-container"]} card-box-shadow`}
		>
			<div className="d-flex justify-content-center align-items-center flex-column">
				<div className={classNames["custom-card-heading"]}>
					<p className="primary-font-color">{heading}</p>
				</div>
				<div className={classNames["custom-card-description"]}>
					<p className="primary-font-color">{description}</p>
				</div>
				<div
					className={classNames["custom-card-button"]}
					onKeyDown={handleAction}
					onClick={handleAction}
					role="button"
					tabIndex={0}
				>
					<div className="d-flex">
						<div style={{ paddingRight: "6px" }}>
							<img
								src={buttonIcon}
								alt="plus-icon"
								style={{ paddingBottom: "4px" }}
							/>
						</div>

						<div className={classNames["custom-button-text"]}>{buttonText}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NewUserCard;
