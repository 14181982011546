import React, { useEffect, useState, useLayoutEffect } from "react";
import { Tooltip } from "bootstrap";

function EventsTab(props: any) {
	const { mouseEvents, targetHtml, metaResponse } = props;

	const [showFullRows, setShowFullRows] = useState(
		new Array(mouseEvents?.length).fill(false),
	);
	const [timeArr, setTimeArr] = useState([]);
	const [endTime, setEndTime] = useState<any>();
	const [showFull, setShowFull] = useState(false);
	const [full, setfull] = useState(false);
	const [responses, setResponses] = useState([]);
	const [maxHeight, setMaxHeight] = useState("100%");

	useEffect(() => {
		const handleResize = () => {
			setMaxHeight(`${window.innerHeight / 2 - 50}px`);
		};

		window.addEventListener("resize", handleResize);
		handleResize(); // Call initially to set the value

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	useEffect(() => {
		// Initialize tooltips when the component mounts
		const tooltipTriggerList = [].slice.call(
			// eslint-disable-next-line   quotes
			document.querySelectorAll('[data-toggle="tooltip"]'),
		);
		tooltipTriggerList.map((tooltipTriggerEl) => {
			return new Tooltip(tooltipTriggerEl);
		});
	}, []);
	function exactTimeIST(time: any) {
		const date = new Date(time);

		// Get the user's time zone using Intl.DateTimeFormat
		const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

		// Format the adjusted time as a string with the user's time zone
		return date.toLocaleString("en-US", {
			timeZone,
			hour: "numeric",
			minute: "2-digit",
			second: "2-digit",
			hour12: true,
		});
	}
	useLayoutEffect(() => {
		const fetchTimeResponses = async () => {
			const responsePromises = mouseEvents.map(async (event: any) => {
				const response = await exactTimeIST(event.time);
				return response;
			});

			const eventResponses: any = await Promise.all(responsePromises);
			setResponses(eventResponses);
		};

		fetchTimeResponses();
	}, [mouseEvents]);
	function formatTime(milliseconds: any) {
		const seconds = Math.floor(milliseconds / 1000);
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;

		// Cap minutes at 59
		const cappedMinutes = Math.min(minutes, 59);

		let formattedTime = "";

		if (cappedMinutes < 10) {
			formattedTime += "0";
		}

		formattedTime += `${cappedMinutes.toString()}.`;

		if (remainingSeconds < 10) {
			formattedTime += "0";
		}

		formattedTime += remainingSeconds.toString();

		return formattedTime;
	}
	useEffect(() => {
		if (mouseEvents?.length > 0) {
			const firstTime = mouseEvents[0].time;
			// eslint-disable-next-line  no-unsafe-optional-chaining
			const lastTime = +mouseEvents[mouseEvents?.length - 1].time + 1000;
			// let formattedTimes = [];
			// mouseEvents.forEach((obj) => {
			// 	const elapsedMilliseconds = obj.time - firstTime;
			// 	const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
			// 	const elapsedMinutes = Math.floor(elapsedSeconds / 60);
			// 	const remainingSeconds = elapsedSeconds % 60;

			// 	let formattedTime = '';

			// 	if (elapsedMinutes < 10) {
			// 		formattedTime += '0';
			// 	}

			// 	formattedTime += elapsedMinutes.toString() + '.';

			// 	if (remainingSeconds < 10) {
			// 		formattedTime += '0';
			// 	}

			// 	formattedTime += remainingSeconds.toString();

			// 	if (elapsedMinutes >= 10) {
			// 		formattedTime =
			// 			(elapsedMinutes / 10).toString() + formattedTime.substr(1, 3);
			// 	}
			// 	formattedTime = formattedTime.toString();
			// 	formattedTimes.push(formattedTime);
			// });
			// setTimeArr(formattedTimes);

			// const elapsedMilliseconds = lastTime - firstTime;
			// const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
			// const elapsedMinutes = Math.floor(elapsedSeconds / 60);
			// const remainingSeconds = elapsedSeconds % 60;

			// let formattedTime = '';

			// if (elapsedMinutes < 10) {
			// 	formattedTime += '0';
			// }

			// formattedTime += elapsedMinutes.toString() + '.';

			// if (remainingSeconds < 10) {
			// 	formattedTime += '0';
			// }

			// formattedTime += remainingSeconds.toString();

			// if (elapsedMinutes >= 10) {
			// 	formattedTime =
			// 		(elapsedMinutes / 10).toString() + formattedTime.substr(1, 3);
			// }

			// setEndTime(formattedTime);
			const formattedTimes = mouseEvents.map((obj: any) => {
				const elapsedMilliseconds = obj.time - firstTime;
				return formatTime(elapsedMilliseconds);
			});

			setTimeArr(formattedTimes);
			setEndTime(formatTime(lastTime - firstTime));
		}
	}, []);

	const toggleShowFull = (index: any) => {
		const newShowFullRows = [...showFullRows];
		newShowFullRows[index] = !newShowFullRows[index];
		setShowFullRows(newShowFullRows);
	};
	function extractAttributes(element: any) {
		const attributeRegex =
			/(\S+)=['"]?((?:.(?!['"]?\s+(?:\S+)=|[>'"]))+.)['"]?/g;
		const tagRegex = /<(\w+)\s+([^>]+)>/i;
		const tagMatches = element.match(tagRegex);

		if (tagMatches) {
			const tagAttributes = tagMatches[2];
			const attributeMatches = tagAttributes.matchAll(attributeRegex);
			const attributes = Array.from(attributeMatches, (match: any) => {
				const name = match[1];
				const value = match[2];
				return (
					<span key={name}>
						<span style={{ color: " rgb(243, 156, 18)" }}>{name}</span>="
						<span style={{ color: "rgb(179, 63, 64)" }}>{value}</span>"{" "}
					</span>
				);
			});
			return <span>{attributes}</span>;
		}
		return "";
	}

	// function extractTextFromElementBroken(element: any) {
	// 	const div = document.createElement("div");
	// 	div.innerHTML = element;
	// 	const nodes = div.childNodes;

	// 	function getTextContent(nodes: any) {
	// 		let textContent = "";

	// 		nodes.forEach((node: any) => {
	// 			if (node.nodeType === Node.TEXT_NODE) {
	// 				textContent += node.textContent;
	// 			} else if (node.nodeType === Node.ELEMENT_NODE) {
	// 				if (node.nodeName !== "SCRIPT" && node.nodeName !== "STYLE") {
	// 					textContent += getTextContent(node.childNodes);
	// 				}
	// 			}
	// 		});

	// 		return textContent;
	// 	}

	// 	const textContent = getTextContent(nodes);
	// 	const trimmedContent = textContent?.replace(/\s+/g, " ").trim();
	// 	return trimmedContent?.length > 50
	// 		? `${trimmedContent?.slice(0, 50)}...`
	// 		: trimmedContent;
	// }
	function TrimContent(trimmedContent: any) {
		return trimmedContent?.length > 50
			? `${trimmedContent?.slice(0, 50)}...`
			: trimmedContent;
	}

	// function extractTextFromElementFull(element: any) {
	// 	const div = document.createElement("div");
	// 	div.innerHTML = element;
	// 	const nodes = div.childNodes;

	// 	function getTextContent(nodes: any) {
	// 		let textContent = "";

	// 		nodes.forEach((node: any) => {
	// 			if (node.nodeType === Node.TEXT_NODE) {
	// 				textContent += node.textContent;
	// 			} else if (node.nodeType === Node.ELEMENT_NODE) {
	// 				if (node.nodeName !== "SCRIPT" && node.nodeName !== "STYLE") {
	// 					textContent += getTextContent(node.childNodes);
	// 				}
	// 			}
	// 		});

	// 		return textContent;
	// 	}

	// 	const textContent = getTextContent(nodes);
	// 	const trimmedContent = textContent?.replace(/\s+/g, " ").trim();
	// 	return trimmedContent?.length > 200
	// 		? `${trimmedContent?.slice(0, 200)}...`
	// 		: trimmedContent;
	// }
	function convertTimeStringToMinutesSeconds(timeString: any) {
		const [minutesStr, secondsStr] = timeString.split(".");
		const minutes = parseInt(minutesStr, 10);
		const seconds = parseInt(secondsStr, 10);
		const totalSeconds = minutes * 60 + seconds;
		const minuteVal = Math.floor(totalSeconds / 60);
		const secVal = totalSeconds % 60;
		return `${minuteVal} minutes ${secVal} seconds`;
	}

	// async function exactTimeIST(time) {
	// 	const date = new Date(time);

	// 	// Retrieve the time zone based on the IP address
	// 	const timeZoneResponse = await fetch(`http://ip-api.com/json`);

	// 	const timeZoneData = await timeZoneResponse.json();
	// 	console.log({ timeZoneData });
	// 	const timeZone = timeZoneData.timezone;
	// 	console.log({ timeZone });

	// 	// Format the adjusted time as a string with the user's time zone
	// 	return date.toLocaleString('en-US', {
	// 		timeZone,
	// 		hour: 'numeric',
	// 		minute: '2-digit',
	// 		second: '2-digit',
	// 		hour12: true,
	// 	});
	// }

	// function exactTimeIST(time) {

	//   const date = new Date(time);

	//   // Convert to IST
	//   const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+05:30
	//   const istTime = new Date(date.getTime() + istOffset);

	//   // Extract the components of the IST time
	//   let hours = istTime.getUTCHours();
	//   const minutes = istTime.getUTCMinutes();
	//   const seconds = istTime.getUTCSeconds();

	//   // Format hours to 12-hour format and determine AM/PM
	//   let ampm = 'AM';
	//   if (hours >= 12) {
	//     ampm = 'PM';
	//     if (hours > 12) {
	//       hours -= 12;
	//     }
	//   }

	//   // Format the time as a string
	//   return `${hours.toString()}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

	// }

	return (
		<div
			className="tab-pane active show border-top-golden"
			id="tabs-event"
			// style={{ maxHeight, overflow: 'auto', padding: 0 }}
		>
			<div
				style={{
					maxHeight,
					overflow: "auto",
					padding: 0,
					minHeight: maxHeight,
				}}
			>
				{(mouseEvents?.length === 1 ||
					!mouseEvents ||
					mouseEvents?.length === 0) && (
					<div className="p-4">
						<div
							className="project-setting-title"
							style={{ fontSize: "14px", lineHeight: "16px", color: "#fff" }}
						>
							*No events in this site.
						</div>
					</div>
				)}
				{mouseEvents?.length > 1 && (
					// <div style={{ borderRadius: '8px', over }}>
					<table
						style={{
							borderCollapse: "collapse",
							border: "transparent",
							borderRadius: 8,
							overflowY: "scroll",
						}}
						className="table card-table table-vcenter text-nowrap datatable"
					>
						{/* <thead>
              <tr>
                <th
                  className="table-th-style table-th-font-style color-868E96"
                  style={{ width: 100 }}
                >
                  Event
                </th>
               
              </tr>
            </thead> */}
						<tbody
							style={{
								overflowY: "scroll",
								background: "#282828",
								color: "#fff",
							}}
						>
							<tr>
								<td className="text-code-sm">
									<span
										style={{ color: "#fff" }}
										data-toggle="tooltip"
										data-placement="top"
										title={
											timeArr?.length > 0
												? `Event Captured At: ${convertTimeStringToMinutesSeconds("00.00")} (${
														responses[0]
													})`
												: ""
										}
									>
										00.00
									</span>

									<span style={{ marginLeft: "48px", paddingRight: "13px" }}>
										{" "}
										<svg
											data-v-42851c16=""
											data-v-08603a8b=""
											height="14"
											viewBox="0 0 16 17"
											width="14"
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											className="base-icon block mt-1 w-32 mr-0"
											style={{ color: "rgb(11, 167, 255)" }}
										>
											<g clipRule="evenodd" fillRule="evenodd">
												<path d="M12.695 7.887a.75.75 0 10-1.449-.388l-.68 2.535-1.215-2.107a.75.75 0 10-1.3.75l1.967 3.406-.713 2.66a.75.75 0 101.449.388l.713-2.66 3.405-1.966a.75.75 0 00-.75-1.3l-2.106 1.217zM4.54 1.5a.75.75 0 00-1.448.39l.68 2.534-2.107-1.216a.75.75 0 00-.75 1.299L4.32 6.473l.713 2.66a.75.75 0 001.449-.388l-.713-2.66L7.735 2.68a.75.75 0 10-1.299-.75L5.22 4.036z" />
											</g>
										</svg>
									</span>
									<span>
										Session Started at{" "}
										{showFull ? (
											<span>
												<span
													onClick={() => setShowFull(false)}
													onKeyDown={() => setShowFull(false)}
													role="button"
													tabIndex={0}
													style={{ marginRight: "5px" }}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														className="bi bi-caret-down-fill"
														viewBox="0 0 16 16"
													>
														<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
													</svg>
												</span>
												<br />
												<div
													style={{
														display: "inline-block",
														marginLeft: "120px",
														wordWrap: "break-word",
														maxWidth: "390px",
														whiteSpace: "pre-wrap",
													}}
												>
													<a
														style={{ textDecoration: "underline" }}
														href={mouseEvents[0].value}
													>
														{mouseEvents[0].value !== undefined
															? mouseEvents[0].value
															: targetHtml}
													</a>
												</div>
											</span>
										) : (
											<span>
												{mouseEvents[0]?.value?.length > 50 && (
													<span
														onClick={() => setShowFull(true)}
														onKeyDown={() => setShowFull(true)}
														role="button"
														tabIndex={0}
														style={{
															marginRight: "5px",
															display:
																mouseEvents[0].value === undefined
																	? "none"
																	: "",
														}}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															fill="currentColor"
															className="bi bi-caret-right-fill"
															viewBox="0 0 16 16"
														>
															<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
														</svg>
													</span>
												)}

												<a
													style={{ textDecoration: "underline" }}
													href={
														mouseEvents[0].value
															? mouseEvents[0].value
															: targetHtml
													}
												>
													{mouseEvents[0]?.value?.length > 50
														? `${mouseEvents[0]?.value?.slice(0, 45)}...`
														: mouseEvents[0]?.value}
													{mouseEvents[0].value === undefined ? targetHtml : ""}
												</a>
											</span>
										)}
									</span>
								</td>
							</tr>
							{metaResponse.video !== null && (
								<tr>
									<td className="text-code-sm">
										<span
											style={{ color: "#fff" }}
											data-toggle="tooltip"
											data-placement="top"
											title={
												timeArr?.length > 0
													? `Event Captured At: ${convertTimeStringToMinutesSeconds("00.00")} (${
															responses[0]
														})})`
													: ""
											}
										>
											00.00
										</span>
										<span style={{ marginLeft: "48px", paddingRight: "13px" }}>
											<svg
												data-v-42851c16=""
												data-v-08603a8b=""
												width="14"
												height="14"
												viewBox="0 0 16 16"
												xmlns="http://www.w3.org/2000/svg"
												fill="currentColor"
												className="base-icon block mt-1 w-32 mr-0"
												style={{ color: "rgb(0, 197, 122)" }}
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 108 0a8 8 0 000 16z"
												/>
												<path d="M5.5 10.41V5.59a.5.5 0 01.738-.44l4.45 2.41a.5.5 0 010 .88l-4.45 2.41a.5.5 0 01-.738-.44z" />
											</svg>
										</span>
										<span>----- Screen Recording Start -----</span>
									</td>
								</tr>
							)}
							{mouseEvents?.map((mouseEvent: any, index: any) => {
								let attributesLine: any = "";
								let BrokenContent;
								let FullContent;
								const hasHref = false;
								// const hrefValue = null;
								if (
									mouseEvent.event !== "URL" &&
									mouseEvent.event !== "RELOAD"
								) {
									if (mouseEvent?.attributes?.length >= 1) {
										attributesLine = extractAttributes(mouseEvent.element);
									}

									BrokenContent = TrimContent(mouseEvent?.innerText ? "" : "");

									FullContent = mouseEvent?.innerText ? "" : "";
								}

								return (
									<tr
										// key={index}
										style={
											{
												// textOverflow: "ellipsis",
												// overflow: "hidden"
											}
										}
									>
										{/* <td
                    style={{ width: 100 }}
                    className="table-td-font-style color-868E96"
                  >
                    {mouseEvent?.event}
                  </td> */}
										{mouseEvent.event === "RELOAD" && (
											<td className="text-code-sm" style={{ width: 100 }}>
												<span
													style={{ color: "#fff" }}
													data-toggle="tooltip"
													data-placement="top"
													title={
														timeArr?.length > 0
															? `Event Captured At: ${convertTimeStringToMinutesSeconds(
																	timeArr[index],
																)} (${responses[index]})`
															: ""
													}
												>
													{timeArr[index]}
												</span>
												<span
													style={{ marginLeft: "48px", paddingRight: "13px" }}
												>
													{" "}
													<svg
														data-v-42851c16=""
														data-v-08603a8b=""
														height="14"
														viewBox="0 0 16 17"
														width="14"
														xmlns="http://www.w3.org/2000/svg"
														fill="currentColor"
														className="base-icon block mt-1 w-32 mr-0"
														style={{ color: "rgb(11, 167, 255)" }}
													>
														<g clipRule="evenodd" fillRule="evenodd">
															<path d="M12.695 7.887a.75.75 0 10-1.449-.388l-.68 2.535-1.215-2.107a.75.75 0 10-1.3.75l1.967 3.406-.713 2.66a.75.75 0 101.449.388l.713-2.66 3.405-1.966a.75.75 0 00-.75-1.3l-2.106 1.217zM4.54 1.5a.75.75 0 00-1.448.39l.68 2.534-2.107-1.216a.75.75 0 00-.75 1.299L4.32 6.473l.713 2.66a.75.75 0 001.449-.388l-.713-2.66L7.735 2.68a.75.75 0 10-1.299-.75L5.22 4.036z" />
														</g>
													</svg>
												</span>

												<span>
													Reloaded{" "}
													{showFullRows[index] ? (
														<span>
															<span
																onClick={() => toggleShowFull(index)}
																style={{ marginRight: "5px" }}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="16"
																	height="16"
																	fill="currentColor"
																	className="bi bi-caret-down-fill"
																	viewBox="0 0 16 16"
																>
																	<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
																</svg>
															</span>
															<br />
															<div
																style={{
																	display: "inline-block",
																	marginLeft: "120px",
																}}
															>
																<a
																	style={{ textDecoration: "underline" }}
																	href={mouseEvent.full_url}
																>
																	{mouseEvent.full_url}
																</a>
															</div>
														</span>
													) : (
														<span>
															{mouseEvent?.full_url?.length > 50 && (
																<span
																	onClick={() => toggleShowFull(index)}
																	style={{ marginRight: "5px" }}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="16"
																		height="16"
																		fill="currentColor"
																		className="bi bi-caret-right-fill"
																		viewBox="0 0 16 16"
																	>
																		<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
																	</svg>
																</span>
															)}

															<a
																style={{ textDecoration: "underline" }}
																href={mouseEvent.full_url}
															>
																{mouseEvent?.full_url?.length > 50
																	? `${mouseEvent.full_url.slice(0, 45)}...`
																	: mouseEvent.full_url}
															</a>
														</span>
													)}
												</span>
											</td>
										)}
										{mouseEvent.event === "URL" && index !== 0 && (
											<td className="text-code-sm" style={{ width: 100 }}>
												<span
													style={{ color: "#fff" }}
													data-toggle="tooltip"
													data-placement="top"
													title={
														timeArr?.length > 0
															? `Event Captured At: ${convertTimeStringToMinutesSeconds(
																	timeArr[index],
																)} (${responses[index]})`
															: ""
													}
												>
													{timeArr[index]}
												</span>
												<span
													style={{ marginLeft: "48px", paddingRight: "13px" }}
												>
													{" "}
													<svg
														data-v-42851c16=""
														data-v-08603a8b=""
														height="14"
														viewBox="0 0 16 17"
														width="14"
														xmlns="http://www.w3.org/2000/svg"
														fill="currentColor"
														className="base-icon block mt-1 w-32 mr-0"
														style={{ color: "rgb(11, 167, 255)" }}
													>
														<g clipRule="evenodd" fillRule="evenodd">
															<path d="M12.695 7.887a.75.75 0 10-1.449-.388l-.68 2.535-1.215-2.107a.75.75 0 10-1.3.75l1.967 3.406-.713 2.66a.75.75 0 101.449.388l.713-2.66 3.405-1.966a.75.75 0 00-.75-1.3l-2.106 1.217zM4.54 1.5a.75.75 0 00-1.448.39l.68 2.534-2.107-1.216a.75.75 0 00-.75 1.299L4.32 6.473l.713 2.66a.75.75 0 001.449-.388l-.713-2.66L7.735 2.68a.75.75 0 10-1.299-.75L5.22 4.036z" />
														</g>
													</svg>
												</span>

												<span>
													Navigated to:{" "}
													{showFullRows[index] ? (
														<span>
															<span
																onClick={() => toggleShowFull(index)}
																style={{ marginRight: "5px" }}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="16"
																	height="16"
																	fill="currentColor"
																	className="bi bi-caret-down-fill"
																	viewBox="0 0 16 16"
																>
																	<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
																</svg>
															</span>
															<br />
															<div
																style={{
																	display: "inline-block",
																	marginLeft: "120px",
																	wordWrap: "break-word",
																	maxWidth: "390px",
																	whiteSpace: "pre-wrap",
																}}
															>
																<a
																	style={{ textDecoration: "underline" }}
																	href={mouseEvent.value}
																>
																	{mouseEvent.value}
																</a>
															</div>
														</span>
													) : (
														<span>
															{mouseEvent?.value?.length > 50 && (
																<span
																	onClick={() => toggleShowFull(index)}
																	style={{ marginRight: "5px" }}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="16"
																		height="16"
																		fill="currentColor"
																		className="bi bi-caret-right-fill"
																		viewBox="0 0 16 16"
																	>
																		<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
																	</svg>
																</span>
															)}

															<a
																style={{ textDecoration: "underline" }}
																href={mouseEvent.value}
															>
																{mouseEvent?.value?.length > 50
																	? `${mouseEvent.value.slice(0, 45)}...`
																	: mouseEvent.value}
															</a>
														</span>
													)}
												</span>
											</td>
										)}

										{mouseEvent?.event === "CLICK" && !hasHref && (
											<td
												className="table-td-font-style "
												style={{ width: 100 }}
											>
												<div
													className="text-code-sm"
													style={{ fontSize: "14px", lineHeight: "16px" }}
												>
													<span
														style={{
															marginRight:
																mouseEvent?.occurence > 1 ? "12px" : "0",
															color: "#fff",
														}}
														data-toggle="tooltip"
														data-placement="top"
														title={
															timeArr?.length > 0
																? `Event Captured At: ${convertTimeStringToMinutesSeconds(
																		timeArr[index],
																	)} (${responses[index]})`
																: ""
														}
													>
														{timeArr[index]}
													</span>
													{mouseEvent?.occurence > 1 && (
														<span
															style={{
																display: "inline-flex",
																alignItems: "center",
																justifyContent: "center",
																width: "20px",
																height: "20px",
																marginRight: "7px",
																background: "#796C3C",
																padding: "0", // Remove padding to ensure the circle shape
																borderRadius: "50%", // Use 50% for a perfect circle
																fontSize: "14px",
																color: "#fff",
																textAlign: "center",
																lineHeight: "20px", // Align text vertically
															}}
														>
															{mouseEvent?.occurence}
														</span>
													)}
													<span
														style={{
															marginLeft:
																mouseEvent?.occurence > 1 ? "0" : "38px",
															paddingLeft: "12px",
															paddingRight: "12px",
														}}
													>
														<svg
															data-v-42851c16=""
															data-v-08603a8b=""
															width="14"
															height="14"
															viewBox="0 0 14 14"
															xmlns="http://www.w3.org/2000/svg"
															fill="currentColor"
															className="base-icon block mt-1 w-32 mr-0"
															style={{ color: "rgb(140, 149, 168)" }}
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M2.34.48c0-.425.513-.64.815-.34l8.401 8.3a.383.383 0 01-.272.655l-2.771-.022 1.338 3.23a.383.383 0 01-.208.5l-2.817 1.168a.383.383 0 01-.5-.207l-1.42-3.428-1.91 1.798a.383.383 0 01-.646-.279L2.34.48zM5.33 8.36l1.764 4.257 1.402-.58L6.788 7.91l2.62.021L3.49 2.085l.007 8 1.834-1.726z"
															/>
														</svg>
													</span>
													<span>Clicked</span>{" "}
													{!showFullRows[index] &&
														mouseEvent?.innerText?.length > 50 && (
															<>
																{mouseEvent?.innerText?.length > 50 && (
																	<span
																		onClick={() => toggleShowFull(index)}
																		style={{ marginRight: "5px" }}
																	>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="16"
																			height="16"
																			fill="currentColor"
																			className="bi bi-caret-right-fill"
																			viewBox="0 0 16 16"
																		>
																			<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
																		</svg>
																	</span>
																)}

																{mouseEvent?.innerText?.length > 50 && (
																	<span>
																		"{mouseEvent.innerText.slice(0, 45)}
																		..."{" "}
																	</span>
																)}
																<br />

																{"  "}
																{mouseEvent?.innerText?.length > 50 && (
																	<div
																		style={{
																			marginLeft: "190px",
																			wordWrap: "break-word",
																			maxWidth: "390px",
																			whiteSpace: "pre-wrap",
																		}}
																	>
																		<span style={{ color: "rgb(39, 174, 96)" }}>
																			{mouseEvent.localName}
																		</span>
																		{"  "}
																		<span>{attributesLine}</span>
																	</div>
																)}
															</>
														)}
													{mouseEvent?.innerText?.length <= 50 &&
														mouseEvent?.innerText?.length > 1 && (
															<>
																<span>"{mouseEvent.innerText}"</span>

																{"  "}
																<span style={{ color: "rgb(39, 174, 96)" }}>
																	{mouseEvent.localName}
																</span>
																{"  "}
																<span>{attributesLine}</span>
															</>
														)}
													{(mouseEvent?.innerText === undefined ||
														mouseEvent?.innerText?.length < 1) && (
														<>
															{"  "}
															<span style={{ color: "rgb(39, 174, 96)" }}>
																{mouseEvent.localName}
															</span>
															{"  "}
															<span>{attributesLine}</span>
														</>
													)}
													{showFullRows[index] &&
														mouseEvent?.innerText?.length > 50 && (
															<>
																<span
																	onClick={() => toggleShowFull(index)}
																	style={{ marginRight: "5px" }}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="16"
																		height="16"
																		fill="currentColor"
																		className="bi bi-caret-down-fill"
																		viewBox="0 0 16 16"
																	>
																		<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
																	</svg>
																</span>

																<span
																	className="mb-2 mt-2"
																	style={{
																		// whiteSpace: 'pre-line!important',
																		display: "block",
																		marginLeft: "110px",
																		wordWrap: "break-word",
																		maxWidth: "420px",
																		whiteSpace: "pre-wrap",
																	}}
																>
																	"{mouseEvent.innerText}"
																</span>
																<br />

																{"  "}
																<div>
																	<span
																		style={{
																			color: "#8adc89",
																			marginLeft: "110px",
																		}}
																	>
																		{mouseEvent.localName}
																	</span>
																	{"  "}
																	<span style={{ marginLeft: "10px" }}>
																		{attributesLine}
																	</span>
																</div>
															</>
														)}
												</div>
											</td>
										)}

										{mouseEvent?.event === "INPUT" &&
											mouseEvent.localName !== "a" &&
											!hasHref && (
												<td
													className="table-td-font-style "
													style={{ width: 100 }}
												>
													<div
														className="text-code-sm"
														style={{ fontSize: "14px", lineHeight: "16px" }}
													>
														<span
															style={{
																marginRight:
																	mouseEvent?.occurence > 1 ? "12px" : "0",
																color: "#fff",
															}}
															data-toggle="tooltip"
															data-placement="top"
															title={
																timeArr?.length > 0
																	? `Event Captured At: ${convertTimeStringToMinutesSeconds(
																			timeArr[index],
																		)} (${responses[index]})`
																	: ""
															}
														>
															{timeArr[index]}
														</span>
														<span style={{ marginLeft: "50px" }}>
															{mouseEvents[index]?.element?.includes(
																"<select",
															) ? (
																<svg
																	data-v-42851c16=""
																	data-v-08603a8b=""
																	width="14"
																	height="14"
																	viewBox="0 0 14 14"
																	xmlns="http://www.w3.org/2000/svg"
																	fill="currentColor"
																	className="base-icon block mt-1 w-32 mr-0"
																	style={{ color: "rgb(140, 149, 168)" }}
																>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M2.34.48c0-.425.513-.64.815-.34l8.401 8.3a.383.383 0 01-.272.655l-2.771-.022 1.338 3.23a.383.383 0 01-.208.5l-2.817 1.168a.383.383 0 01-.5-.207l-1.42-3.428-1.91 1.798a.383.383 0 01-.646-.279L2.34.48zM5.33 8.36l1.764 4.257 1.402-.58L6.788 7.91l2.62.021L3.49 2.085l.007 8 1.834-1.726z"
																	/>
																</svg>
															) : (
																<svg
																	data-v-42851c16=""
																	data-v-08603a8b=""
																	width="14"
																	height="14"
																	viewBox="0 0 14 14"
																	xmlns="http://www.w3.org/2000/svg"
																	fill="currentColor"
																	className="base-icon block mt-1 w-32 mr-0"
																	style={{ color: "rgb(140, 149, 168)" }}
																>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M11 1.3H3A1.7 1.7 0 001.3 3v8A1.7 1.7 0 003 12.7h8a1.7 1.7 0 001.7-1.7V3A1.7 1.7 0 0011 1.3zM3 0a3 3 0 00-3 3v8a3 3 0 003 3h8a3 3 0 003-3V3a3 3 0 00-3-3H3z"
																	/>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M9.376 3.6a.65.65 0 00-.65.65v3a.35.35 0 01-.35.35H5.945l.39-.39a.65.65 0 00-.919-.92L4.128 7.578a.95.95 0 000 1.344l1.288 1.288a.65.65 0 10.92-.92l-.391-.39h2.43a1.65 1.65 0 001.65-1.65v-3a.65.65 0 00-.65-.65z"
																	/>
																</svg>
															)}
														</span>
														{mouseEvents[index]?.element?.includes(
															"<select",
														) ? (
															<span style={{ marginLeft: "10px" }}>
																Selected :
															</span>
														) : (
															<span style={{ marginLeft: "10px" }}>
																Typed :
															</span>
														)}
														<span style={{ marginLeft: "5px" }}>
															{mouseEvent?.value}
														</span>
														<span
															style={{
																color: "rgb(39, 174, 96)",
																marginLeft: "8px",
															}}
														>
															{mouseEvent.localName}
														</span>
														{"  "}
														<span>{attributesLine}</span>
													</div>
												</td>
											)}
									</tr>
								);
							})}
							{metaResponse.screenshot !== null &&
								metaResponse.video === null && (
									<tr>
										<td className="text-code-sm">
											<span
												style={{ color: "#fff" }}
												data-toggle="tooltip"
												data-placement="top"
												title={
													timeArr?.length > 0
														? `Event Captured At: ${convertTimeStringToMinutesSeconds(endTime)} (${
																responses[mouseEvents?.length - 1]
															})})`
														: ""
												}
											>
												{endTime}
											</span>
											<span
												style={{ marginLeft: "48px", paddingRight: "13px" }}
											>
												<svg
													data-v-42851c16=""
													data-v-08603a8b=""
													width="14"
													height="14"
													viewBox="0 0 16 16"
													xmlns="http://www.w3.org/2000/svg"
													fill="currentColor"
													className="base-icon block mt-1 w-32 mr-0"
													style={{ color: "rgb(0, 197, 122)" }}
												>
													<circle cx="8" cy="8" r="3" />
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M2.75 1.5c-.69 0-1.25.56-1.25 1.25v2.5a.75.75 0 01-1.5 0v-2.5A2.75 2.75 0 012.75 0h2.5a.75.75 0 010 1.5h-2.5zM14.5 2.75c0-.69-.56-1.25-1.25-1.25h-2.5a.75.75 0 010-1.5h2.5A2.75 2.75 0 0116 2.75v2.5a.75.75 0 01-1.5 0v-2.5zM13.25 14.5c.69 0 1.25-.56 1.25-1.25v-2.5a.75.75 0 011.5 0v2.5A2.75 2.75 0 0113.25 16h-2.5a.75.75 0 010-1.5h2.5zM1.5 13.25c0 .69.56 1.25 1.25 1.25h2.5a.75.75 0 010 1.5h-2.5A2.75 2.75 0 010 13.25v-2.5a.75.75 0 011.5 0v2.5z"
													/>
												</svg>
											</span>
											<span>Screenshot Taken</span>
										</td>
									</tr>
								)}
							{metaResponse.video !== null && (
								<tr>
									<td className="text-code-sm">
										<span
											style={{ color: "#fff" }}
											data-toggle="tooltip"
											data-placement="top"
											title={
												timeArr?.length > 0
													? `Event Captured At: ${convertTimeStringToMinutesSeconds(endTime)} (${
															responses[mouseEvents?.length - 1]
														})})`
													: ""
											}
										>
											{endTime}
										</span>
										<span style={{ marginLeft: "48px", paddingRight: "13px" }}>
											<svg
												data-v-42851c16=""
												data-v-08603a8b=""
												width="14"
												height="14"
												viewBox="0 0 16 16"
												xmlns="http://www.w3.org/2000/svg"
												fill="currentColor"
												className="base-icon block mt-1 w-32 mr-0"
												style={{ color: "rgb(0, 197, 122)" }}
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 108 0a8 8 0 000 16z"
												/>
												<path d="M5.5 10.41V5.59a.5.5 0 01.738-.44l4.45 2.41a.5.5 0 010 .88l-4.45 2.41a.5.5 0 01-.738-.44z" />
											</svg>
										</span>
										<span>----- Screen Recording End -----</span>
									</td>
								</tr>
							)}
							<tr>
								<td className="text-code-sm">
									<span
										style={{ color: "#fff" }}
										data-toggle="tooltip"
										data-placement="top"
										title={
											timeArr.length > 0
												? `Event Captured At: ${convertTimeStringToMinutesSeconds(endTime)} (${
														responses[mouseEvents.length - 1]
													})`
												: ""
										}
									>
										{endTime}
									</span>
									<span style={{ marginLeft: "48px", paddingRight: "13px" }}>
										{" "}
										<svg
											data-v-42851c16=""
											data-v-08603a8b=""
											height="14"
											viewBox="0 0 16 17"
											width="14"
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											className="base-icon block mt-1 w-32 mr-0"
											style={{ color: "rgb(11, 167, 255)" }}
										>
											<g clipRule="evenodd" fillRule="evenodd">
												<path d="M12.695 7.887a.75.75 0 10-1.449-.388l-.68 2.535-1.215-2.107a.75.75 0 10-1.3.75l1.967 3.406-.713 2.66a.75.75 0 101.449.388l.713-2.66 3.405-1.966a.75.75 0 00-.75-1.3l-2.106 1.217zM4.54 1.5a.75.75 0 00-1.448.39l.68 2.534-2.107-1.216a.75.75 0 00-.75 1.299L4.32 6.473l.713 2.66a.75.75 0 001.449-.388l-.713-2.66L7.735 2.68a.75.75 0 10-1.299-.75L5.22 4.036z" />
											</g>
										</svg>
									</span>
									<span>
										Session Ended on{" "}
										{full ? (
											<span>
												<span
													onClick={() => setfull(false)}
													style={{ marginRight: "5px" }}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														className="bi bi-caret-down-fill"
														viewBox="0 0 16 16"
													>
														<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
													</svg>
												</span>
												<br />
												<div
													style={{
														display: "inline-block",
														marginLeft: "120px",
														wordWrap: "break-word",
														maxWidth: "390px",
														whiteSpace: "pre-wrap",
													}}
												>
													<a
														style={{ textDecoration: "underline" }}
														href={targetHtml}
													>
														{targetHtml}
													</a>
												</div>
											</span>
										) : (
											<span>
												<span
													onClick={() => setfull(true)}
													style={{
														marginRight: "5px",
														display: targetHtml?.length > 50 ? "" : "none",
													}}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														className="bi bi-caret-right-fill"
														viewBox="0 0 16 16"
													>
														<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
													</svg>
												</span>

												<a
													style={{
														textDecoration: "underline",
													}}
													href={targetHtml}
												>
													{targetHtml?.length > 50
														? `${targetHtml.slice(0, 45)}...`
														: targetHtml}
												</a>
											</span>
										)}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
					// </div>
				)}
			</div>
		</div>
	);
}

export default EventsTab;
