import "./testScript.scss";

import { FaCopy } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { HiDownload } from "react-icons/hi";
import Select from "react-select";
import { Tooltip } from "bootstrap";
import { createTheme } from "@uiw/codemirror-themes";
import { javascript } from "@codemirror/lang-javascript";
import { saveAs } from "file-saver";
import { tags as t } from "@lezer/highlight";
import { toast } from "react-toastify";

import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
	asyncCypressScriptGeneration,
	asyncPlaywrightCSScriptGeneration,
	asyncPlayWrightJavaScriptGeneration,
	asyncPlayWrightPYScriptGeneration,
	asyncPuppeteerScriptGeneration,
	asyncSeleniumJavaScriptGeneration,
	attributesArrayFilter,
	seleniumCSScriptGeneration,
	seleniumPYScriptGeneration,
} from "../../../utils/testscripts";
import Loader from "../../../components/Loader";

function TestScriptTab(props: any) {
	const {
		mouseEvents,
		playwrightJavaCode,
		playwrightCSCode,
		playwrightPyCode,
		seleniumJavaCode,
		seleniumCSCode,
		seleniumPyCode,
		cypressCode,
		puppeteerCode,
		setPlaywrightJavaCode,
		setPlaywrightCSCode,
		setPlaywrightPyCode,
		setSeleniumJavaCode,
		setSeleniumCSCode,
		setSeleniumPyCode,
		setCypressCode,
		setPuppeteerCode,
		bug,
	} = props;
	// const [code, setCode] = useState("");
	// cypress code
	// const [data, setData] = useState("");
	const [maxHeight, setMaxHeight] = useState("100%");
	const [displayGenerate, setDisplayGenerate] = useState(true);
	useEffect(() => {
		const handleResize = () => {
			setMaxHeight(`${window.innerHeight / 2 - 50}px`);
		};

		window.addEventListener("resize", handleResize);
		handleResize(); // Call initially to set the value

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	const [displayedData, setDisplayedData] = useState("");
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;
	// const [isTyping, setIsTyping] = useState(false);
	const options = [
		{ value: "selenium", label: "Selenium" },
		{ value: "cypress", label: "Cypress" },

		{ value: "playwright", label: "Playwright" },

		{ value: "puppeteer", label: "Puppeteer" },
	];
	const languageOptions = [
		{ value: "java", label: "Java" },
		{ value: "c#", label: "C#" },
		{ value: "python", label: "Python" },
	];
	// const intervalRef = useRef(null);
	const scripts: any = {};
	const urlArray: any = [];

	const [selectedOption, setSelectedOption] = useState(options[0]);
	const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);
	const [loader, setLoader] = useState("");

	useEffect(() => {
		if (bug?.selectedLanguage && bug?.selectedTool) {
			const newSelectedOption = options.find(
				(option) => option.value === bug.selectedTool.toLowerCase(),
			);
			const newSelectedLanguage = languageOptions.find(
				(language) => language.value === bug.selectedLanguage.toLowerCase(),
			);

			if (newSelectedOption) {
				setSelectedOption(newSelectedOption);
			}

			if (newSelectedLanguage) {
				setSelectedLanguage(newSelectedLanguage);
			}
		}
	}, [bug]);
	useEffect(() => {
		const hasValidMouseEvents =
			(mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
			(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL");

		if (selectedOption.value === "cypress" && hasValidMouseEvents) {
			if (cypressCode !== null) {
				setDisplayedData(cypressCode);
			} else {
				setDisplayedData("Your code will generate here");
			}
		} else if (
			selectedOption.value === "playwright" &&
			selectedLanguage.value === "java" &&
			hasValidMouseEvents
		) {
			if (playwrightJavaCode !== null) {
				setDisplayedData(playwrightJavaCode);
			} else {
				setDisplayedData("Your code will generate here");
			}
		} else if (
			selectedOption.value === "playwright" &&
			selectedLanguage.value === "c#" &&
			hasValidMouseEvents
		) {
			if (playwrightCSCode !== null) {
				setDisplayedData(playwrightCSCode);
			} else {
				setDisplayedData("Your code will generate here");
			}
		} else if (
			selectedOption.value === "playwright" &&
			selectedLanguage.value === "python" &&
			hasValidMouseEvents
		) {
			if (playwrightPyCode !== null) {
				setDisplayedData(playwrightPyCode);
			} else {
				setDisplayedData("Your code will generate here");
			}
		} else if (
			selectedOption.value === "selenium" &&
			selectedLanguage.value === "java" &&
			hasValidMouseEvents
		) {
			if (seleniumJavaCode !== null) {
				setDisplayedData(seleniumJavaCode);
			} else {
				setDisplayedData("Your code will generate here");
			}
		} else if (
			selectedOption.value === "selenium" &&
			selectedLanguage.value === "c#" &&
			hasValidMouseEvents
		) {
			if (seleniumCSCode !== null) {
				setDisplayedData(seleniumCSCode);
			} else {
				setDisplayedData("Your code will generate here");
			}
		} else if (
			selectedOption.value === "selenium" &&
			selectedLanguage.value === "python" &&
			hasValidMouseEvents
		) {
			if (seleniumPyCode !== null) {
				setDisplayedData(seleniumPyCode);
			} else {
				setDisplayedData("Your code will generate here");
			}
		} else if (selectedOption.value === "puppeteer" && hasValidMouseEvents) {
			if (puppeteerCode !== null) {
				setDisplayedData(puppeteerCode);
			} else {
				setDisplayedData("Your code will generate here");
			}
		} else {
			setDisplayGenerate(false);
			setDisplayedData("There is no event to generate Test Scripts...");
		}
	}, [
		selectedOption,
		mouseEvents,
		selectedLanguage,
		playwrightJavaCode,
		playwrightCSCode,
		playwrightPyCode,
		seleniumJavaCode,
		seleniumCSCode,
		seleniumPyCode,
		cypressCode,
		puppeteerCode,
	]);

	const handleGenerateScript = async () => {
		console.log("hit");
		const stepsArray = mouseEvents.map((event: any, index: any) => {
			let sentence = "";
			let attributesLine;
			if (event.event !== "URL" && event.event !== "RELOAD") {
				attributesLine = attributesArrayFilter(event.attributes);
			}
			if (event.event === "RELOAD") {
				sentence += `${index + 1}. Reloaded the existing page`;
			} else if (event.event === "URL") {
				urlArray.push(event.value);
				sentence += `${index + 1}. Navigated to url${index + 1}`;
			} else if (event.event === "INPUT") {
				sentence += `${index + 1}. Typed : "${event.value}" on ${
					event.localName
				} ${attributesLine} `;
			} else if (event.event === "CLICK" && event?.innerText) {
				sentence += `${index + 1}. Clicked "${event.innerText}" ${attributesLine} `;
			}
			return sentence;
		});

		const steps = stepsArray.join("/n");

		if (
			selectedOption.value === "cypress" &&
			((mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
				(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL"))
		) {
			const response = await asyncCypressScriptGeneration(steps, urlArray);
			setCypressCode(response);
			scripts.cypressCode = response;

			sessionStorage.setItem("cypress", response);
			setDisplayedData(response);
		} else if (
			selectedOption.value === "playwright" &&
			selectedLanguage.value === "java" &&
			((mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
				(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL"))
		) {
			const response = await asyncPlayWrightJavaScriptGeneration(
				steps,
				urlArray,
			);
			setPlaywrightJavaCode(response);
			scripts.playwrightJavaCode = response;
			sessionStorage.setItem("playwrightJava", response);
			setDisplayedData(response);
		} else if (
			selectedOption.value === "playwright" &&
			selectedLanguage.value === "c#" &&
			((mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
				(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL"))
		) {
			const response = await asyncPlaywrightCSScriptGeneration(steps, urlArray);
			setPlaywrightCSCode(response);
			scripts.playwrightCSCode = response;
			sessionStorage.setItem("playwrightCS", response);
			setDisplayedData(response);
		} else if (
			selectedOption.value === "playwright" &&
			selectedLanguage.value === "python" &&
			((mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
				(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL"))
		) {
			const response = await asyncPlayWrightPYScriptGeneration(steps, urlArray);
			setPlaywrightPyCode(response);

			scripts.playwrightPyCode = response;

			sessionStorage.setItem("playwrightPy", response);
			setDisplayedData(response);
		} else if (
			selectedOption.value === "selenium" &&
			selectedLanguage.value === "java" &&
			((mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
				(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL"))
		) {
			const response = await asyncSeleniumJavaScriptGeneration(steps, urlArray);
			console.log({ response });
			setSeleniumJavaCode(response);
			scripts.seleniumJavaCode = response;
			sessionStorage.setItem("seleniumJava", response);
			setDisplayedData(response);
		} else if (
			selectedOption.value === "selenium" &&
			selectedLanguage.value === "c#" &&
			((mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
				(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL"))
		) {
			const response = await seleniumCSScriptGeneration(steps, urlArray);
			setSeleniumCSCode(response);
			scripts.seleniumCSCode = response;
			sessionStorage.setItem("seleniumCS", response);
			setDisplayedData(response);
		} else if (
			selectedOption.value === "selenium" &&
			selectedLanguage.value === "python" &&
			((mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
				(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL"))
		) {
			const response = await seleniumPYScriptGeneration(steps, urlArray);
			setSeleniumPyCode(response);
			scripts.seleniumPyCode = response;
			sessionStorage.setItem("seleniumPy", response);
			setDisplayedData(response);
		} else if (
			selectedOption.value === "puppeteer" &&
			((mouseEvents?.length > 1 && mouseEvents[0]?.event === "URL") ||
				(mouseEvents?.length > 0 && mouseEvents[0]?.event !== "URL"))
		) {
			const response = await asyncPuppeteerScriptGeneration(steps, urlArray);
			setPuppeteerCode(response);
			scripts.puppeteerCode = response;
			sessionStorage.setItem("puppeteer", response);
			setDisplayedData(response);
		} else {
			setDisplayedData("There is no event to generate Test Scripts...");
		}
		console.log({ scripts });

		setLoader("");
	};

	useEffect(() => {
		// Initialize tooltips when the component mounts
		const tooltipTriggerList = [].slice.call(
			// eslint-disable-next-line quotes
			document.querySelectorAll('[data-toggle="tooltip"]'),
		);
		tooltipTriggerList.map((tooltipTriggerEl) => {
			return new Tooltip(tooltipTriggerEl);
		});
	}, []);

	const handleCopyCode = () => {
		navigator.clipboard
			.writeText(displayedData)
			.then(() => {
				toast.success("Script copied to clipboard!");
			})
			.catch(() => {
				toast.error("Failed to copy script");
			});
	};

	const handleDownload = () => {
		// Create a Blob with the code content
		const blob = new Blob([displayedData], {
			type: "text/plain;charset=utf-8",
		});

		// Save the file using FileSaver.js
		if (selectedLanguage.value === "java")
			saveAs(blob, `javaCode.${selectedLanguage.value}`);
		else if (selectedLanguage.value === "python") saveAs(blob, "PythonCode.py");
		else if (selectedLanguage.value === "javascript")
			saveAs(blob, "JavascriptCode.js");
		else saveAs(blob, "CsharpCode.cs");

		toast.success("Downloaded successfully!");
	};

	const selectStyles = {
		menuList: (provided: any) => ({
			...provided,
			// padding: 0, // Remove padding to get rid of the small strip
			backgroundColor: darkMode ? "#1C2A4C" : "#F7F6FB", // Set the background color
		}),
		singleValue: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
		}),
		control: (provided: any) => ({
			...provided,
			backgroundColor: darkMode ? "#1C2A4C" : "#F7F6FB",
			color: "#356EFF",
			width: "130px",
		}),
		option: (provided: any, state: any) => ({
			...provided,
			// eslint-disable-next-line no-nested-ternary
			backgroundColor: state.isHovered
				? "#7EA2FE"
				: // eslint-disable-next-line no-nested-ternary
					state.isSelected
					? "#356EFF"
					: darkMode
						? "#1C2A4C"
						: "#F7F6FB",
			color:
				state.isHovered || state.isSelected || darkMode ? "white" : "black",

			textAlign: "left",
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
		}),
	};

	const handleChange = (selectedOp: any) => {
		if (selectedOp.value === "puppeteer" || selectedOp.value === "cypress") {
			setSelectedLanguage({ value: "javascript", label: "JavaScript" });
		}
		if (
			selectedOp.value !== "puppeteer" &&
			selectedOp.value !== "cypress" &&
			selectedLanguage.value === "javascript"
		) {
			setSelectedLanguage(languageOptions[0]);
		}
		setSelectedOption(selectedOp);
	};

	const handleChangeLanguage = (selectedLang: any) => {
		setSelectedLanguage(selectedLang);
	};

	const myTheme = createTheme({
		theme: "light",
		settings: {
			background: "#ebf1fa",
			foreground: "rgb(228,85,73)",
			caret: "#5d00ff",
			selection: "#036dd626",
			selectionMatch: "#036dd626",
			lineHighlight: "#8a91991a",
			gutterBackground: "#fff",
			gutterForeground: "#8a919966",
		},
		styles: [
			{ tag: t.comment, color: "#787b8099" },
			{ tag: t.variableName, color: "#0080ff" },
			{ tag: [t.string, t.special(t.brace)], color: "rgb(80,161,79)" },
			{ tag: t.number, color: "#0080ff" },
			{ tag: t.bool, color: "rgb(80,161,79)" },
			{ tag: t.null, color: "rgb(80,161,79)" },
			{ tag: t.keyword, color: "rgb(80,161,79)" },
			{ tag: t.operator, color: "rgb(80,161,79)" },
			{ tag: t.className, color: "rgb(80,161,79)" },
			{ tag: t.definition(t.typeName), color: "rgb(80,161,79)" },
			{ tag: t.typeName, color: "rgb(80,161,79)" },
			{ tag: t.angleBracket, color: "rgb(80,161,79)" },
			{ tag: t.tagName, color: "rgb(80,161,79)" },
			{ tag: t.attributeName, color: "rgb(80,161,79)" },
		],
	});
	const customInviteMemberSelectStyle = {
		menuList: (provided: any) => ({
			...provided,
			// padding: 0, // Remove padding to get rid of the small strip
			backgroundColor: darkMode ? "#1C2A4C" : "#424242", // Set the background color
		}),
		menu: (provided: any) => ({
			...provided,
			width: 160, // Set the same width as the control
		}),
		control: (provided: any, state: any) => ({
			...provided,
			color: state.isFocused ? "#fff" : "#fff",
			minHeight: "40px",
			maxHeight: "40px",
			textAlign: "left",
			marginBottom: "10px",
			backgroundColor: darkMode ? "#1C2A4C" : "#424242",
			border: "0.5px solid transparent",
			borderImageSource: "linear-gradient(90deg, #D5BD6A 0%, #6F6237 100%)",
			borderImageSlice: 1,
			borderRadius: "6px",
			width: 160,
			outline: "none",
		}),
		option: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isHovered
				? "#424242"
				: state.isSelected
					? "#796c3c"
					: darkMode
						? "#1C2A4C"
						: "#424242",
			color:
				state.isHovered || state.isSelected || darkMode ? "white" : "white",
			minHeight: "32px",
			maxHeight: "32px",
			textAlign: "left",
		}),
		singleValue: (provided: any) => ({
			...provided,
			color: "#fff",
			textAlign: "left",
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: "#fff",
			textAlign: "left",
		}),
	};

	return (
		<div
			className="tab-pane active show"
			style={{
				maxHeight,
				minHeight: maxHeight,
				overflow: "scroll",
				color: "#fff",
			}}
		>
			{displayGenerate && (
				<div style={{ paddingLeft: "16px", paddingTop: "12px" }}>
					<p>
						We can generate End to End Testscript for the steps you have
						performed
					</p>
				</div>
			)}
			{displayGenerate && (
				<div
					className="d-flex"
					style={{
						width: "100%",
						justifyContent: "space-between",
						background: "#424242",
						paddingLeft: "20px",
						paddingRight: "20px",
						alignItems: "center",
					}}
				>
					<div style={{ display: "flex", columnGap: "40px", height: "64px" }}>
						<div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
							<div style={{ marginBottom: "1rem" }}>Select tool</div>
							<div>
								<Select
									options={options}
									value={selectedOption}
									onChange={handleChange}
									styles={customInviteMemberSelectStyle}
									components={{
										IndicatorSeparator: () => null,
									}}
								/>
							</div>
						</div>
						<div
							className="d-flex"
							style={{ display: "flex", gap: "12px", alignItems: "center" }}
						>
							<div style={{ marginBottom: "1rem" }}>Select language</div>
							<div>
								<Select
									options={languageOptions}
									value={selectedLanguage}
									onChange={handleChangeLanguage}
									styles={customInviteMemberSelectStyle}
									isDisabled={
										selectedOption.value === "puppeteer" ||
										selectedOption.value === "cypress"
									}
									components={{
										IndicatorSeparator: () => null,
									}}
								/>
							</div>
						</div>
					</div>
					<div
						id="generatediivide"
						style={{ display: "flex", columnGap: "16px", alignItems: "center" }}
					>
						{displayedData !== "Your code will generate here" &&
							displayedData !==
								"There is no event to generate Test Scripts..." && (
								<div
									className="d-flex "
									style={{
										marginLeft: "180px",
										// width: '300px',
										justifyContent: "flex-end",
										cursor: "pointer",
									}}
								>
									<div
										style={{
											display: displayedData.length <= 83 ? "none" : "block",
										}}
										data-toggle="tooltip"
										data-placement="top"
										title="Copy"
										onClick={handleCopyCode}
									>
										<svg
											width="28"
											height="32"
											viewBox="0 0 28 32"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect
												x="0.5"
												y="0.5"
												width="27"
												height="31"
												rx="3.5"
												stroke="#E3E3E3"
											/>
											<path
												d="M9 23L17.375 23C17.4437 23 17.5 22.9438 17.5 22.875L17.5 22C17.5 21.9313 17.4437 21.875 17.375 21.875L9.625 21.875L9.625 11.125C9.625 11.0563 9.56875 11 9.5 11L8.625 11C8.55625 11 8.5 11.0563 8.5 11.125L8.5 22.5C8.5 22.7766 8.72344 23 9 23ZM11 21L19 21C19.2766 21 19.5 20.7766 19.5 20.5L19.5 12.2078C19.5 12.075 19.4469 11.9484 19.3531 11.8547L16.6453 9.14688C16.6109 9.1125 16.5719 9.08438 16.5297 9.06094L16.5297 9.03125L16.4641 9.03125C16.4094 9.01094 16.3516 9 16.2922 9L11 9C10.7234 9 10.5 9.22344 10.5 9.5L10.5 20.5C10.5 20.7766 10.7234 21 11 21ZM16.0312 10L16.0344 10L18.375 12.3406L18.375 12.3438L16.0312 12.3438L16.0312 10Z"
												fill="#E3E3E3"
											/>
										</svg>
									</div>
									<div
										style={{
											marginLeft: "16px",
											display: displayedData.length <= 83 ? "none" : "block",
										}}
										data-toggle="tooltip"
										data-placement="top"
										title="Download"
										onClick={handleDownload}
									>
										<svg
											width="28"
											height="32"
											viewBox="0 0 28 32"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect
												x="0.5"
												y="0.5"
												width="27"
												height="31"
												rx="3.5"
												stroke="#E3E3E3"
											/>
											<path
												d="M14 18.75L10.875 14.6875L11.75 13.5094L13.375 15.6219V9H14.625V15.6219L16.25 13.5094L17.125 14.6875L14 18.75ZM9 22V17.9375H10.25V20.375H17.75V17.9375H19V22H9Z"
												fill="#E3E3E3"
											/>
										</svg>
									</div>
								</div>
							)}

						<div style={{ display: "flex" }}>
							<button
								className="copyLink d-flex justify-content-center align-items-center"
								style={{
									border: "none",
									width: "fit-content",
									padding: "16px",
								}}
								onClick={async () => {
									handleGenerateScript();
									setLoader("Generate");
								}}
								// disabled={
								//   loader !== '' || displayedData === 'There is no event to generate Test Scripts...'
								// }
							>
								<div className="copyLink-text">Generate</div>
								{loader === "Generate" && <Loader />}
							</button>
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					maxWidth: "100%",
					minWidth: "100%",
					maxHeight: "calc(100% - 121px)",
					minHeight: "calc(100% - 121px)",
					padding: "8px",
					paddingLeft: "16px",

					overflow: "auto",
					marginTop: "16px",
				}}
			>
				<div className="root-wrapper">
					{displayedData === "Your code will generate here" ||
					displayedData === "There is no event to generate Test Scripts..." ? (
						<div>{displayedData}</div>
					) : (
						<CodeMirror
							value={displayedData}
							theme={myTheme}
							extensions={[javascript({ jsx: true })]}
							editable={false}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
export default TestScriptTab;
