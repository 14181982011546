import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

// type GetMembers = {
// 	workspaceId: string;
// 	skip?: number;
// 	limit?: number;
// 	role?: string;
// 	count?: boolean;
// 	reporter?: string;
// };

export const memberApi = createApi({
	reducerPath: "memberAPI",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getMembers: builder.query<any, any>({
			query: (data: any) => ({
				url: `/workspace/member/${data?.workspaceId}`,
				params: {
					...(data?.skip && { skip: data?.skip }),
					...(data?.limit && { limit: data?.limit }),
					...(data?.role && { role: data?.role }),
					count: data?.count,
					...(data?.reporter && { reporter: data?.reporter }),
					...(data?.title && { title: data?.title }),
				},
			}),
		}),
		getMemberInfo: builder.query<any, any>({
			query: (data: any) => ({
				url: `/workspace/member/info/${data?.workspaceId}`,
			}),
		}),
		updateMember: builder.mutation<any, any>({
			query: (id?: string) => {
				return {
					url: `/workspace${id ? `/${id}` : ""}`,
					method: "GET",
					headers: { "Content-Type": "application/json" },
				};
			},
		}),
	}),
});

export const {
	useUpdateMemberMutation,
	useGetMemberInfoQuery,
	useGetMembersQuery,
} = memberApi;
