import { IThemeState } from "../shared/features/themeSlice";
import {
	AZURE_INTEGRATION_URL,
	CLICKUP_INTEGRATION_URL,
	SLACK_CLIENT_ID,
	REACT_APP_GOOGLE_SSO_REDIRECT_URL,
	REACT_APP_GOOGLE_SSO_CLIENT_ID,
	SLACK_REDIRECT_URI,
	REACT_APP_JIRA_CLIENT_ID,
	REACT_APP_JIRA_REDIRECT_URI,
	GITHUB_INTEGRATION_URL,
	GITLAB_INTEGRATION_URL,
} from "../config/environment";

const connectWithPmt = (pmt: string, workspaceId: string) => {
	const width = 800;
	const height = 600;
	const options = `width=${width},height=${height},resizable=yes,scrollbars=yes,status=yes`;
	let newWindow;
	switch (pmt) {
		case "Jira":
			newWindow = window.open(
				`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${REACT_APP_JIRA_CLIENT_ID}&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work%20offline_access&redirect_uri=${REACT_APP_JIRA_REDIRECT_URI}&state=${workspaceId}&response_type=code&prompt=consent`,
				"_blank",
				options,
			);
			break;
		case "Slack":
			newWindow = window.open(
				`https://slack.com/oauth/v2/authorize?scope=links%3Aread%2Clinks%3Awrite%2Cchannels%3Aread%2Cgroups%3Aread%2Cchat%3Awrite.public%2Cchat%3Awrite%2Cteam%3Aread&state=${workspaceId}&client_id=${SLACK_CLIENT_ID}&redirect_uri=${SLACK_REDIRECT_URI}&user_scope=channels%3Aread%2Cgroups%3Aread%2Cteam%3Aread`,
				"_blank",
				options,
			);
			break;
		case "Azure DevOps":
			newWindow = window.open(
				`${AZURE_INTEGRATION_URL}&state=${workspaceId}`,
				"_blank",
				options,
			);
			break;
		case "Clickup":
			newWindow = window.open(
				`${CLICKUP_INTEGRATION_URL}&state=${workspaceId}`,
				"_blank",
				options,
			);
			break;

		case "Github":
			newWindow = window.open(
				`${GITHUB_INTEGRATION_URL}&state=${workspaceId}`,
				"_blank",
				options,
			);
			break;
		case "GitLab":
			newWindow = window.open(
				`${GITLAB_INTEGRATION_URL}&state=${workspaceId}`,
				"_blank",
				options,
			);
			break;

		default:
			break;
	}
	return newWindow;
};
const flipImage = (target: HTMLElement) => {
	const imageElement = target.querySelector("img");

	if (imageElement) {
		if (imageElement.style.transform === "scaleY(-1)") {
			imageElement.style.transform = "scaleY(1)";
		} else {
			imageElement.style.transform = "scaleY(-1)";
		}
	}
};
export function getGoogleOAuthURL(extensionId: any) {
	const rooturl = "https://accounts.google.com/o/oauth2/v2/auth";

	const options = {
		redirect_uri: `${REACT_APP_GOOGLE_SSO_REDIRECT_URL}`,
		client_id: `${REACT_APP_GOOGLE_SSO_CLIENT_ID}`,
		access_type: "offline",
		response_type: "code",
		scope: [
			"https://www.googleapis.com/auth/userinfo.profile",
			"https://www.googleapis.com/auth/userinfo.email",
		].join(" "),
		...(extensionId && extensionId !== undefined && { state: extensionId }),
	};
	const qs = new URLSearchParams(options);
	return `${rooturl}?${qs.toString()}`;
}
const getCustomStyles = (darkMode: IThemeState) => {
	return {
		// Whole select UI
		control: (provided: any, state: any) => ({
			...provided,
			color: state.isFocused ? "#356EFF" : "#356EFF",
			minHeight: "32px",
			maxWidth: "100%",
			maxHeight: "32px",
			textAlign: "left",
			// marginBottom: "10px",
		}),
		// Dropdowns
		option: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isSelected
				? darkMode.themeVariables.navbarColor
				: "white",
			color: state.isSelected ? darkMode.themeVariables.primaryColor : "black",
			minHeight: "32px",
			maxHeight: "32px",
			textAlign: "left",
		}),
		// Content
		singleValue: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
			// marginBottom: "10px",
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: "#356EFF",
			textAlign: "left",
			// marginBottom: "10px",
		}),
	};
};

const generateUniqueColor = () => {
	return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

function timeAgo(date: Date) {
	const currentDate = new Date();
	const timeDifference = currentDate.getTime() - date.getTime();

	// Define time units in milliseconds
	const minute = 60 * 1000;
	const hour = 60 * minute;
	const day = 24 * hour;
	const week = 7 * day;
	const month = 30.44 * day; // Average month length

	if (timeDifference < minute) {
		const seconds = Math.floor(timeDifference / 1000);
		return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
	}
	if (timeDifference < hour) {
		const minutes = Math.floor(timeDifference / minute);
		return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
	}
	if (timeDifference < day) {
		const hours = Math.floor(timeDifference / hour);
		return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
	}
	if (timeDifference < week) {
		const days = Math.floor(timeDifference / day);
		return `${days} ${days === 1 ? "day" : "days"} ago`;
	}
	if (timeDifference < month) {
		const weeks = Math.floor(timeDifference / week);
		return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
	}
	const months = Math.floor(timeDifference / month);
	return `${months} ${months === 1 ? "month" : "months"} ago`;
}

export {
	connectWithPmt,
	flipImage,
	getCustomStyles,
	generateUniqueColor,
	timeAgo,
};

export function isValidJSON(jsonString: string) {
	try {
		JSON.parse(jsonString);
		return true;
	} catch (error) {
		return false;
	}
}
