import React from "react";

import Annotations from "./Annotation/Annotation";
import RecordReplay from "./RecordReplay";
import VideoPlayer from "./VideoPlayer";

function Media(props: any) {
	const { data, totalHeight } = props;
	return (
		<>
			{data?.screenshot && !data?.video && (
				<Annotations data={data?.screenshot} totalHeight={totalHeight} />
			)}
			{data?.video && (
				<VideoPlayer data={data?.video} totalHeight={totalHeight} />
			)}
			{data?.arrayBuffer && (
				<RecordReplay data={data?.arrayBuffer} totalHeight={totalHeight} />
			)}
		</>
	);
}

export default Media;
