// import { useNavigate } from "react-router-dom";
// import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import NotificationTypes from "../../../assets/utils/enum";
import { useGetNotificationQuery } from "../../../shared/services/notification";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";

function Notification() {
	// const navigate = useNavigate();
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;

	const [hasMore, setHasMore] = useState(false);
	const [skip, setSkip] = useState(0);
	const [data, setData] = useState<any>([]);

	const notificationCountBody = {
		count: true,
		// seen: notificationCount?.unseenCount !== 0 ? false : true,
	};
	const { data: notificationCount, refetch: refetchCount } =
		useGetNotificationQuery(notificationCountBody, {
			refetchOnMountOrArgChange: true,
		});

	const notificationBody = {
		limit: 10,
		skip,
		count: false,
		// seen: notificationCount?.unseenCount !== 0 ? false : true,
	};

	const {
		data: notifications,
		refetch,
		isFetching,
	} = useGetNotificationQuery(notificationBody, {
		refetchOnMountOrArgChange: true,
	});

	useEffect(() => {
		if (notifications?.length === 0) {
			setHasMore(false);
		}
		if (notifications?.length > 0 && !isFetching) {
			setData([...data, ...notifications]);
		}
	}, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

	//   const [markNotificationSeen] = useMarkNotificationSeenMutation();
	const fetchMoreData = () => {
		if (hasMore) {
			if (notificationCount?.unseenCount > 10) {
				refetchCount();
				refetch();
			} else if (
				notificationCount?.unseenCount > 0 &&
				notificationCount?.unseenCount < 10
			) {
				refetchCount();
				refetch();
				setHasMore(false);
			} else if (
				notificationCount?.unseenCount === 0 &&
				notificationCount?.seenCount > 0 &&
				notificationCount?.seenCount > data?.length
			) {
				setSkip(skip + 1);
				refetch();
			} else {
				setHasMore(false);
			}
		}
	};

	const cb = () => {
		if (hasMore) {
			fetchMoreData();
		}
	};

	const [observerTarget] = useInfiniteScroll(cb, [data.length]);

	const handleNotificationClick = async (e: any) => {
		e.preventDefault();
		// navigate(
		// 	`/bug/details/${notification?.globalNotificationId?.issueInfo?._id}`,
		// );
	};

	// eslint-disable-next-line consistent-return
	const getNotification = (notification: any | undefined) => {
		switch (notification?.name) {
			case NotificationTypes.WORKSPACE_CREATED:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}
						</span>{" "}
						created
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.metaData?.workspaceName}{" "}
						</span>
					</div>
				);

			case NotificationTypes.WORKSPACE_UPDATED:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						{/* <span>
							{notification?.metaData?.firstName}{" "}
							{notification?.globalNotificationId?.userDetails?.lastName}
						</span>{" "}
						<span>
							{" "}
							{notification?.globalNotificationId?.projectInfo?.name}{" "}
						</span> */}
					</div>
				);

			case NotificationTypes.WORKSPACE_NAME_UPDATED:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}
						</span>{" "}
						updated workspace name from{" "}
						<span style={{ color: "#356eff" }}>
							{notification?.metaData?.oldWorkspaceName}
						</span>
						to
						<span style={{ color: "#356eff" }}>
							{notification?.metaData?.newWorkspaceName}.
						</span>
					</div>
				);

			case NotificationTypes.WORKSPACE_DELETION:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}
						</span>{" "}
						deleted
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.metaData?.workspaceName}{" "}
						</span>
					</div>
				);

			case NotificationTypes.WORKSPACE_MEMBER_UPDATED:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}
						</span>{" "}
						added
						<span style={{ color: "#356eff" }}>
							{notification?.metaData?.userId?.firstName}{" "}
							{notification?.metaData?.userId?.lastName}{" "}
						</span>{" "}
						to
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.metaData?.workspaceName}{" "}
						</span>
					</div>
				);

			case NotificationTypes.WORKSPACE_STATUS_UPDATED:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}{" "}
						</span>
						changed status of
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.metaData?.workspaceName}{" "}
						</span>
					</div>
				);

			case NotificationTypes.TESTBUDDY_AI:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}
						</span>
						<span style={{ color: "#356eff" }}>
							{notification?.metadata?.updatedStatusTestBuddyAI
								? "Enabled"
								: "Disabled"}{" "}
							TestbuddyAI
						</span>
					</div>
				);

			case NotificationTypes.TAGGED:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.issueInfo?.title}:{" "}
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}
						</span>{" "}
						added a comment
					</div>
				);

			case NotificationTypes.ISSUE_CREATED:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}
						</span>{" "}
						created a issue:
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.metaData?.bugTitle}{" "}
						</span>
					</div>
				);

			case NotificationTypes.BUG_STATUS_CHANGED:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.userDetails?.firstName}{" "}
							{notification?.userDetails?.lastName}
						</span>{" "}
						changed satus:{" "}
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.globalNotificationId?.projectInfo?.name}{" "}
						</span>
					</div>
				);

			case NotificationTypes.SUBSCRIPTION_RENEWAL:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.globalNotificationId?.userDetails?.firstName}{" "}
							{notification?.globalNotificationId?.userDetails?.lastName}
						</span>{" "}
						revoked integration of project:{" "}
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.globalNotificationId?.projectInfo?.name}{" "}
						</span>
					</div>
				);

			case NotificationTypes.INVITE:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.globalNotificationId?.userDetails?.firstName}{" "}
							{notification?.globalNotificationId?.userDetails?.lastName}
						</span>{" "}
						revoked integration of project:{" "}
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.globalNotificationId?.projectInfo?.name}{" "}
						</span>
					</div>
				);

			case NotificationTypes.FORGOTPASSWORD:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.globalNotificationId?.userDetails?.firstName}{" "}
							{notification?.globalNotificationId?.userDetails?.lastName}
						</span>{" "}
						revoked integration of project:{" "}
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.globalNotificationId?.projectInfo?.name}{" "}
						</span>
					</div>
				);

			case NotificationTypes.VERIFICATION:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span style={{ color: "#356eff" }}>
							{notification?.globalNotificationId?.userDetails?.firstName}{" "}
							{notification?.globalNotificationId?.userDetails?.lastName}
						</span>{" "}
						revoked integration of project:{" "}
						<span style={{ color: "#356eff" }}>
							{" "}
							{notification?.globalNotificationId?.projectInfo?.name}{" "}
						</span>
					</div>
				);

			case NotificationTypes.WELCOMEUSER:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span>
							{notification?.globalNotificationId?.userDetails?.firstName}{" "}
							{notification?.globalNotificationId?.userDetails?.lastName}
						</span>{" "}
						revoked integration of project:{" "}
						<span>
							{" "}
							{notification?.globalNotificationId?.projectInfo?.name}{" "}
						</span>
					</div>
				);

			case NotificationTypes.FEEDBACK:
				return (
					<div
						className="notification-text notification-text"
						style={{
							color: darkMode ? "white" : "black",
						}}
					>
						<span>
							{notification?.globalNotificationId?.userDetails?.firstName}{" "}
							{notification?.globalNotificationId?.userDetails?.lastName}
						</span>{" "}
						revoked integration of project:{" "}
						<span>
							{" "}
							{notification?.globalNotificationId?.projectInfo?.name}{" "}
						</span>
					</div>
				);

			default:
		}
	};

	return (
		<div>
			<div>
				<div
					role="button" // Add the appropriate role
					tabIndex={0}
					onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
						if (e.key === "Enter" || e.key === " ") {
							// Handle activation logic here
							e.preventDefault(); // Prevent triggering any default behavior
						}
					}}
					className="card"
					style={{
						border: darkMode ? "0.5px solid #818181" : "",
					}}
					onClick={(e: React.MouseEvent<HTMLDivElement>) => {
						// Handle click logic here
						e.stopPropagation();
					}}
				>
					<div
						className="card-header"
						style={{
							backgroundColor: darkMode ? "#1c2a4c" : "#FFFFFF",
						}}
					>
						<div
							style={{
								fontSize: 16,
								fontWeight: 400,
								color: darkMode ? "white" : "black",
							}}
							className="notification-text"
						>
							Last updates
						</div>
					</div>
					<div
						className="list-group list-group-flush list-group-hoverable"
						style={{
							border: darkMode ? "0.5px solid #818181" : "",
						}}
					>
						{data?.map((notification: any) => (
							<div
								className={`list-group-item ${
									notification?.seen ? "" : "notification-item"
								}`}
								style={{
									// cursor: "pointer",
									// background: notification?.seen ? "" : "#0850c41a",
									backgroundColor: darkMode ? "#090D17" : "#FFFFFF",
									border: darkMode ? "0.5px solid #818181" : "",
								}}
								onClick={(e: any) => handleNotificationClick(e)}
								onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
									if (e.key === "Enter" || e.key === " ") {
										handleNotificationClick(e);
									}
								}}
								role="button" // Add the role attribute
								tabIndex={0} // Add the tabIndex attribute to make it focusable
							>
								<div className="row align-items-center">
									<div className="col">
										<div className="text-body d-block">
											{getNotification(notification)}
										</div>
									</div>
								</div>
							</div>
						))}
						<div ref={observerTarget} />
						{/* <InfiniteScroll
							dataLength={data?.length || 0}
							next={fetchMoreData}
							hasMore={hasMore}
							style={{
								backgroundColor: darkMode ? "#1c2a4c" : "#FFFFFF",
							}}
							loader={
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										margin: "10px 0px",
									}}
								>
									<div className="spinner-border text-primary" role="status" />
								</div>
							}
							height={350}
							endMessage={
								<div
									style={{
										backgroundColor: darkMode ? "#1c2a4c" : "#FFFFFF",
										height: 50,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div
										style={{
											color: darkMode ? "white" : "black",
										}}
									>
										{data?.length === 0
											? "No updates available"
											: "No more updates available"}
									</div>
								</div>
							}
						>
							{data?.map((notification: any) => (
								<div
									className={`list-group-item ${
										notification?.seen ? "" : "notification-item"
									}`}
									style={{
										cursor: "pointer",
										// background: notification?.seen ? "" : "#0850c41a",
										backgroundColor: darkMode ? "#090D17" : "#FFFFFF",
										border: darkMode ? "0.5px solid #818181" : "",
									}}
									onClick={(e: any) => handleNotificationClick(e, notification)}
									onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
										if (e.key === "Enter" || e.key === " ") {
											handleNotificationClick(e, notification);
										}
									}}
									role="button" // Add the role attribute
									tabIndex={0} // Add the tabIndex attribute to make it focusable
								>
									<div className="row align-items-center">
										
										<div className="col text-truncate">
											<div className="text-body d-block">
												{getNotification(notification)}
											</div>
										</div>
									</div>
								</div>
							))}
						</InfiniteScroll> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Notification;
