import classNames from "./styles.module.scss";
import leftCarrot from "../../assets/icon/leftCarrot.svg";
import rightCarrot from "../../assets/icon/rightCarrot.svg";

function TableFooter({
	totalEntries,
	currentPage,
	entriesPerPage,
	onPageChange, // onLimitChange,
}: {
	totalEntries: number;
	currentPage: number;
	entriesPerPage: number;
	onPageChange: (page: number) => void;
	// onLimitChange: (limit: number) => void;
}) {
	const totalPages = Math.ceil(totalEntries / entriesPerPage);

	const handlePageChange = (newPage: number) => {
		if (newPage >= 1 && newPage <= totalPages) {
			onPageChange(newPage);
		}
	};

	// const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
	// 	onLimitChange(Number(e.target.value));
	// };

	const renderPagination = () => {
		const pagesToShow = 5; // Adjust the number of pages to show
		const pages = [];

		if (totalPages <= pagesToShow) {
			// eslint-disable-next-line no-plusplus
			for (let i = 1; i <= totalPages; i++) {
				pages.push(i);
			}
		} else {
			const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
			const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

			if (startPage > 1) {
				pages.push(1);
				if (startPage > 2) {
					pages.push("..."); // Show ellipsis if there are more pages before the startPage
				}
			}

			// eslint-disable-next-line no-plusplus
			for (let i = startPage; i <= endPage; i++) {
				pages.push(i);
			}

			if (endPage < totalPages) {
				if (endPage < totalPages - 1) {
					pages.push("..."); // Show ellipsis if there are more pages after the endPage
				}
				pages.push(totalPages);
			}
		}

		return (
			<div className="d-flex align-items-center ">
				<button
					style={{ marginRight: "5px" }}
					type="button"
					className={classNames["nav-button"]}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					<img src={leftCarrot} alt="prev" />
				</button>
				{pages.map((page: any) => (
					<button
						type="button"
						className={`${classNames["nav-button"]} ${
							page === currentPage ? classNames["page-active"] : ""
						} ${page === currentPage ? "" : "text-color"}`}
						key={page}
						onClick={() => handlePageChange(page)}
						style={{
							color: page === currentPage ? "white" : "black",
						}}
					>
						{page}
					</button>
				))}
				<button
					type="button"
					className={`${classNames["nav-button"]} text-color`}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					<img src={rightCarrot} alt="next" />
				</button>
			</div>
		);
	};

	return (
		<div className={`d-flex align-items-center ${classNames["table-footer"]}`}>
			<p className="m-0">
				Showing {Math.min(currentPage * entriesPerPage, totalEntries)} of{" "}
				{totalEntries} entries
			</p>
			{renderPagination()}
		</div>
	);
}

export default TableFooter;
