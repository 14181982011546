import React, { useState, useEffect } from "react";

import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
	useAddSlackMetaMutation,
	useGetSlackChannelsQuery,
	useRemoveSlackMetaMutation,
} from "../../../shared/services/integration";
import "./selectStyle.css";
import classNames from "./styles.module.scss";

function SlackIntegration(props: any) {
	const {
		slackIntegration,
		workspaceId,
		openModal,
		setOpenModal,
		refetchAllIntegrations,
	} = props;
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;
	const [selectedChannel, setSelectedChannel] = useState<any>(null);
	const [channels, setChannels] = useState<any>([
		{ label: "general", value: "general" },
		{ label: "test", value: "test" },
		{ label: "testing", value: "testing" },
	]);
	const [isEnabled, setIsEnabled] = useState(false);
	const [removeSlackMeta] = useRemoveSlackMetaMutation();

	useEffect(() => {
		// Check if githubIntegration has data
		if (slackIntegration && slackIntegration.metadata) {
			if (slackIntegration?.metadata?.selectedChannel) {
				setIsEnabled(true);
			}
			// Update state based on selectedProject
			setSelectedChannel(slackIntegration.metadata.selectedChannel || null);
		}
	}, [slackIntegration]);

	const { data } = useGetSlackChannelsQuery(
		{ workspaceId },
		{ skip: openModal !== "Slack" },
	);

	const [addSlackMeta] = useAddSlackMetaMutation();
	async function handleRemoveSlackMeta() {
		const receivedData = await removeSlackMeta({
			id: slackIntegration._id,
		});
		if (receivedData) {
			setOpenModal("");
			setIsEnabled(false);
			refetchAllIntegrations();
		}
	}

	const addSlackChannel = async (val: any) => {
		setSelectedChannel(val);
		await addSlackMeta({
			id: slackIntegration._id,
			selectedChannel: val,
		});
		setOpenModal("");
		setIsEnabled(true);
	};

	useEffect(() => {
		if (data && data.length > 0) {
			const arr: any = [];
			data.map((channel: any) => {
				const obj = {
					label: channel.name,
					value: channel.id,
				};
				arr.push(obj);
				return channel;
			});
			setChannels(arr);
		}
	}, [data]);

	return (
		<div
			className="d-flex align-items-center justify-content-between"
			style={{ position: "relative" }}
		>
			<div
				className={`d-flex gap-3 ${classNames["pmt-logo"]}`}
				style={{ marginLeft: "2rem" }}
			>
				<div>
					<p className="primary-font-color m-auto">Sessions</p>
				</div>
			</div>
			<div className=" d-flex gap-2" style={{ cursor: "pointer" }}>
				<div>
					<p className="primary-font-color m-auto">
						{selectedChannel ? `# ${selectedChannel.label}` : "Do Not Notify"}
					</p>
				</div>
				{/* eslint-disable jsx-a11y/control-has-associated-label */}
				<div
					role="button"
					tabIndex={0}
					onKeyDown={() => {
						if (openModal === "Slack") {
							setOpenModal("");
						} else {
							setOpenModal("Slack");
						}
					}}
					onClick={() => {
						if (openModal === "Slack") {
							setOpenModal("");
						} else {
							setOpenModal("Slack");
						}
					}}
				>
					<svg
						style={{
							marginBottom: "2px",
							transform: openModal === "Slack" ? "rotate(180deg)" : "none",
							transition: "transform 0.3s",
						}}
						fill={darkMode ? "white" : "black"}
						xmlns="http://www.w3.org/2000/svg"
						height="14"
						width="14"
						viewBox="0 0 512 512"
					>
						<path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
					</svg>
				</div>
			</div>
			{openModal === "Slack" && (
				<div
					className={`${classNames["slack-options-container"]} integration-popup-container`}
				>
					<div
						className={`${classNames["slack-heading"]}`}
						style={{ marginLeft: "16px", marginTop: "8px" }}
					>
						SLACK
					</div>
					<div
						style={{
							position: "relative",
							maxHeight: "36px",
							height: "100%",
							marginTop: "8px",
						}}
					>
						<div
							style={{
								position: "absolute",
								marginLeft: "8px",
								marginTop: "3px",
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								height="14"
								width="14"
								viewBox="0 0 512 512"
								fill="#8c95a8"
							>
								<path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
							</svg>
						</div>
						<div>
							<input
								type="text"
								className={`${classNames["slack-input-search"]} `}
								placeholder="Search channel name"
							/>
						</div>
					</div>
					<div className={classNames["slack-channels-list"]}>
						{channels &&
							channels.map((channel: any) => {
								return (
									<div
										role="button"
										tabIndex={0}
										onKeyDown={() => {
											addSlackChannel(channel);
										}}
										className={`${classNames["individual-slack-channel"]} primary-font-color`}
										onClick={() => {
											addSlackChannel(channel);
										}}
									>
										# {channel.label}
									</div>
								);
							})}
					</div>
					<div
						className="d-flex gap-1"
						style={{ marginBottom: "10px", marginLeft: "10px" }}
					>
						<div>
							<svg
								style={{
									marginBottom: "5px",
								}}
								xmlns="http://www.w3.org/2000/svg"
								height="14"
								width="18"
								viewBox="0 0 640 512"
								fill="#dc3545"
							>
								<path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L542.6 400c2.7-7.8 1.3-16.5-3.9-23l-14.9-18.6C495.5 322.9 480 278.8 480 233.4V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V51.2c-42.6 8.6-79 34.2-102 69.3L38.8 5.1zM224 150.3C243.6 117.7 279.3 96 320 96c61.9 0 112 50.1 112 112v25.4c0 32.7 6.4 64.8 18.7 94.5L224 150.3zM406.2 416l-60.9-48H168.3c21.2-32.8 34.4-70.3 38.4-109.1L160 222.1v11.4c0 45.4-15.5 89.5-43.8 124.9L101.3 377c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6H406.2zM384 448H320 256c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z" />
							</svg>
						</div>
						<div
							className="text-danger"
							style={{ fontSize: "14px" }}
							role="button"
							tabIndex={0}
							onKeyDown={() => {
								setOpenModal("");
								if (isEnabled === true) {
									handleRemoveSlackMeta();
								} else setSelectedChannel(null);
							}}
							onClick={() => {
								setOpenModal("");
								if (isEnabled === true) {
									handleRemoveSlackMeta();
								} else setSelectedChannel(null);
							}}
						>
							Do not notify
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
export default SlackIntegration;
