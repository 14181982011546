import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

export const purchaseApi = createApi({
	reducerPath: "purchaseAPI",
	baseQuery: baseQueryWithReauth,
	// tagTypes: [""],
	endpoints: (builder) => ({
		getPurchaseHistory: builder.query<any, any>({
			query: (data: any) => ({
				method: "GET",
				url: `/purchase/${data?.workspaceId}`,
			}),
		}),
	}),
});

export const { useGetPurchaseHistoryQuery } = purchaseApi;
