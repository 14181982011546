import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGetWorkspaceQuery } from "../../shared/services/workspace";
import PrimaryButton from "../Button/Primary";
import PrivateWorkSpace from "./PrivateWorkspace";
import SharedWorkspace from "./SharedWorkspace";
import WorkspaceSetting from "./WorkspaceSetting";
import className from "./styles.module.scss";

interface ISelectedWorkspace {
	workspace: IOption;
	private: any;
}
interface IOption {
	label: string;
	value: string;
}
function WorkSpaceSideNav() {
	const [sharedWorkspaces, setSharedWorkspaces] = useState<Array<IOption>>([]);
	const navigate = useNavigate();
	const params = useParams();
	const [privateWorkspaces, setPrivateWorkspaces] = useState<IOption>({
		label: "",
		value: "",
	});
	const [selectedWorkspace, setSelectedWorkspace] =
		useState<ISelectedWorkspace>({
			workspace: { label: "", value: "" },
			private: false,
		});

	const { data: workspaces } = useGetWorkspaceQuery("", {
		refetchOnMountOrArgChange: true,
		skip: false,
	});
	useEffect(() => {
		const workspaceId = params.id;

		if (workspaces && workspaces.data.length) {
			const data: Array<{ label: string; value: string }> = [];
			const privateWorkspace = workspaces.data[0];
			const sharedWorkspace = workspaces.data.slice(1);

			// Only private workspace
			setPrivateWorkspaces({
				label: privateWorkspace.workspaceName,
				value: privateWorkspace.workspaceId,
			});

			// If default workspaceId is in the URL or no workspace is selected
			if (workspaceId === privateWorkspace.workspaceId || !workspaceId) {
				setSelectedWorkspace({
					workspace: {
						label: privateWorkspace.workspaceName,
						value: privateWorkspace.workspaceId,
					},
					private: true,
				});
				if (!workspaceId)
					navigate(`/workspace/${privateWorkspace.workspaceId}`);
			}

			// Preparing shared workspace
			sharedWorkspace.forEach((workspace: any) => {
				if (workspaceId === workspace.workspaceId) {
					setSelectedWorkspace({
						workspace: {
							label: workspace.workspaceName,
							value: workspace.workspaceId,
						},
						private: false,
					});
				}
				data.push({
					label: workspace.workspaceName,
					value: workspace.workspaceId,
				});
			});
			setSharedWorkspaces(data);
		}
	}, [workspaces, navigate, params.id]);

	const onWorkspaceSelection = (data: IOption, mode: any) => {
		setSelectedWorkspace({ workspace: data, private: mode || false });
		navigate(`/workspace/${data.value}`);
	};

	return (
		<div
			className={`d-xs-none d-sm-none d-md-flex flex-column justify-content-between sidenav-background ${className.container}`}
			id="sidenav-container"
		>
			<div className={className.content}>
				<PrivateWorkSpace
					selectedWorkspace={selectedWorkspace}
					privateWorkspace={privateWorkspaces}
					onChangeHandler={onWorkspaceSelection}
				/>
				<SharedWorkspace
					selectedWorkspace={selectedWorkspace}
					sharedWorkspaces={sharedWorkspaces}
					onChangeHandler={onWorkspaceSelection}
					setSelectedWorkspace={setSelectedWorkspace}
				/>
				<PrimaryButton />
			</div>
			<WorkspaceSetting />
		</div>
	);
}

export default WorkSpaceSideNav;
