import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { toast } from "react-toastify";

import { useSendClickupAccessTokenMutation } from "../../shared/services/integration";

function ReceiveClickup() {
	const params = new URLSearchParams(useLocation().search);
	const [sendClickupAccessToken] = useSendClickupAccessTokenMutation();
	const api = async () => {
		const code: string = params.get("code") ?? "";
		const extensionId: string = params.get("state") ?? "";
		const data: any = {
			code,
		};
		const response: any = await sendClickupAccessToken(data);
		console.log({ response });
		if (extensionId && response?.data?.success) {
			chrome.runtime.sendMessage(
				extensionId,
				{
					msg: "CLICKUP-ACCESS-CBP",
					data: {
						accessToken: response.data.accessToken,
						email: response.data.email,
					},
				},
				(respne: any) => {
					console.log({ respne });
					setTimeout(() => {
						window.close();
					}, 1500);
				},
			);
		} else {
			// toast.error("Some error occured. Please try again");
			setTimeout(() => {
				window.close();
			}, 1500);
		}
	};

	useEffect(() => {
		api();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex flex-column justify-content-center align-items-center primary-background">
			<div>Authorisation Success</div>
			<div>Please wait ...</div>
		</div>
	);
}

export default ReceiveClickup;
