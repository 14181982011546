import { useEffect, useState } from "react";
import { useParams } from "react-router";

import className from "./styles.module.scss";
import General from "./General";
import Members from "./Members";
import Integrations from "./Integrations";
// import SubscriptionPlan from "./SubscriptionPlan";
import Notifications from "./Notifications";
import { useGetWorkspaceQuery } from "../../shared/services/workspace";
// import { useGetPurchaseHistoryQuery } from "../../shared/services/purchase";
import { useGetMemberInfoQuery } from "../../shared/services/members";

interface ITabs {
	name: string;
	content: JSX.Element;
}

function WorkspaceSettings() {
	const [activeTab, setActiveTab] = useState(
		window.location.href.split("#")[1]?.toUpperCase() || "GENERAL",
	);

	const handleTabClick = (tab: string) => {
		window.location.hash = tab.toLowerCase();
		setActiveTab(tab);
	};

	const { id } = useParams();
	const { data: workspace, refetch } = useGetWorkspaceQuery(
		{ id },
		{
			refetchOnMountOrArgChange: true,
			// skip: !id,
		},
	);

	const { data: memberInfo } = useGetMemberInfoQuery(
		{
			workspaceId: id,
		},
		{
			refetchOnMountOrArgChange: true,
		},
	);

	// const { data: purchaseHistory } = useGetPurchaseHistoryQuery(
	// 	{
	// 		workspaceId: id,
	// 	},
	// 	{
	// 		refetchOnMountOrArgChange: true,
	// 	},
	// );

	useEffect(() => {
		document.title = "TestBuddy | Workspace Settings";
	}, [workspace, id]);

	const tabs: ITabs[] = [
		{
			name: "GENERAL",
			content: (
				<General
					workspace={workspace?.data}
					refetch={refetch}
					memberInfo={memberInfo}
				/>
			),
		},
		...(workspace?.data?.name === "My Workspace"
			? []
			: [
					{
						name: "MEMBERS",
						content: <Members workspaceId={id} memberInfo={memberInfo} />,
					},
				]),
		{ name: "INTEGRATIONS", content: <Integrations workspaceId={id} /> },
		// {
		// 	name: "SUBSCRIPTION PLAN",
		// 	content: (
		// 		<SubscriptionPlan workspaceId={id} purchaseHistory={purchaseHistory} />
		// 	),
		// },
		{ name: "NOTIFICATIONS", content: <Notifications workspaceId={id} /> },
	];

	return (
		<>
			<p className={` text-left ${className["page-heading"]}`}>
				{workspace?.data?.name?.toUpperCase()} /{" "}
				<span className={`${className["page-heading-span"]}`}>SETTINGS</span>
			</p>
			<div
				className={`d-flex justify-content-around align-items-center primary-background ${className["tab-container"]}`}
			>
				{tabs?.map((tab: ITabs, index: number) => (
					<div
						key={tab?.name}
						onClick={() => handleTabClick(tab?.name)}
						onKeyDown={() => handleTabClick(tab?.name)}
						role="button"
						tabIndex={index}
						className={`d-flex justify-content-center w-100 ${
							className["cursor-pointer"]
						} ${className["tab-item"]} ${
							tab?.name === activeTab ? className.active : ""
						} `}
					>
						<p>{tab?.name}</p>
					</div>
				))}
			</div>
			<div className={`${className["tab-content"]}`}>
				{tabs.find((tab) => tab.name === activeTab)?.content}
			</div>
		</>
	);
}

export default WorkspaceSettings;
