import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import classNames from "./createWorkspaceModal.module.scss";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import "./modal.scss";
import { useYoutrackIntegrationMutation } from "../../shared/services/integration";

function CreateYoutrackIntegrationModal(props: any) {
	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;

	const { show, onHide, workspaceId, handleClose } = props;

	const [youtrackIntegration] = useYoutrackIntegrationMutation();
	const {
		register,
		handleSubmit,
		reset,

		formState: { errors },
	}: any = useForm({
		mode: "onChange",
	});
	const onSubmit = async (data: any) => {
		console.log("hit");
		console.log({ data });

		const response: any = await youtrackIntegration({
			instanceUrl: data?.instanceUrl,
			apiToken: data?.apiToken,
			workspaceId,
		});
		console.log({ response });
		if (!response?.data?.success) {
			toast.error("Please check your credentials or permissions");
		} else {
			toast.success("Youtrack integration established successfully!");
			reset();
			onHide();
			handleClose();
		}
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body
				style={{
					backgroundColor: darkMode ? "#090D17" : "#FFFFFF",
					borderRadius: 18,
					border: "1px solid #356EFF",
					height: 425,
				}}
			>
				<div>
					<div
						className="d-flex justify-content-between w-100"
						style={{ marginBottom: 33 }}
					>
						<div
							className="tb-modal-heading"
							style={{
								color: darkMode ? "white" : "black",
							}}
						>
							YOUTRACK INTEGRATION
						</div>
						{darkMode ? (
							<svg
								onClick={onHide}
								style={{
									cursor: "pointer",
								}}
								width="28"
								height="28"
								viewBox="0 0 28 28"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="28" height="28" rx="14" fill="#356EFF" />
								<path
									d="M19.8327 8.16669L8.16602 19.8334"
									stroke="white"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.16602 8.16669L19.8327 19.8334"
									stroke="white"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						) : (
							<svg
								onClick={onHide}
								style={{
									cursor: "pointer",
								}}
								width="28"
								height="28"
								viewBox="0 0 28 28"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="0.5"
									y="0.5"
									width="27"
									height="27"
									rx="13.5"
									stroke="#356EFF"
								/>
								<path
									d="M19.8327 8.16675L8.16602 19.8334"
									stroke="#356EFF"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.16602 8.16675L19.8327 19.8334"
									stroke="#356EFF"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div>
							<div
								className="tb-modal-heading"
								style={{
									color: darkMode ? "white" : "black",
								}}
							>
								Instance URL
							</div>
							<input
								type="text"
								className="workspace w-100"
								// className="form-control"
								{...register("instanceUrl", {
									required: "Instance URL is required",
									pattern: {
										// eslint-disable-next-line  no-useless-escape
										value: /^https:\/\/[a-zA-Z0-9-]+\.youtrack\.cloud$/,
										message:
											"Enter a valid YouTrack instance URL. The URL should start with 'https' and must not end with '/'.",
									},
								})}
								autoComplete="off"
								aria-autocomplete="none"
								style={{
									borderRadius: 6,
									background: darkMode ? "#1C2A4C" : "#f7f6fb",
									border: darkMode ? "none" : "1px solid #356EFF",
									padding: 10,
									outline: "none",
									color: darkMode ? "white" : "black",
								}}
								placeholder="Enter your Youtrack Instance URL"
							/>
							{errors?.instanceUrl && (
								<span className="text-danger">
									{errors?.instanceUrl?.message}
								</span>
							)}
						</div>
						<div style={{ marginTop: "8px" }}>
							<div
								className="tb-modal-heading"
								style={{
									color: darkMode ? "white" : "black",
								}}
							>
								API token
							</div>

							<input
								type="text"
								className="workspace w-100"
								// className="form-control"
								{...register("apiToken", {
									required: true,
								})}
								autoComplete="off"
								aria-autocomplete="none"
								style={{
									borderRadius: 6,
									background: darkMode ? "#1C2A4C" : "#f7f6fb",
									border: darkMode ? "none" : "1px solid #356EFF",
									padding: 10,
									outline: "none",
									color: darkMode ? "white" : "black",
								}}
								placeholder="Enter your user API Token"
							/>
							{errors?.apiToken && (
								<span className="text-danger">API token is required</span>
							)}
							<div style={{ marginTop: "8px" }}>
								<p>
									<strong>Note:</strong> To generate an API token, please refer
									to the{" "}
									<a
										href="https://www.jetbrains.com/help/youtrack/devportal/Manage-Permanent-Token.html"
										target="_blank"
										rel="noopener noreferrer"
									>
										YouTrack API Token Guide
									</a>
									. Ensure the token has both "YouTrack" and "YouTrack
									Administration" scopes.
								</p>
							</div>
						</div>

						<div
							className="d-flex justify-content-center w-100"
							style={{ marginTop: 40 }}
						>
							<button
								className={classNames["create-workspace-button"]}
								type="submit"
								style={{
									width: 149,
									height: 34,
									background: "#356EFF",
									border: "none",
									color: "white",
									padding: "12px 9px 12px 9px",
									fontSize: 12,
									fontWeight: 600,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									margin: 0,
								}}
							>
								Proceed
							</button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
}
export default CreateYoutrackIntegrationModal;
