import classNames from "./customSelect.module.scss";

function CustomDropdownIndicator({ selectProps }: any) {
	const handleKeyDown = (event: any) => {
		// Example: handle Enter key
		if (event.key === "Enter") {
			selectProps.onMenuOpen();
		}
	};

	return (
		<div
			className={classNames["custom-dropdown-indicator"]}
			onClick={selectProps.onMenuOpen}
			onKeyDown={handleKeyDown}
			role="button"
			tabIndex={0} // Makes the div focusable
			aria-label="Open dropdown" // Descriptive label for the button
		>
			<svg
				width="12"
				height="6"
				viewBox="0 0 12 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M6 6L12 0H0L6 6Z" fill="#356EFF" />
			</svg>
		</div>
	);
}

export default CustomDropdownIndicator;
