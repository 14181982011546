export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID;
export const SLACK_REDIRECT_URI = process.env.REACT_APP_SLACK_REDIRECT_URI ;
export const AZURE_INTEGRATION_URL =
	process.env.REACT_APP_AZURE_INTEGRATION_URL;
export const CLICKUP_INTEGRATION_URL =
	process.env.REACT_APP_CLICKUP_INTEGRATION_URL;
export const GITHUB_INTEGRATION_URL = 
process.env.REACT_APP_GITHUB_INTEGRATION_URL;
export const GITLAB_INTEGRATION_URL = 
process.env.REACT_APP_GITLAB_INTEGRATION_URL;
export const REACT_APP_URL = process.env.REACT_APP_URL;
export const REACT_APP_MSAL_APPID = process.env.REACT_APP_MSAL_APPID;
export const REACT_APP_GOOGLE_SSO_CLIENT_ID =
	process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID;
export const REACT_APP_GOOGLE_SSO_REDIRECT_URL =
	process.env.REACT_APP_GOOGLE_SSO_REDIRECT_URL;
export const REACT_APP_GITHUB_SSO_CLIENT_ID =
	process.env.REACT_APP_GITHUB_SSO_CLIENT_ID;
export const REACT_APP_GITHUB_SSO_REDIRECT_URL =
	process.env.REACT_APP_GITHUB_SSO_REDIRECT_URL;
export const REACT_APP_JIRA_CLIENT_ID = process.env.REACT_APP_JIRA_CLIENT_ID;	
export const REACT_APP_JIRA_REDIRECT_URI = process.env.REACT_APP_JIRA_REDIRECT_URI ;