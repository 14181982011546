import classNames from "./styles.module.scss";
import WorkspacePill from "../WorkspacePill";

interface IOption {
	label: string;
	value: string;
}
interface IProps {
	selectedWorkspace: {
		workspace: IOption;
		private: any;
	};
	onChangeHandler: Function;
	privateWorkspace: IOption;
}
function PrivateWorkSpace(props: IProps) {
	const { onChangeHandler, selectedWorkspace, privateWorkspace } = props;

	return (
		<div
			className={`text-left d-flex flex-column
			${selectedWorkspace?.private ? "selected-workspace" : ""}
			${classNames["my-workspace-container"]}`}
		>
			<h1
				className={`text-uppercase text-left m-0 text-white ${classNames["workspace-h1"]}`}
			>
				Private Workspaces
			</h1>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
			<div
				aria-label="My Workspace"
				tabIndex={0}
				role="button"
				onClick={() => onChangeHandler(privateWorkspace, true)}
				className={`d-flex borderalign-items-center justify-content-between flex-row ${classNames["my-workspace"]}`}
			>
				<WorkspacePill
					name="My Workspace"
					selected={selectedWorkspace?.private}
				/>
			</div>
		</div>
	);
}

export default PrivateWorkSpace;
