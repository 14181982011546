import React, { useState, useEffect, useRef } from "react";
import "./gptbuddy.scss";
import axios from "axios";
import { toast } from "react-toastify";

import ChatMessage from "./ChatMessage";
import typing from "../../../assets/golden-typing-3.gif";

export default function GptBuddy(props: any) {
	const { consoleEvents, mouseEvents, emptyIssueId, networkEvents } = props;
	const textareaRef = useRef(null);
	const [maxHeight, setMaxHeight] = useState("100%");
	useEffect(() => {
		const handleResize = () => {
			setMaxHeight(`${window.innerHeight / 2 - 50}px`);
		};

		window.addEventListener("resize", handleResize);
		handleResize(); // Call initially to set the value

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const attributesArrayFilter = (attributes: any) => {
		const filteredAttributes = attributes.filter((obj: any) => {
			const keys = Object.keys(obj);
			return (
				keys.includes("class") || keys.includes("id") || keys.includes("name")
			);
		});

		const result = filteredAttributes
			.map((obj: any) => {
				const keys = Object.keys(obj);
				return keys.map((key) => `${key}: ${obj[key]}`).join(" ");
			})
			.join(" ");

		return result;
	};

	function extractNetworkErrors() {
		// Filter networkEvents to get only 40x status codes
		const errorEvents = networkEvents.filter((event: any) => {
			const statusCode = event.response.status;
			return statusCode >= 400 && statusCode < 500;
		});

		// Extract and format the errors
		const formattedErrors = errorEvents
			.map((event: any, index: number) => {
				return `${index + 1}. ${event.response.status} network error at URI ${event.request.uri}`;
			})
			.join("\n");

		return formattedErrors;
	}

	const adjustTextareaHeight = () => {
		const textarea: any = textareaRef.current;
		textarea.style.height = "auto"; // Reset height to auto
		textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
	};

	const initialMessages: any = [
		{ role: "assistant", content: "Hello there!", type: "text" },
		{
			role: "assistant",
			content: "I am GPTBuddy, your AI code debugging assistant.",
			type: "text",
		},
		{
			role: "assistant",
			content:
				"Would you like me to analyze this issue for fixes and other recommendations?",
			type: "text",
		},
	];

	const [chatMessages, setChatMesages] = useState<any>([]);

	const [isConversationStarted, setIsConversationStarted] = useState(false);
	const [isAwaitingResponse, setIsAwaitingResponse] = useState(true);
	const [consoleErrors, setConsoleErrors] = useState(null);
	const [steps, setSteps] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [networkErrors, setNetworkErrors] = useState(null);
	const chatEndRef = useRef<any>(null);
	useEffect(() => {
		if (chatEndRef.current) {
			chatEndRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [chatMessages, isAwaitingResponse]);
	useEffect(() => {
		if (searchTerm === "" && textareaRef?.current) {
			const textarea: any = textareaRef.current;
			textarea.style.height = "auto"; // Reset height to auto
			textarea.style.height = "24px"; // Default height
		}
	}, [searchTerm]);

	const handleChange = (e: any) => {
		setSearchTerm(e.target.value);
	};

	function extractUniqueConsoleErrors(consoleEventss: any) {
		// Filter consoleEvents to get only errors and extract unique types
		const errorTypes = consoleEventss
			.filter((event: any) => event.type === "error")
			.reduce((uniqueErrors: any, event: any) => {
				event.value.forEach((error: any) => {
					if (!uniqueErrors.includes(error)) {
						uniqueErrors.push(error);
					}
				});
				return uniqueErrors;
			}, []);

		// Format the errors for output
		const formattedErrors = errorTypes
			.map((error: any, index: any) => `${index + 1}. ${error}`)
			.join("\n");

		return formattedErrors;
	}

	const structureSteps = () => {
		const urlArray = [];
		const stepsArray = mouseEvents.map((event: any, index: any) => {
			let sentence = "";
			let attributesLine;
			if (event.event !== "URL" && event.event !== "RELOAD") {
				attributesLine = attributesArrayFilter(event.attributes);
			}
			if (event.event === "RELOAD") {
				sentence += `${index + 1}. Reloaded the existing page`;
			} else if (event.event === "URL") {
				urlArray.push(event.value);
				sentence += `${index + 1}. Navigated to url${index + 1}`;
			} else if (event.event === "INPUT") {
				sentence += `${index + 1}. Typed : "${event.value}" on ${
					event.localName
				} ${attributesLine} `;
			} else if (event.event === "CLICK" && event?.innerText) {
				sentence += `${index + 1}. Clicked "${event.innerText}" ${attributesLine} `;
			}
			return sentence;
		});

		const stepss = stepsArray.join("/n");
		setSteps(stepss);
	};

	useEffect(() => {
		if (consoleEvents.length > 0) {
			const text = extractUniqueConsoleErrors(consoleEvents);
			setConsoleErrors(text);
		}
	}, [consoleEvents]);
	useEffect(() => {
		if (networkEvents?.length > 0) {
			const text = extractNetworkErrors();
			setNetworkErrors(text);
		}
	}, [networkEvents]);
	useEffect(() => {
		if (mouseEvents?.length > 0) {
			structureSteps();
		}
	}, [mouseEvents]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsAwaitingResponse(false);
			if (chatMessages.length === 0) {
				setChatMesages(initialMessages);
			}
		}, 3000);

		return () => clearTimeout(timer);
	}, [chatMessages]);

	const getChatsForIssue = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/chat?id=${emptyIssueId}`,
			);
			const newMessage = { type: "text", content: "Absolutely!", role: "user" };

			if (response.data.length > 0) {
				// Construct new chat messages array
				const updatedMessages = [
					...initialMessages, // Ensure initial messages are included
					newMessage, // User response
					...response.data, // Append API response
				];

				setChatMesages(updatedMessages);
				setIsConversationStarted(true);
			}
		} catch (error) {
			console.error("Error fetching chats:", error);
			// Handle error if needed
		}
	};

	useEffect(() => {
		if (emptyIssueId) {
			getChatsForIssue();
		}
	}, [emptyIssueId]);

	const fetchResponseFromGPTFirstQuestion = async () => {
		if (!emptyIssueId) {
			toast.error("something went wrong");
			return;
		}
		setIsAwaitingResponse(true);
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/chat/respond?id=${emptyIssueId}`,
			{
				consoleErrors,
				steps,
				networkErrors,
				userQuery:
					"A bug has been caught , can you check if there is any error and tell the probable cause of it and solution if possible in code?",
			},
		);
		if (response?.data?.success) {
			setIsAwaitingResponse(false);
			getChatsForIssue();
		}
	};

	const sendResponseToGpt = async () => {
		if (searchTerm.trim() === "") {
			toast.error("Please enter a message");
			return;
		}
		setSearchTerm("");
		const userMessage = { role: "user", content: searchTerm, type: "text" };
		setChatMesages((prevMessages: any) => [...prevMessages, userMessage]);
		setIsAwaitingResponse(true);

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/chat/respond?id=${emptyIssueId}`,
				{
					userQuery: searchTerm,
					consoleErrors,
					networkErrors,
					steps,
				},
			);
			if (response?.data?.success) {
				setIsAwaitingResponse(false);
				getChatsForIssue();
			}
		} catch (error) {
			toast.error("Failed to send message");
		}
	};
	const handleKeyPress = (e: any) => {
		if (e.key === "Enter") {
			sendResponseToGpt();
		}
	};
	let lastRole: any = null;

	return (
		<div className="ancester" style={{ maxHeight }}>
			<div className="parent-gptbuddy">
				{chatMessages.map((chatMessage: any, index: number) => {
					const showSvg =
						chatMessage.role === "assistant" && lastRole !== "assistant";
					lastRole = chatMessage.role;
					return (
						<ChatMessage
							key={index}
							chat={chatMessage}
							showSvg={showSvg} // Pass the conditionally calculated value
						/>
					);
				})}
				{!isConversationStarted && chatMessages?.length === 3 && (
					<div
						className="click-chat"
						onClick={() => fetchResponseFromGPTFirstQuestion()}
					>
						Yes. Absolutely!
					</div>
				)}
				{isAwaitingResponse && (
					<div className="typing-gif">
						<img src={typing} alt="loading" style={{ maxWidth: "100%" }} />
					</div>
				)}
				<div ref={chatEndRef} />
			</div>
			{isConversationStarted && (
				<div className="search-box">
					<textarea
						ref={textareaRef}
						value={searchTerm}
						onChange={(e) => {
							handleChange(e);
							adjustTextareaHeight();
						}}
						onKeyDown={handleKeyPress}
						placeholder="Write your query or paste a code"
						rows={1} // Set initial number of rows
						style={{ maxHeight: "48px", overflowY: "auto" }}
					/>
					<button className="search-button" onClick={sendResponseToGpt}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0_204_486)">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M19.2562 12.4921L5.6444 6.09735L7.04381 11.3584L12.9201 11.362C13.218 11.3623 13.5036 11.481 13.7141 11.6919C13.9245 11.9028 14.0426 12.1887 14.0422 12.4866C14.0419 12.7846 13.9232 13.0702 13.7123 13.2806C13.5014 13.4911 13.2156 13.6091 12.9176 13.6088L7.04054 13.6044L5.63563 18.8641L19.2562 12.4921ZM21.296 10.968C22.5885 11.5741 22.5871 13.4135 21.2937 14.0177L5.49395 21.4102C4.19736 22.016 2.78298 20.8316 3.15253 19.4484L5.01697 12.4807L3.16297 5.51017C2.79549 4.1264 4.21164 2.9441 5.50732 3.55184L21.296 10.968Z"
									fill="#E3E3E3"
								/>
							</g>
							<defs>
								<clipPath id="clip0_204_486">
									<rect width="24" height="24" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</button>
				</div>
			)}
		</div>
	);
}
