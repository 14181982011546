import { useState } from "react";

import Banner1 from "../../assets/img/Banner1";
import Banner2 from "../../assets/img/Banner2";
import Banner3 from "../../assets/img/Banner3";
import Banner4 from "../../assets/img/Banner4";
import useEffectOnce from "../../hooks/useEffectOnce";
import classNames from "./styles.module.scss";

function Banner({ activeIndex }: { activeIndex: number }) {
	if (activeIndex === 0) {
		return (
			<div className={`${classNames.bannerSection}`}>
				<div className={`${classNames.primaryHeading}`}>
					<h1 className="text-white text-capitalize">
						Report Bugs with technical logs
					</h1>
					<p className="text-white text-capitalize text-left">
						Enjoy effortless tool integration that aligns with your workflow.
						Instantly create tickets in your selected issue tracker as you
						report bugs
					</p>
				</div>

				<Banner1 />
			</div>
		);
	}
	if (activeIndex === 1) {
		return (
			<div className={`${classNames.bannerSection}`}>
				<div className={`${classNames.primaryHeading}`}>
					<h1 className="text-white text-capitalize">
						Instant watch and replay of the live session
					</h1>
					<p className="text-white text-capitalize text-left">
						Enjoy effortless tool integration that aligns with your workflow.
						Instantly create tickets in your selected issue tracker as you
						report bugs
					</p>
					<Banner2 />
				</div>
			</div>
		);
	}
	if (activeIndex === 2) {
		return (
			<div className={`${classNames.bannerSection}`}>
				<div className={`${classNames.primaryHeading}`}>
					<h1 className="text-white text-capitalize">
						Instant watch and replay of the live session
					</h1>
					<p className="text-white text-capitalize text-left">
						Enjoy effortless tool integration that aligns with your workflow.
						Instantly create tickets in your selected issue tracker as you
						report bugs
					</p>
					<Banner3 />
				</div>
			</div>
		);
	}
	return (
		<div className={`${classNames.bannerSection}`}>
			<div className={`${classNames.primaryHeading}`}>
				<h1 className="text-white text-capitalize">
					AI generated steps and bugs Details
				</h1>
				<p className="text-white text-capitalize text-left">
					Enjoy effortless tool integration that aligns with your workflow.
					Instantly create tickets in your selected issue tracker as you report
					bugs
				</p>
			</div>
			<Banner4 />
		</div>
	);
}

function Carousel() {
	const [activeIndex, setActiveIndex] = useState(0);
	useEffectOnce(() => {
		setInterval(() => {
			setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
		}, 2000);
	}, []);

	const buttonClickHandler = (index: number) => {
		setActiveIndex(index);
	};

	return (
		<div className={`${classNames.carousel} ${classNames["login-container"]}`}>
			<Banner activeIndex={activeIndex} />
			<div className={classNames.dots}>
				<button
					type="button"
					value={0}
					onClick={() => buttonClickHandler(0)}
					className={`${activeIndex === 0 ? classNames.active : ""} ${
						classNames.dot
					}`}
					aria-label="Button 0"
				/>
				<button
					type="button"
					value={1}
					onClick={() => buttonClickHandler(1)}
					className={`${activeIndex === 1 ? classNames.active : ""} ${
						classNames.dot
					}`}
					aria-label="Button 1"
				/>
				<button
					type="button"
					value={2}
					onClick={() => buttonClickHandler(2)}
					className={`${activeIndex === 2 ? classNames.active : ""} ${
						classNames.dot
					}`}
					aria-label="Button 2"
				/>
				<button
					type="button"
					value={3}
					onClick={() => buttonClickHandler(3)}
					className={`${activeIndex === 3 ? classNames.active : ""} ${
						classNames.dot
					}`}
					aria-label="Button 3"
				/>
			</div>
		</div>
	);
}

export default Carousel;
