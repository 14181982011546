import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { RootState } from "../redux/store";
import { IAuthState } from "../shared/features/auth";

export default function PrivateRoute() {
	const authState = useSelector<RootState>((state) => state.auth) as IAuthState;
	const location = useLocation();
	return authState.token ? (
		<Outlet />
	) : (
		<Navigate
			state={{ from: `${location?.pathname}${location?.hash}` }}
			replace
			to="/login"
		/>
	);
}
