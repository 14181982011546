import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useUpdateWorkspaceMutation } from "../../../shared/services/workspace";
import Status from "./Status";
import className from "./styles.module.scss";
import DeleteWorkspaceModal from "../../../components/Modal/deleteWorkspaceModal";

export interface IStatus {
	name: string;
	color: string;
	_id: string;
}

function General(props: any) {
	const { workspace, refetch, memberInfo } = props;
	const navigate = useNavigate();
	// const [isActive, setIsActive] = useState(false);
	const [statuses, setStatuses] = useState<Array<IStatus>>([]);
	const [isNewStatus, setIsNewStatus] = useState(false);
	const [showDeleteWorkspaceModal, setShowDeleteWorkspaceModal] =
		useState<boolean>(false);
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		reset,
		formState: { errors },
	}: any = useForm({
		mode: "onChange",
	});
	useEffect(() => {
		if (workspace) {
			setValue("workspaceName", workspace?.name);
			// setIsActive(workspace?.testbuddyAI);
			setStatuses(workspace?.status);
		}
	}, [workspace, setValue]);

	const [updateWorkspace] = useUpdateWorkspaceMutation();

	const generateUniqueColor = () => {
		let color: string;
		do {
			color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
		} while (statuses.some((status) => status.color === color)); // eslint-disable-line @typescript-eslint/no-loop-func
		return color;
	};

	const handleDeleteWorkspace = async () => {
		const response: any = await updateWorkspace({
			_id: workspace._id,
			isDeleted: true,
		});
		if (response?.data) {
			toast.success("Workspace deleted Successfully!");
			setShowDeleteWorkspaceModal(false);
			navigate("/workspace");
		}
	};

	const handleAddStatus = () => {
		const newColor = generateUniqueColor();
		const newStatus = { name: "", color: newColor, _id: "new" };
		setIsNewStatus(true);
		setStatuses([...statuses, newStatus]);
	};

	// const handleChangeTestbuddyAIStatus = async (status: boolean) => {
	// 	const response: any = await updateWorkspace({
	// 		_id: workspace?._id,
	// 		testbuddyAI: status,
	// 	});
	// 	if (response?.data) {
	// 		setIsActive(status);
	// 		toast.success("Testbuddy AI status updated successfully!");
	// 	} else {
	// 		toast.error("Some error occured. please try again.");
	// 	}
	// };

	const handleKeyDown = (e: any) => {
		if (e.key === "Escape") {
			reset();
			setValue("workspaceName", workspace?.name);
		}
	};

	const onSubmit = async (data: any) => {
		try {
			const response: any = await updateWorkspace({
				_id: workspace?._id,
				name: data?.workspaceName,
			});
			if (response.data) {
				toast.success("Workspace name is updated successfully!");
				refetch();
			}
		} catch (err: any) {
			toast.error(err);
			reset();
		}
	};

	const handleWorkspaceNameBlur = () => {
		// Check if value changed before resetting
		if (workspace?.name !== getValues("workspaceName")) {
			handleSubmit(onSubmit)();
		}
	};

	return (
		<div
			className="d-flex flex-column align-items-start"
			style={{ marginLeft: "10px" }}
		>
			<p className={`${className["workspace-name"]} text-color`}>
				Workspace Name
			</p>
			<form
				className={`w-100 text-start ${className["workspace-name-form"]}`}
				onSubmit={handleSubmit(onSubmit)}
			>
				<input
					className={` form-control ${className["workspace-name-input"]} input-color-settings`}
					type="text"
					{...register("workspaceName", {
						required: true,
					})}
					onKeyDown={handleKeyDown}
					onBlur={handleWorkspaceNameBlur}
					readOnly={
						!!(
							memberInfo?.role === "member" ||
							workspace?.name === "My Workspace"
						)
					}
					autoComplete="off"
				/>
				{errors && errors?.workspaceName && (
					<span className="text-danger text-start">
						Workspace name should not be blank
					</span>
				)}
			</form>

			{/* <p className={`${className["workspace-ai"]} text-color`}>
				Testbuddy AI{" "}
				<span>AI based auto generate bug steps and other details</span>
			</p>
			<div
				className={`navbar-background d-flex justify-content-between align-items-center ${className["workspace-ai-status"]}`}
			>
				<span
					role="button"
					tabIndex={0}
					onKeyDown={() => handleChangeTestbuddyAIStatus(true)}
					onClick={() => handleChangeTestbuddyAIStatus(true)}
					className={`d-flex align-items-center justify-content-center ${
						isActive && className.active
					} ${!isActive ? "text-color" : ""}`}
				>
					Active
				</span>
				<span
					role="button"
					tabIndex={0}
					onKeyDown={() => handleChangeTestbuddyAIStatus(false)}
					onClick={() => handleChangeTestbuddyAIStatus(false)}
					className={`d-flex align-items-center justify-content-center ${
						!isActive && className.active
					} ${isActive ? "text-color" : ""}`}
				>
					Inactive
				</span>
			</div> */}
			<p className={`${className["workspace-bug-status"]} text-color`}>
				Bug Status <span>Add bug status to track your issues.</span>
			</p>
			<ul
				className={`d-flex flex-column align-items-start ${className["workspace-bug-status-list"]}`}
			>
				{statuses?.length > 0 &&
					statuses?.map((status: IStatus, index: number) => (
						<li key={status?._id}>
							<Status
								name={status.name}
								color={status.color}
								id={status._id}
								workspaceId={workspace?._id}
								statuses={statuses}
								setStatuses={setStatuses}
								refetch={refetch}
								isNewStatus={isNewStatus}
								setIsNewStatus={setIsNewStatus}
								memberInfo={memberInfo}
								index={index}
							/>
						</li>
					))}
				{memberInfo?.role !== "member" && (
					<li>
						<button
							className={`d-flex align-items-center ${className["add-status-btn"]} add-status-btn-color`}
							type="button"
							onClick={handleAddStatus}
						>
							<svg
								width="15"
								height="14"
								viewBox="0 0 15 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.5 6V0.995333C8.5 0.449333 8.052 0 7.5 0C6.944 0 6.5 0.446 6.5 0.995333V6H1.49533C0.949333 6 0.5 6.448 0.5 7C0.5 7.556 0.946 8 1.49533 8H6.5V13.0047C6.5 13.5507 6.948 14 7.5 14C8.056 14 8.5 13.554 8.5 13.0047V8H13.5047C14.0507 8 14.5 7.552 14.5 7C14.5 6.444 14.054 6 13.5047 6H8.5Z"
									fill="#356EFF"
								/>
							</svg>
							<span>Add New Status</span>
						</button>
					</li>
				)}
			</ul>
			{memberInfo?.role !== "member" && workspace?.name !== "My Workspace" && (
				<div
					className={`d-flex ${className["workspace-delete-btn"]} delete-workspace-btn-color`}
				>
					<svg
						width="19"
						height="20"
						viewBox="0 0 19 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={className["workspace-delete-btn-svg"]}
					>
						<path
							d="M18.1923 3.07692H14.3462V2.30769C14.3462 1.69565 14.103 1.10868 13.6702 0.675907C13.2375 0.243131 12.6505 0 12.0385 0H7.42308C6.81104 0 6.22407 0.243131 5.79129 0.675907C5.35852 1.10868 5.11538 1.69565 5.11538 2.30769V3.07692H1.26923C1.06522 3.07692 0.869561 3.15797 0.725303 3.30223C0.581044 3.44648 0.5 3.64214 0.5 3.84615C0.5 4.05017 0.581044 4.24582 0.725303 4.39008C0.869561 4.53434 1.06522 4.61538 1.26923 4.61538H2.03846V18.4615C2.03846 18.8696 2.20055 19.2609 2.48907 19.5494C2.77758 19.8379 3.1689 20 3.57692 20H15.8846C16.2926 20 16.684 19.8379 16.9725 19.5494C17.261 19.2609 17.4231 18.8696 17.4231 18.4615V4.61538H18.1923C18.3963 4.61538 18.592 4.53434 18.7362 4.39008C18.8805 4.24582 18.9615 4.05017 18.9615 3.84615C18.9615 3.64214 18.8805 3.44648 18.7362 3.30223C18.592 3.15797 18.3963 3.07692 18.1923 3.07692ZM6.65385 2.30769C6.65385 2.10368 6.73489 1.90802 6.87915 1.76376C7.02341 1.61951 7.21906 1.53846 7.42308 1.53846H12.0385C12.2425 1.53846 12.4381 1.61951 12.5824 1.76376C12.7266 1.90802 12.8077 2.10368 12.8077 2.30769V3.07692H6.65385V2.30769ZM15.8846 18.4615H3.57692V4.61538H15.8846V18.4615ZM8.19231 8.46154V14.6154C8.19231 14.8194 8.11126 15.0151 7.96701 15.1593C7.82275 15.3036 7.62709 15.3846 7.42308 15.3846C7.21906 15.3846 7.02341 15.3036 6.87915 15.1593C6.73489 15.0151 6.65385 14.8194 6.65385 14.6154V8.46154C6.65385 8.25753 6.73489 8.06187 6.87915 7.91761C7.02341 7.77335 7.21906 7.69231 7.42308 7.69231C7.62709 7.69231 7.82275 7.77335 7.96701 7.91761C8.11126 8.06187 8.19231 8.25753 8.19231 8.46154ZM12.8077 8.46154V14.6154C12.8077 14.8194 12.7266 15.0151 12.5824 15.1593C12.4381 15.3036 12.2425 15.3846 12.0385 15.3846C11.8344 15.3846 11.6388 15.3036 11.4945 15.1593C11.3503 15.0151 11.2692 14.8194 11.2692 14.6154V8.46154C11.2692 8.25753 11.3503 8.06187 11.4945 7.91761C11.6388 7.77335 11.8344 7.69231 12.0385 7.69231C12.2425 7.69231 12.4381 7.77335 12.5824 7.91761C12.7266 8.06187 12.8077 8.25753 12.8077 8.46154Z"
							fill="#FF4135"
						/>
					</svg>

					<button
						type="button"
						onClick={() => setShowDeleteWorkspaceModal(true)}
						className="delete-workspace-btn-color"
					>
						DELETE THIS WORKSPACE
					</button>
				</div>
			)}

			<DeleteWorkspaceModal
				show={showDeleteWorkspaceModal}
				onHide={() => setShowDeleteWorkspaceModal(false)}
				handleDelete={handleDeleteWorkspace}
			/>
		</div>
	);
}

export default General;
