import { Route, Routes, useLocation } from "react-router-dom";

import { OPEN_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } from "./routeList";
import PrivateRoute from "./privateRoute";
import TopNav from "../components/TopNav";
import classNames from "./styles.module.scss";
import WorkSpaceSideNav from "../components/WorkspaceSideNav";
import PageNotFound from "../pages/PageNotFound";

function Wrapper({ children }: { children: JSX.Element }) {
	const route = useLocation();
	const isOpenRoute: any = OPEN_ROUTES.includes(route.pathname);

	if (isOpenRoute) {
		return null;
	}
	return (
		<>
			{!route.pathname.includes("bugdetails") &&
				!route.pathname.includes("bug/details") && (
					<TopNav
						openRoute={isOpenRoute}
						isBugDetailsPage={
							route.pathname.includes("bugdetails") ||
							route.pathname.includes("bug")
						}
					/>
				)}

			{!route.pathname.includes("bugdetails") &&
				!route.pathname.includes("bug") && (
					<div className={`${classNames.container}`}>
						<WorkSpaceSideNav />
					</div>
				)}
			{route.pathname.includes("bugdetails") ||
			route.pathname.includes("bug") ? (
				<> {children} </>
			) : (
				<div
					className={` pl-sm-5 page-container  ${classNames["page-container"]}`}
				>
					{children}
				</div>
			)}
		</>
	);
}

export default function AllRoutes() {
	return (
		<>
			{/* <Routes>
				
			</Routes> */}
			<Routes>
				{PRIVATE_ROUTES.map((route) => (
					<Route key={route.path} path={route.path} element={<PrivateRoute />}>
						<Route
							path=""
							element={
								<Wrapper>
									<route.component />
								</Wrapper>
							}
						/>
					</Route>
				))}
				{PUBLIC_ROUTES.map((route) => (
					<Route
						key={route.path}
						path={route.path}
						element={<route.component />}
					/>
				))}
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</>
	);
}
