import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useForgotPasswordMutation } from "../../shared/services/user";
import PublicTopNav from "../../components/TopNav/PublicTopNav";

import "./forgotPassword.scss";

interface IFormInput {
	email: string;
}

function ForgotPassword() {
	document.title = "Testbuddy | Forgot Password";
	const params = new URLSearchParams(useLocation().search);
	const extensionId = params.get("extensionId");
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<IFormInput>({ mode: "onChange" });

	const [forgotPassword] = useForgotPasswordMutation();

	const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
		try {
			if (extensionId) {
				data.extensionId = extensionId; // eslint-disable-line no-param-reassign
			}

			await forgotPassword(data)
				.then(() => {
					toast.success("Reset Password Email Sent Successfully");
				})
				.catch(() => {
					toast.error("Email Not Found!");
				});
		} catch (error) {
			toast.error("Something went wrong");
		}
	};

	return (
		<div
			className="container "
			style={{
				paddingTop: "0px",
				background: " #f8f8fa",
				maxWidth: "100%",
			}}
		>
			<PublicTopNav isBugDetailsPage={false} />
			<div className="row main-wraper" style={{ paddingTop: "100px" }}>
				<div className="page-wrapper">
					<div className="container-xl">
						<div className="page-header d-print-none">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<h1 className="mb-4">Forgot Your Password?</h1>
								<div>
									Enter your email and we will send you a link to reset your
									password.
								</div>
								<div>
									<form onSubmit={handleSubmit(onSubmit)}>
										<div className="col-12 my-2" style={{ textAlign: "start" }}>
											<input
												{...register("email", {
													required: "Email is required.",
													pattern: {
														value:
															/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
														message: "Please enter a valid email",
													},
												})}
												style={{ width: 500, boxShadow: "none" }}
												name="email"
												className="form-control settings-field-height input-text border-0 bg-color-ebf1fa"
												placeholder="Enter your email"
											/>
											{errors.email && (
												<span style={{ color: "red" }} className="error">
													{errors.email.message}
												</span>
											)}
										</div>

										<div
											className="col-12 my-3"
											style={{
												display: "flex",
												justifyContent: "center",
												width: 500,
											}}
										>
											<button
												type="submit"
												value="yes"
												disabled={!isValid}
												className="btn btn-primary submit-button button-blue"
												style={{ width: 500 }}
											>
												Reset Password
											</button>
										</div>
									</form>
								</div>
								<div>
									Return to{" "}
									<Link
										to={
											extensionId
												? `/login?extensionId=${extensionId}`
												: "/login"
										}
									>
										{"Sign In >"}
									</Link>{" "}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;
