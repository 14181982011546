import {
	faApple,
	faChrome,
	faLinux,
	// faEdge,
	faWindows,
} from "@fortawesome/free-brands-svg-icons";
import {
	faClock,
	faHeading,
	faLink,
	faUserPen,
	faWindowMaximize,
	// faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
// import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
// import Select from "react-select";
import { Checkbox } from "antd";

import "./generalInfo.scss";
// import { RootState } from "../../../redux/store";
// import { IThemeState } from "../../../shared/features/themeSlice";
// import { useUpdateIssueMutation } from "../../../shared/services/bugDetails";
import { useGetWorkspaceQuery } from "../../../shared/services/workspace";
// import CustomDropdownIndicator from "../../../components/CustomSelect/customDropdownIndicator";

function GeneralInfoTab(props: any) {
	const { browserData, bug } = props;
	const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const [selectedTimezone, setSelectedTimezone] = useState(userTimezone);

	const [isOpen, setIsOpen] = useState(false);
	const [formattedTime, setFormattedTime] = useState("");
	const [isHovered, setIsHovered] = useState(false);
	const [maxHeight, setMaxHeight] = useState("100%");
	useEffect(() => {
		const handleResize = () => {
			setMaxHeight(`${window.innerHeight / 2 - 50}px`);
		};

		window.addEventListener("resize", handleResize);
		handleResize(); // Call initially to set the value

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	// const flagRef = useRef();

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};
	const formatTime = () => {
		if (selectedTimezone === "UTC") {
			const formatted = moment
				.utc(Date.now())
				.format("DD MMMM YYYY [at] HH:mm [UTC]");
			setFormattedTime(formatted);
		} else {
			const timezoneOffset = moment
				.tz(Date.now(), selectedTimezone)
				.format("Z");
			const formatted = moment
				.tz(Date.now(), selectedTimezone)
				.format(`DD MMMM YYYY [at] HH:mm [GMT]${timezoneOffset}`);
			setFormattedTime(formatted);
		}
	};

	useEffect(() => {
		formatTime();
	}, [selectedTimezone, browserData]);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (timezone: any) => {
		setSelectedTimezone(timezone);
		setIsOpen(false);
	};

	const [options, setOptions] = useState<any>([]);
	const [selectedStatus, setSelectedStatus] = useState<any>();
	const [isMobile, setIsMobile] = useState(false);

	console.log(options, selectedStatus, isMobile);

	const checkIsMobile = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	useEffect(() => {
		checkIsMobile();
		window.addEventListener("resize", checkIsMobile);
		return () => {
			window.removeEventListener("resize", checkIsMobile);
		};
	}, []);

	useEffect(() => {
		setSelectedStatus(bug?.status?.id);
	}, [bug]);
	const { data: workspaceDetails } = useGetWorkspaceQuery(
		{
			id: bug?.workspaceId,
		},
		{
			refetchOnMountOrArgChange: true,
		},
	);
	useEffect(() => {
		if (workspaceDetails && workspaceDetails?.data?.status) {
			const { status } = workspaceDetails.data;
			const arr: any = [];
			status.map((ele: any) => {
				const obj = { label: ele.name, value: ele._id };
				arr.push(obj);
				return true;
			});
			setOptions(arr);
		}
	}, [workspaceDetails]);
	// const { darkMode } = useSelector<RootState>(
	// 	(state) => state.theme,
	// ) as IThemeState;

	// const route = useLocation();
	const handleCopyClick = () => {
		if (browserData?.target_html) {
			navigator.clipboard.writeText(browserData.target_html);
			toast.success("URL copied to clipboard!");
		}
	};

	const getOs = (os: any) => {
		switch (os) {
			case "MacOS":
				return faApple;
			case "Windows":
				return faWindows;
			case "Ubuntu":
			case "Linux":
				return faLinux;
			default:
				return faWindows;
		}
	};

	// const [showFull, setShowFull] = useState(false);
	// const [updateIssue] = useUpdateIssueMutation();

	// const handleStatusUpdate = async (val: any) => {
	// 	setSelectedStatus(val.value);
	// 	await updateIssue({
	// 		_id: bug?._id,
	// 		status: { name: val.label, id: val.value },
	// 	});
	// 	toast.success("status updated successfully!");
	// 	refetch();
	// };

	return (
		<div
			className="tab-pane active show border-top-golden"
			id="tabs-general"
			style={{
				borderRadius: "8px",
				maxHeight,
				overflowY: "scroll",
				minHeight: maxHeight,
			}}
			// style={{maxWidth: '616px'}}
			// style={{ height: "430px", overflow: "scroll" }}
		>
			<table
				style={{
					borderCollapse: "collapse",
					border: "transparent",
					borderRadius: 8,
				}}
				className="table card-table table-vcenter text-nowrap datatable generalInfoTable w-100"
			>
				<tbody style={{ overflowY: "scroll" }}>
					<tr className="rowBlackStripe">
						<td style={{ width: 200 }}>
							<div className="dropdown-wrapper">
								<div className="dropdown-label">
									<FontAwesomeIcon icon={faClock} style={{ marginRight: 5 }} />
									Timestamp
									<svg
										className={`dropdown-icon ${isOpen ? "open" : ""}`}
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										onClick={toggleDropdown}
										width={20}
									>
										<path
											fillRule="evenodd"
											d="M5.293 7.707a1 1 0 011.414 0L10 11.414l3.293-3.707a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</div>

								{isOpen && (
									<ul className="dropdown-list">
										<li
											onClick={() => handleOptionClick(userTimezone)}
											// onKeyDown={() => handleOptionClick(userTimezone)}
											// role="button"
											// tabIndex={0}
										>
											<Checkbox checked={selectedTimezone === userTimezone}>
												{userTimezone} timezone
											</Checkbox>
										</li>
										<li
											onClick={() => handleOptionClick("UTC")}
											// onKeyDown={() => handleOptionClick("UTC")}
											// role="button"
											// tabIndex={0}
										>
											<Checkbox checked={selectedTimezone === "UTC"}>
												UTC timezone
											</Checkbox>
										</li>
									</ul>
								)}
							</div>
						</td>
						<td>{formattedTime}</td>
					</tr>
					<tr className="rowWhiteStripe">
						<td style={{ width: 200 }}>
							<FontAwesomeIcon icon={faHeading} style={{ marginRight: 5 }} />
							Page Title
						</td>
						<td style={{ wordWrap: "break-word" }}>
							<div
								style={{
									whiteSpace: "pre-wrap",
								}}
							>
								{browserData?.page_title}{" "}
							</div>
						</td>
					</tr>
					<tr
						className="rowBlackStripe"
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<td
							style={{
								width: 200,
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<div className="d-flex">
								<FontAwesomeIcon icon={faLink} style={{ marginRight: 5 }} />
								<div>URL</div>
							</div>

							<div
								style={{
									marginRight: "6px",
									display: isHovered ? "block" : "none",
									cursor: "pointer",
								}}
								className="copy-icon"
								onClick={handleCopyClick}
								onKeyDown={handleCopyClick}
								role="button"
								tabIndex={0}
								title="Copy URL"
							>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="cursor-pointer"
								>
									<path
										d="M15.7188 2.21875C15.9062 2.40625 16 2.63542 16 2.90625V10C15.9792 10.5625 15.7812 11.0312 15.4062 11.4062C15.0312 11.7812 14.5625 11.9792 14 12H8C7.4375 11.9792 6.96875 11.7812 6.59375 11.4062C6.19792 11.0312 5.98958 10.5625 5.96875 10V2C5.98958 1.4375 6.1875 0.96875 6.5625 0.59375C6.9375 0.21875 7.40625 0.0208333 7.96875 0H13.0938C13.3646 0 13.5938 0.09375 13.7812 0.28125L15.7188 2.21875ZM14.5 10H14.4688V4H13C12.7083 4 12.4688 3.90625 12.2812 3.71875C12.0938 3.53125 12 3.29167 12 3L11.9688 1.5H7.96875C7.65625 1.54167 7.48958 1.70833 7.46875 2V10C7.48958 10.3125 7.65625 10.4792 7.96875 10.5H14C14.3125 10.4792 14.4792 10.3125 14.5 10ZM8.5 14L8.53125 13H10V14C9.97917 14.5625 9.78125 15.0312 9.40625 15.4062C9.03125 15.7812 8.5625 15.9792 8 16H2C1.4375 15.9792 0.96875 15.7812 0.59375 15.4062C0.21875 15.0312 0.0208333 14.5625 0 14V6C0.0208333 5.4375 0.21875 4.96875 0.59375 4.59375C0.96875 4.21875 1.4375 4.02083 2 4H5V5.5H2C1.6875 5.52083 1.52083 5.6875 1.5 6L1.46875 14C1.48958 14.3125 1.65625 14.4792 1.96875 14.5H8C8.3125 14.4792 8.47917 14.3125 8.5 14Z"
										fill="white"
										fillOpacity="0.6"
									/>
								</svg>
							</div>
						</td>
						<td style={{ wordWrap: "break-word" }}>
							<div
								style={{
									maxWidth: "400px", // Set the max-width property here
									display: "flex",
								}}
							>
								<div style={{ marginRight: "6px" }}>
									<svg
										onClick={() =>
											window.open(browserData?.target_html, "_blank")
										}
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										style={{ cursor: "pointer" }}
									>
										<path
											d="M6.27241 17.7277C5.06326 16.5185 5.06326 14.5528 6.27241 13.3436L9.10084 10.5152L7.75733 9.17168L4.92891 12.0001C2.97729 13.9517 2.97729 17.1196 4.92891 19.0712C6.88052 21.0228 10.0484 21.0228 12 19.0712L14.8284 16.2428L13.4849 14.8992L10.6565 17.7277C9.44732 18.9368 7.48156 18.9368 6.27241 17.7277ZM9.87865 15.5356L15.5355 9.87879L14.1213 8.46458L8.46444 14.1214L9.87865 15.5356ZM12 4.92904L9.17155 7.75747L10.515 9.10097L13.3435 6.27255C14.5526 5.06339 16.5184 5.06339 17.7275 6.27255C18.9367 7.4817 18.9367 9.44746 17.7275 10.6566L14.8991 13.485L16.2426 14.8285L19.071 12.0001C21.0227 10.0485 21.0227 6.88066 19.071 4.92904C17.1194 2.97743 13.9516 2.97743 12 4.92904Z"
											fill="#4C8DF6"
										/>
									</svg>
								</div>
								<div>
									<span>
										{browserData?.target_html?.length > 50
											? `${browserData?.target_html?.slice(0, 50)}...`
											: browserData?.target_html}
									</span>
								</div>
							</div>
						</td>
					</tr>
					<tr className="rowWhiteStripe">
						<td style={{ width: 200 }}>
							<FontAwesomeIcon
								icon={getOs(browserData?.os)}
								style={{ marginRight: 5 }}
							/>
							OS
						</td>
						<td>
							<div
								style={{
									whiteSpace: "pre-wrap",
								}}
							>
								{" "}
								{browserData?.os}{" "}
							</div>
						</td>
					</tr>
					<tr className="rowBlackStripe">
						<td style={{ width: 200 }}>
							<FontAwesomeIcon icon={faChrome} style={{ marginRight: 5 }} />
							Browser Version
						</td>
						<td>
							{" "}
							<div
								style={{
									whiteSpace: "pre-wrap",
								}}
							>
								{browserData?.browser_name}{" "}
							</div>
						</td>
					</tr>
					<tr className="rowWhiteStripe">
						<td style={{ width: 200 }}>
							{/* <FontAwesomeIcon icon={faWindowRestore} style={{ marginRight: 5 }} /> */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="currentColor"
								aria-hidden="true"
								width="16"
								style={{
									color: "rgb(95, 104, 101)",
									fontSize: 16,
									marginRight: 5,
								}}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
								/>
							</svg>
							Screen
						</td>
						<td>
							{" "}
							<div
								style={{
									whiteSpace: "pre-wrap",
								}}
							>
								{browserData?.screen_size}{" "}
							</div>
						</td>
					</tr>
					<tr className="rowBlackStripe">
						<td style={{ width: 200 }}>
							<FontAwesomeIcon
								icon={faWindowMaximize}
								style={{ marginRight: 5 }}
							/>
							{/* <FontAwesomeIcon icon={faWindowRestore} style={{ marginRight: 5 }} /> */}
							Viewport
						</td>
						<td>{browserData?.viewport}</td>
					</tr>

					<tr className="rowWhiteStripe">
						<td style={{ width: 200 }}>
							<FontAwesomeIcon icon={faUserPen} style={{ marginRight: 5 }} />
							Reporter
						</td>
						<td>
							<div
								style={{
									whiteSpace: "pre-wrap",
								}}
							>
								{bug?.author?.firstName ?? "Unknown"}
							</div>
						</td>
					</tr>

					<tr className="rowBlackStripe">
						<td style={{ width: 200 }}>
							<svg
								style={{ marginRight: 5 }}
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-globe"
								viewBox="0 0 16 16"
							>
								<path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z" />
							</svg>
							Country
						</td>
						<td>
							{" "}
							{/* {country?.filter((item) => item?.code === flag)[0]?.emoji} */}
							{/* <img
				  style={{
					height: 16,
					width: 14,
					marginRight: 5,
				  }}
				  src={`https://flagsapi.com/${flag}/flat/64.png`}
				></img> */}
							{/* {flag} */}
							{/* {flag &&  */}
							{/* <img
								ref={flagRef}
								src={flag}
								alt=""
								srcSet=""
								style={{
									width: 19,
									marginRight: 5,
								}}
							/>
							{country} */}
							{bug?.country}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default GeneralInfoTab;
