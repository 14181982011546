import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { IAuthState, resetUser } from "../../../shared/features/auth";
import { RootState, resetCache } from "../../../redux/store";
// import bulb from "../../../assets/img/bulb.svg";
import className from "./styles.module.scss";
import { toggleTheme } from "../../../shared/features/themeSlice";
import { useLogoutMutation } from "../../../shared/services/auth";
import { useAppSelector } from "../../../redux/hooks";
import "./toggle.scss";
import { resetApiState } from "../../../shared/services/workspace";
import { useUpdateDarkModeMutation } from "../../../shared/services/bugDetails";

function UserInfoPill() {
	const [firstName, setFirstName] = useState<any>(null);
	const dispatch = useDispatch();
	const authState = useSelector<RootState>((state) => state.auth) as IAuthState;
	if (!authState?.firstName) {
		setFirstName(Cookies.get("firstName") ?? null);
	}

	const { darkMode }: any = useAppSelector<RootState>(
		(state: any) => state.theme,
	) as any;

	const route = useLocation();

	const navigate = useNavigate();
	const [openDropdown, setOpenDropdown] = useState(false);
	const [logout, { isLoading }] = useLogoutMutation();
	const [updateDarkMode] = useUpdateDarkModeMutation();
	const handleLogout = () => {
		if (!isLoading) {
			logout().then(() => {
				dispatch(resetUser());
				resetCache();
				resetApiState();
				Cookies.remove("extensionToken", { path: "/" });
				Cookies.remove("firstName", { path: "/" });
				navigate("/login", { replace: true });
			});
		}
	};
	const themeButtonHandler = async () => {
		await updateDarkMode({ isDarkMode: !darkMode });
		dispatch(toggleTheme());
	};
	return (
		<div className="navbar-nav flex-row order-md-last border-1">
			<div className="d-flex">
				{/* <button type="button" onClick={themeButtonHandler}>
					Toggle
				</button> */}
				{/*  */}

				{/* <div className="nav-item d-none d-md-flex">
					<Link to="/roadmap">
						<div
							className="btn btn-icon lh-1 button-icon-outline"
							data-bs-toggle="modal"
							data-bs-target="#modal-scrollable"
						>
							<img
								src={bulb}
								alt="Tabler"
								className="navbar-brand-image topMenuBulb"
							/>
						</div>
					</Link>
				</div> */}
			</div>
			<OverlayTrigger
				rootClose
				trigger="click"
				key="bottom-end"
				placement="bottom-end"
				overlay={
					<Popover
						placement="bottom-end"
						style={{ zIndex: 99999, padding: 0, width: 107 }}
						id="popover-positioned-bottom-end"
					>
						<Popover.Body
							style={{
								padding: "3px 5px",
								width: "107px",
							}}
						>
							<div
								onClick={() => {
									setOpenDropdown(false);
									handleLogout();
								}}
								role="button"
								tabIndex={0}
								onKeyDown={() => {
									setOpenDropdown(false);
									handleLogout();
								}}
								style={{
									zIndex: openDropdown ? 10000 : -9000,
								}}
							>
								<div>
									<div
										role="button"
										tabIndex={0}
										onKeyDown={handleLogout}
										onClick={handleLogout}
										className={`${className["dropdown-item"]} lh-1 pointer`}
									>
										<i className={`${className.icon} bi bi-power`} />{" "}
										<div className="px-2 pe-3"> Logout</div>
									</div>
								</div>
							</div>
						</Popover.Body>
					</Popover>
				}
			>
				<div
					className={`nav-link d-flex lh-1 show ${className["user-info"]}`}
					data-bs-toggle="dropdown"
					aria-label="Open user menu"
					style={{ position: "relative" }}
					// aria-expanded={openDropdown ? "true" : "false"}
				>
					<button
						onClick={() => setOpenDropdown(!openDropdown)}
						type="button"
						style={{ background: "none", border: "none", outline: "none" }}
						className={`lh-1 ${className.topMenuLoginToUpload}`}
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect width="20" height="20" rx="4" fill="white" />

							<text
								x="50%"
								y="50%"
								dominantBaseline="middle"
								textAnchor="middle"
								fill="black"
								style={{ fontSize: "14", fontWeight: "600" }}
							>
								{authState && authState?.firstName
									? authState?.firstName[0]?.toUpperCase()
									: (firstName && firstName[0]?.toUpperCase()) || "U"}
							</text>
						</svg>
						<span className="px-2 pe-3 b-3">
							{authState && authState?.firstName
								? authState?.firstName
								: (firstName && firstName) || "User"}
						</span>
						<svg
							width="12"
							height="6"
							viewBox="0 0 12 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M6 6L12 0H0L6 6Z" fill="white" />
						</svg>
					</button>
				</div>
			</OverlayTrigger>
		</div>
	);
}

export default UserInfoPill;
