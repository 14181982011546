import { useEffect, useRef } from "react";

export default function useEffectOnce(cb: Function, dep: Array<any> = []) {
	const didMount = useRef(false);
	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true;
			cb();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cb, ...dep]);
}
